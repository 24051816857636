import { Box, Typography } from "@mui/material"
import Diamond from "../img/diamond.svg"
import { Link } from "react-router-dom"

export function FeatureUpsell(props) {
  const { top, right, position, marginLeft, height, width } = props.position
  return (
    <Box className="feature-addon-container">
      {!props.hidePopover && (
        <Box
          className="feature-popover"
          sx={{
            position: position ?? "absolute",
            marginLeft: marginLeft ?? 0,
            backgroundColor: "white",
            borderRadius: "12px",
            padding: 2,
            boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
            top,
            right,
            height: height ?? "auto",
            zIndex: 1001,
            display: "flex",
            alignItems: "center",
            width: width ?? "281px",
          }}
        >
          {props.eventsOverride ? (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <span className="icon calendar color" />
              <Typography style={{ color: "black", fontSize: 12 }}>
                Organize and track all live training - internal and external -
                in the same hub by including our scheduling and registration
                component!{" "}
                <Link to="/upgrade">
                  <strong>Contact us to learn more.</strong>
                </Link>
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                color: "black",
                fontSize: 12,
                textTransform: "none",
                textWrap: "auto",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={Diamond}
                  alt="diamond"
                  width={20}
                  style={{ marginRight: 5 }}
                />
                <strong>Get more out of MobileMind!</strong>
              </Box>
              Good news—you can unlock it with an upgrade!{" "}
              <Link
                className="feature-popover-link"
                style={{
                  padding: "0px !important",
                  margin: 0,
                  textTransform: "none",
                  display: "inline !important",
                  fontSize: 12,
                  fontWeight: "bold",
                }}
                to="/upgrade"
              >
                Contact us to learn more.
              </Link>
            </Typography>
          )}
        </Box>
      )}
      {props.children}
    </Box>
  )
}
