import { Quill } from "react-quill"
import ImageResize from "quill-image-resize-module-react"

Quill.register("modules/imageResize", ImageResize)

export const formats = ["bold", "italic", "link"]
export const modules = {
  toolbar: [["bold", "italic"], ["link"]],
}

export const expandedModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ],
  imageResize: {
    modules: ["Resize", "DisplaySize"],
  },
}

export const expandedFormats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
]
