import { getNotifications } from "@mobilemind/common/src/actions/notifications"
import {
  logout,
  switchActiveMembership,
} from "@mobilemind/common/src/actions/user"
import NotificationsMenu from "@mobilemind/common/src/components/NotificationsMenu"
import OrgSwitcher from "@mobilemind/common/src/components/orgSwitcher/OrgSwitcher"
import { NestedMenuItem } from "@mobilemind/common/src/components/NestedMenuItem"
import { Button, Menu, MenuItem, Popover } from "@mui/material"
import classNames from "classnames"
import { Component, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { useAppDispatch } from "store/hooks"
import ErrorBoundary from "../../components/ErrorBoundary"
import SuccessChecklist from "../../components/SuccessChecklist"
import logoWhiteImg from "../../img/logo-white.png"
import profilePicImg from "../../img/profile-pic.svg"
import { setCategoryModalOpen } from "../../store/reducers/categories"
import { changeTab } from "../../store/reducers/navbar"
import { startTour } from "../../store/reducers/productTour"
import "../../styles/navbar.scss"
import "../../styles/notifications.scss"
import { startNewConference } from "../events/conference/activeConferenceSlice"
import { setReviewerGroupsModalOpen } from "../review/reviewSlice"
import { JumpToOrgSelect } from "./JumpToOrgSelect"
import { FeatureName, unmasqueradeUser } from "store/reducers/session"
import Loading from "@mobilemind/common/src/components/Loading"
import { FeatureUpsell } from "@mobilemind/common/src/components/FeatureUpsell"

const mapStateToProps = ({ courses, notifications, navbar, session }) => {
  return {
    courses: courses.data,
    navbar,
    notifications: notifications.data,
    session,
  }
}

const mapDispatchToProps = {
  logout,
  startTour,
  startNewConference,
  setCategoryModalOpen,
  setReviewerGroupsModalOpen,
  getNotifications,
}

class ContentMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { isCollectionAdminView, isOrgAdmin, isPartner, enabledAddOns } =
      this.props

    return (
      <li id={"tour-" + this.props.tab}>
        <Button
          className={classNames({
            isLocation: this.props.navbar.activeItem === this.props.tab,
          })}
          onClick={this.handleClick}
        >
          {this.props.tab}
        </Button>

        <Menu
          id="content-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.canCreate && (
            <NestedMenuItem
              className="dropdown-menu"
              label="Create"
              parentMenuOpen={Boolean(anchorEl)}
            >
              {this.props.items.map((item, index) => {
                let shouldReturn =
                  !this.props.isPartner ||
                  (item.label !== "Quick Links Widget" &&
                    item.label !== "Announcements" &&
                    item.label !== "Goals")

                if (isCollectionAdminView && item.label !== "Announcements") {
                  shouldReturn = false
                }
                let featureDisabled, topOfffset
                if (item.label === "Badges") {
                  topOfffset = "285px"
                  featureDisabled = !enabledAddOns.includes(FeatureName.Badges)
                }
                if (item.label === "Mandated Training") {
                  topOfffset = "240px"
                  featureDisabled = !enabledAddOns.includes(
                    FeatureName.OnDemand
                  )
                }
                if (item.label === "Goals") {
                  topOfffset = "195px"
                  featureDisabled = !enabledAddOns.includes(
                    FeatureName.OnDemand
                  )
                }

                if (item.label === "Rubrics") {
                  topOfffset = "345px"
                  featureDisabled = !enabledAddOns.includes(
                    FeatureName.Observations
                  )
                }

                if (featureDisabled) {
                  return (
                    <div className="navbar-feature-item">
                      <FeatureUpsell
                        position={{
                          top: topOfffset,
                          right: "auto",
                          marginLeft: 30,
                          position: "fixed",
                        }}
                      >
                        <MenuItem
                          className="nestedItem"
                          style={{ marginBottom: "0px !important" }}
                          onClick={() => {}}
                          key={index}
                        >
                          <DropdownItem
                            disabled={true}
                            key={index}
                            item={item}
                            tab={this.props.tab}
                          />
                        </MenuItem>
                      </FeatureUpsell>
                    </div>
                  )
                }

                if (shouldReturn) {
                  return (
                    <MenuItem
                      className="nestedItem"
                      onClick={this.handleClose}
                      key={index}
                    >
                      <DropdownItem
                        disabled={item.disabled}
                        key={index}
                        item={item}
                        tab={this.props.tab}
                      />
                    </MenuItem>
                  )
                }
                return null
              })}
            </NestedMenuItem>
          )}
          {(isOrgAdmin || isPartner) && !isCollectionAdminView && (
            <MenuItem
              onClick={() => {
                this.props.setCategoryModalOpen(true)
                this.handleClose()
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "16px 20px",
                }}
              >
                Categories
              </div>
            </MenuItem>
          )}

          {this.props.canReview &&
            this.props.isHQ &&
            !isCollectionAdminView && (
              <MenuItem>
                <Link onClick={this.handleClose} to={"/review"}>
                  Review
                </Link>
              </MenuItem>
            )}

          {isOrgAdmin &&
            this.props.isHQ &&
            !isPartner &&
            !isCollectionAdminView && (
              <MenuItem>
                <Link
                  onClick={() => {
                    this.handleClose()
                    this.props.setReviewerGroupsModalOpen(true)
                  }}
                >
                  Reviewer Groups
                </Link>
              </MenuItem>
            )}

          {isOrgAdmin && !isPartner && !isCollectionAdminView && (
            <MenuItem>
              <Link onClick={this.handleClose} to={"/marketplace"}>
                Readymade Marketplace
              </Link>
            </MenuItem>
          )}
        </Menu>
      </li>
    )
  }
}

function DropdownItem(props) {
  const dispatch = useAppDispatch()

  if (props.disabled) {
    // eslint-disable-next-line
    return <a style={{ color: "rgba(255,255,255,.6)" }}>{props.item.label}</a>
  } else if (props.item.isExternal) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={props.item.path}>
        {props.item.label}
      </a>
    )
  } else if (props.item.isMobileMind) {
    return (
      <a href={process.env.REACT_APP_API_URL + props.item.path}>
        {props.item.label}
      </a>
    )
  } else {
    return (
      <Link
        className={props.disabled ? "disabled" : ""}
        onClick={() => dispatch(changeTab(props.tab))}
        to={props.item.path}
      >
        {props.item.label}
      </Link>
    )
  }
}

class UserMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { isOrgAdmin, isPartner, enabledAddOns } = this.props.session
    const isCollectionAdminView =
      this.props.session.isCollectionAdmin &&
      !this.props.session.activeMembership.data
        ? true
        : false

    const canViewOrgSettings =
      isOrgAdmin && !isPartner && !isCollectionAdminView

    const hasOrgSettingsAddOn =
      enabledAddOns.includes(FeatureName.OnDemand) ||
      enabledAddOns.includes(FeatureName.Events)

    const canSubmitTicket =
      isOrgAdmin || enabledAddOns.includes(FeatureName.OnDemand)

    return (
      <div>
        <div
          className="profilePicture"
          style={{ backgroundImage: "url(" + this.props.profilePicSrc + ")" }}
          aria-owns={anchorEl ? "user-menu" : undefined}
          onClick={this.handleClick}
        ></div>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem>
            <Link onClick={this.handleClose} to="/profile">
              Your Profile
            </Link>
          </MenuItem>

          {this.props.isServiceAdmin && this.props.serviceAccountId && (
            <MenuItem>
              <Link onClick={this.handleClose} to="/service-account">
                Service Account
              </Link>
            </MenuItem>
          )}

          {isCollectionAdminView && (
            <MenuItem onClick={this.handleClose}>
              <Link to="/collection-settings">
                {this.props.session.userCollection[0].label[0].value} Settings
              </Link>
            </MenuItem>
          )}

          {canViewOrgSettings && (
            <div className="relative">
              <FeatureUpsell
                hidePopover={hasOrgSettingsAddOn}
                position={{
                  top: 42,
                  right: 180,
                }}
              >
                <MenuItem
                  style={{
                    opacity: hasOrgSettingsAddOn ? 1 : 0.5,
                    pointerEvents: hasOrgSettingsAddOn ? "auto" : "none",
                  }}
                  onClick={() => {
                    if (hasOrgSettingsAddOn) {
                      this.handleClose()
                    }
                  }}
                >
                  <Link to="/org-settings">Organization Settings</Link>
                </MenuItem>
              </FeatureUpsell>
            </div>
          )}
          {this.props.session.isPartner && (
            <MenuItem onClick={this.handleClose}>
              <Link onClick={this.props.startTour} to="/partner-settings">
                Partner Settings
              </Link>
            </MenuItem>
          )}

          {!isCollectionAdminView && (
            <MenuItem
              onClick={() => {
                this.handleClose()
              }}
            >
              <Link onClick={this.props.startTour} to="/">
                Start Product Tour
              </Link>
            </MenuItem>
          )}

          {canSubmitTicket && (
            <MenuItem>
              <Link to="/submit-ticket">Submit a Ticket</Link>
            </MenuItem>
          )}

          <MenuItem onClick={this.handleClose}>
            <Link onClick={this.props.logout} to="/">
              Logout
            </Link>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

class AccountMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { enabledAddOns, isOrgAdmin } = this.props
    const canViewSupport =
      isOrgAdmin ||
      enabledAddOns.includes(FeatureName.OnDemand) ||
      enabledAddOns.includes(FeatureName.Events)

    const { anchorEl } = this.state
    return (
      <li id={"tour-account"}>
        <Button
          className={classNames({
            isLocation: this.props.navbar.activeItem === this.props.tab,
          })}
          onClick={this.handleClick}
        >
          {this.props.tab}
        </Button>

        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.items.map((item, index) => {
            if (item.label) {
              if (
                (this.props.isGroupManager && item.label === "Groups") ||
                !this.props.isGroupManager
              ) {
                return (
                  <MenuItem onClick={this.handleClose} key={index}>
                    <DropdownItem
                      disabled={item.disabled}
                      key={index}
                      item={item}
                      tab={this.props.tab}
                    />
                  </MenuItem>
                )
              }
            }
            return null
          })}

          {!this.props.isGroupManager && canViewSupport && (
            <NestedMenuItem
              className="dropdown-menu"
              label="Support"
              parentMenuOpen={Boolean(anchorEl)}
            >
              {this.props.supportItems.map((item, index) => {
                return (
                  <MenuItem
                    className="nestedItem"
                    onClick={this.handleClose}
                    key={index}
                  >
                    <DropdownItem
                      disabled={item.disabled}
                      key={index}
                      item={item}
                      tab={this.props.tab}
                    />
                  </MenuItem>
                )
              })}
            </NestedMenuItem>
          )}
        </Menu>
      </li>
    )
  }
}

class DropdownMenu extends Component {
  state = {
    anchorEl: null,
    active: false,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, active: true })
  }

  handleClose = () => {
    this.setState({ anchorEl: null, active: false })
  }

  changeNavbar = () => {
    this.props.changeNavbar(this.props.tab)
    this.handleClose()
  }

  onClick = () => {
    this.handleClose()
  }

  render() {
    const { anchorEl } = this.state
    return (
      <li id={"tour-" + this.props.tab}>
        <Button
          className={classNames(
            { isLocation: this.props.navbar.activeItem === this.props.tab },
            { active: this.state.active }
          )}
          onClick={this.handleClick}
        >
          {this.props.tab}
        </Button>

        <Menu
          className={"dropdown-menu"}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.items.map((item, index) => {
            if (this.props.isPartner && item.label === "Access") {
              return null
            }
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  item.onClick && item.onClick()
                  this.onClick()
                }}
              >
                {item.isAddOn && !item.canView ? (
                  <FeatureUpsell
                    position={{
                      left: 0,
                      top: item.top,
                      right: "auto",
                      position: "fixed",
                      marginLeft: "175px",
                      height: "52px",
                    }}
                  >
                    <DropdownItem
                      disabled={true}
                      key={index}
                      item={item}
                      tab={this.props.tab}
                    />
                  </FeatureUpsell>
                ) : (
                  <DropdownItem
                    disabled={item.disabled}
                    key={index}
                    item={item}
                    tab={this.props.tab}
                  />
                )}
              </MenuItem>
            )
          })}
        </Menu>
      </li>
    )
  }
}

function Navbar(props) {
  const { session, navbar, isAuthorized } = props
  const { orgRoles, groupRoles, group, isSiteAdmin, isSiteReviewer } = session

  const [anchorEl, setAnchorEl] = useState(null)
  const [notificationsFetched, setNotificationsFetched] = useState(false)
  const [isJumpToOrgOpen, setIsJumpToOrgOpen] = useState(false)
  const [isUnmasquerading, setIsUnmasquerading] = useState(false)

  useEffect(() => {
    if (!notificationsFetched) {
      setNotificationsFetched(true)
    }
  }, [notificationsFetched])

  const dispatch = useAppDispatch()

  let profilePicSrc = session.userPicture
    ? process.env.REACT_APP_API_URL + session.userPicture.attributes.uri.url
    : profilePicImg

  let allRoles = orgRoles
    .concat(groupRoles)
    .filter(
      (role) =>
        role !== "organization-member" &&
        role !== "organization-teacher" &&
        role !== "group-member" &&
        role !== "group-teacher"
    )
  let rolesArray = allRoles.map((role) => {
    return role
      .replaceAll("organization-", "Org ")
      .replaceAll("group-", "Group ")
      .replaceAll("partner-", "Partner ")
  })

  const {
    isCollectionAdmin,
    canReview,
    canReport,
    canCreate,
    isEventPersonnel,
    canSchedule,
    isOrgAdmin,
    isServiceAdmin,
    isGroupAdmin,
    reviewerTypes,
    isPartner,
    serviceAccountId,
    enabledAddOns,
  } = session

  let isAdmin = isSiteAdmin || isOrgAdmin || isGroupAdmin

  let isHQ =
    isSiteAdmin ||
    isSiteReviewer ||
    (group && group.field_hq[0] && group.field_hq[0].value)

  const canViewContent =
    canReview ||
    isSiteAdmin ||
    isOrgAdmin ||
    canCreate ||
    isPartner ||
    isCollectionAdmin

  const canReviewExternal =
    allRoles.join().includes("admin") ||
    ((allRoles.join().includes("reviewer") ||
      allRoles.join().includes("organization-reviewer")) &&
      reviewerTypes.includes("external_event"))

  const learnURL =
    session.collection && session.collection.field_domain[0]
      ? "https://" + session.collection.field_domain[0].value
      : process.env.REACT_APP_TEACHER_URL

  const createItems = [
    {
      label: "Courses",
      path: "/create/courses",
      disabled: !isHQ,
    },
    {
      label: "Learning Paths",
      path: "/create/learning-paths",
      disabled: !isHQ,
    },
    {
      label: "Announcements",
      path: "/create/announcements",
    },
  ]

  if (
    isAdmin ||
    orgRoles.includes("organization-creator") ||
    groupRoles.includes("group-admin") ||
    groupRoles.includes("group-creator")
  ) {
    createItems.splice(4, 0, {
      label: "Goals",
      path: "/create/goals",
    })
  }

  if (
    (isAdmin || canCreate) &&
    !isPartner &&
    !rolesArray.toString().includes("drafter")
  ) {
    createItems.push({
      label: "Mandated Training",
      path: "/create/mandated-training",
    })
  }

  createItems.push({
    label: "Badges",
    path: "/create/badges",
    disabled: !isHQ,
  })

  if (isOrgAdmin) {
    createItems.push({
      label: "Rubrics",
      path: "/create/rubrics",
    })
  }

  if (isAdmin) {
    createItems.push({
      label: "Quick Links Widget",
      path: "/create/quick-links",
    })
  }

  const activeMembershipUUID = session.activeMembership?.data

  const collectionUUID =
    session.userCollection[0] && session.userCollection[0].uuid[0].value

  let mismatchedCollectionAdmin
  if (activeMembershipUUID && collectionUUID) {
    mismatchedCollectionAdmin = true
  }

  const isCollectionAdminView =
    session.isCollectionAdmin && !session.activeMembership.data ? true : false

  const showEventsTab =
    enabledAddOns.includes(FeatureName.Events) ||
    enabledAddOns.includes(FeatureName.OnDemand) ||
    enabledAddOns.includes(FeatureName.Observations)

  const showOrgSwitcher =
    session.memberships.length > 0 &&
    !isCollectionAdminView &&
    !mismatchedCollectionAdmin

  const isGroupManager =
    orgRoles.includes("organization-group_manager") ||
    groupRoles.includes("group-user_manager")

  const canObserve =
    allRoles.join(",").includes("admin") ||
    allRoles.join(",").includes("observer")

  const complianceOnly =
    !session.isOrgAdmin &&
    (orgRoles.includes("organization-observation_admin") ||
      groupRoles.includes("group-observation_admin"))

  const canViewCompliance =
    canReport &&
    (enabledAddOns.includes(FeatureName.Events) ||
      enabledAddOns.includes(FeatureName.OnDemand))

  const canSeeAnalytics =
    enabledAddOns.includes(FeatureName.Observations) &&
    !isPartner &&
    (isOrgAdmin ||
      orgRoles.includes("organization-observation_admin") ||
      groupRoles.includes("group-observation_admin"))

  // Check status of org success checklist
  const {
    field_course,
    field_badge,
    field_event,
    field_group,
    field_learning_path,
    field_user,
  } = session.successChecklist

  let isChecklistComplete =
    field_course && field_group && field_learning_path && field_user

  if (
    enabledAddOns.includes(FeatureName.Events) &&
    enabledAddOns.includes(FeatureName.Badges)
  ) {
    isChecklistComplete = isChecklistComplete && field_badge && field_event
  } else if (
    enabledAddOns.includes(FeatureName.Events) &&
    !enabledAddOns.includes(FeatureName.Badges)
  ) {
    isChecklistComplete = isChecklistComplete && field_event
  } else if (
    !enabledAddOns.includes(FeatureName.Events) &&
    enabledAddOns.includes(FeatureName.Badges)
  ) {
    isChecklistComplete = isChecklistComplete && field_badge
  }

  if (session.successChecklist.isComplete) {
    isChecklistComplete = true
  }

  let reportsItems = complianceOnly
    ? [
        {
          label: "Progress Tracking",
          path: "/reports/progress-tracking",
        },
      ]
    : [
        {
          label: "Leaderboards",
          path: "/reports/leaderboards",
        },
        {
          label: "Progress Tracking",
          path: "/reports/progress-tracking",
          isAddOn: true,
          canView: canViewCompliance,

          top: "95px",
        },
        {
          label: "Access",
          path: "/reports/learner-access",
        },
        {
          label: "Analytics",
          path: "/reports/analytics",
          isAddOn: true,
          canView: canSeeAnalytics,
          top: "195px",
        },
      ].filter((item) => item)

  return (
    <ErrorBoundary>
      <div className="navbar">
        <nav>
          <Link to="/">
            <h1 id="tour-mm-logo">
              <img className="logo" src={logoWhiteImg} alt="MobileMind" />
              {isHQ && <>hq</>}
            </h1>
          </Link>

          <ul className="menu">
            {isAuthorized && (
              <>
                {(isAdmin || canReport) &&
                  !isSiteAdmin &&
                  !isCollectionAdminView && (
                    <DropdownMenu
                      tab="reports"
                      isPartner={isPartner}
                      changeNavbar={props.changeNavbar}
                      navbar={navbar}
                      items={reportsItems}
                    />
                  )}
                {canViewContent && (
                  <ContentMenu
                    tab="content"
                    changeNavbar={props.changeNavbar}
                    setCategoryModalOpen={props.setCategoryModalOpen}
                    setReviewerGroupsModalOpen={
                      props.setReviewerGroupsModalOpen
                    }
                    isCollectionAdmin={session.isCollectionAdmin}
                    isCollectionAdminView={isCollectionAdminView}
                    navbar={navbar}
                    items={createItems}
                    canReview={canReview}
                    canCreate={canCreate || isCollectionAdmin}
                    isOrgAdmin={isOrgAdmin}
                    isPartner={isPartner}
                    serviceAccountId={serviceAccountId}
                    isAdmin={isOrgAdmin || isGroupAdmin}
                    isHQ={isHQ}
                    enabledAddOns={enabledAddOns}
                  />
                )}

                {isCollectionAdminView && (
                  <>
                    <DropdownMenu
                      tab="manage"
                      changeNavbar={props.changeNavbar}
                      navbar={navbar}
                      items={[
                        {
                          label: "Organizations",
                          path: "/",
                        },
                        {
                          label: "Collection Settings",
                          path: "/collection-settings",
                        },
                        {
                          label: "ReadyMade Marketplace",
                          path: "/marketplace",
                        },
                      ]}
                    />

                    <li>
                      <Button
                        onClick={() => {
                          setIsJumpToOrgOpen(true)
                        }}
                      >
                        Jump to Org
                      </Button>

                      <JumpToOrgSelect
                        open={isJumpToOrgOpen}
                        onClose={() => setIsJumpToOrgOpen(false)}
                      />
                    </li>
                  </>
                )}

                {(canSchedule ||
                  canReviewExternal ||
                  isEventPersonnel ||
                  canObserve) &&
                  !isCollectionAdminView &&
                  !isPartner && (
                    <FeatureUpsell
                      position={{
                        top: 10,
                        right: "auto",
                        width: "350px",
                      }}
                      eventsOverride={true}
                      hidePopover={showEventsTab}
                    >
                      <li id="tour-events">
                        <Button
                          disabled={!showEventsTab}
                          className={classNames({
                            isLocation: navbar.activeItem === "events",
                          })}
                        >
                          <Link
                            style={{ opacity: !showEventsTab ? 0.5 : 1 }}
                            to="/events"
                          >
                            Events
                          </Link>
                        </Button>
                      </li>
                    </FeatureUpsell>
                  )}

                {(isOrgAdmin || isGroupManager) &&
                  !isPartner &&
                  !isCollectionAdminView && (
                    <AccountMenu
                      enabledAddOns={enabledAddOns}
                      tab="account"
                      changeNavbar={props.changeNavbar}
                      navbar={navbar}
                      isSiteAdmin={isSiteAdmin}
                      isGroupManager={isGroupManager}
                      items={[
                        {
                          label: "Users",
                          path: "/org-portal/user",
                          isMobileMind: true,
                        },
                        {
                          label: "Groups",
                          path: "/org-portal/groups",
                          isMobileMind: true,
                        },

                        {
                          label: isSiteAdmin ? "Organizations" : null,
                          path: "/organizations",
                          isMobileMind: false,
                        },
                        {
                          label: "Account Info",
                          path: "/org-portal/license-info",
                          isMobileMind: true,
                        },
                      ]}
                      supportItems={[
                        {
                          label: "Schedule an HQ Session",
                          path: "https://meetings.hubspot.com/laura33/mobilemind-coaching-",
                          isExternal: true,
                        },
                        {
                          label: "Submit a Ticket",
                          path: "/submit-ticket",
                          isExternal: false,
                        },
                        {
                          label: "FAQs",
                          path: "https://knowledge.mobilemind.io/",
                          isExternal: true,
                        },
                      ]}
                    />
                  )}

                {session.isMasquerading && (
                  <li>
                    <Button
                      onClick={() => {
                        dispatch(unmasqueradeUser())
                        setIsUnmasquerading(true)
                      }}
                    >
                      Unmasquerade
                    </Button>
                  </li>
                )}
              </>
            )}
          </ul>
          {isUnmasquerading && (
            <Loading message="Just a moment..." fullPage={true} />
          )}
          {!isHQ && !session.userCollection && (
            <div className="upgrade">
              <span className="icon upgrade" />
              Upgrade to HQ
            </div>
          )}
          {!isCollectionAdminView && (
            <div id="tour-learner">
              <a href={learnURL}>
                <span className="swap icon" />
                Learn
              </a>
            </div>
          )}

          {mismatchedCollectionAdmin && (
            <div
              id="tour-learner"
              className="collection-link"
              onClick={() => {
                dispatch(
                  switchActiveMembership(null, process.env.REACT_APP_HQ_URL)
                )
              }}
            >
              <span className="swap icon" />
              Back to {session.userCollection[0].label[0].value}
            </div>
          )}

          <div className="userInfo">
            <strong
              className="user-name"
              style={{ top: session.memberships.length > 0 ? 6 : 0 }}
            >
              {session.user.attributes.field_first_name &&
                session.user.attributes.field_first_name.toLowerCase()}{" "}
              {session.user.attributes.field_last_name &&
                session.user.attributes.field_last_name.toLowerCase()}
            </strong>
            <span>
              {showOrgSwitcher && (
                <OrgSwitcher
                  activeOrgId={
                    session.user.relationships.field_active_membership.data
                      ? session.user.relationships.field_active_membership.data
                          .id
                      : session.group.uuid[0].value
                  }
                  isHQ={true}
                  memberships={session.memberships}
                  onChange={(selectedGroupId) => {
                    dispatch(
                      switchActiveMembership(
                        selectedGroupId,
                        process.env.REACT_APP_HQ_URL
                      )
                    )
                  }}
                />
              )}

              {rolesArray.length > 0 && (
                <span
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    opacity: 1,
                  }}
                  onMouseEnter={(event) => {
                    setAnchorEl(event.currentTarget)
                  }}
                  onMouseLeave={(event) => {
                    setAnchorEl(null)
                  }}
                >
                  {!showOrgSwitcher && <>{session.group.label[0].value} • </>}
                  View HQ Roles
                </span>
              )}
            </span>
          </div>

          <Popover
            id="roles-popover"
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={() => setAnchorEl(null)}
            disableRestoreFocus
            sx={{
              pointerEvents: "none",
              "& .MuiPaper-root": {
                padding: 2,
              },
            }}
          >
            <ul>
              {rolesArray.map((role, index) => {
                if (!role.includes("member")) {
                  return (
                    <li style={{ textTransform: "capitalize" }} key={index}>
                      {role.includes("Group") && (
                        <span className="icon group" />
                      )}
                      {role.includes("Org") && (
                        <span className="icon district" />
                      )}
                      {role.includes("Partner") && (
                        <span className="icon support" />
                      )}
                      {role
                        .replace("Org event_personnel", "Event Personnel")
                        .replace(
                          "Org observation_admin",
                          "Org Observation Admin"
                        )
                        .replace(
                          "Group observation_admin",
                          "Group Observation Admin"
                        )}
                    </li>
                  )
                }
                return null
              })}
            </ul>
          </Popover>

          <UserMenu
            session={props.session}
            updateProfile={(profile, session) => {
              this.props.updateProfile(profile, session)
            }}
            profilePicSrc={profilePicSrc}
            isOrgAdmin={isOrgAdmin}
            isServiceAdmin={isServiceAdmin}
            serviceAccountId={serviceAccountId}
            startTour={() => {
              props.startTour()
            }}
            logout={() => {
              props.logout()
            }}
          />
          {session.successChecklist.fetched && (
            <>
              {!isChecklistComplete ? (
                <>
                  {isOrgAdmin && !isCollectionAdminView ? (
                    <SuccessChecklist />
                  ) : (
                    <NotificationsMenu
                      {...props}
                      onClick={() => {}}
                      isHQ={true}
                    />
                  )}
                </>
              ) : (
                <NotificationsMenu {...props} onClick={() => {}} isHQ={true} />
              )}
            </>
          )}
        </nav>
      </div>
    </ErrorBoundary>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
