import { Button } from "@mui/material"
import ButtonLarge from "./ButtonLarge"
import theme from "../theme/theme"
import "../styles/sidebar.scss"
import classNames from "classnames"
import { FeatureName } from "../types/utilities"
import { FeatureUpsell } from "./FeatureUpsell"

interface SidebarProps {
  sections: {
    title: string
    id?: string
    children: {
      title: string
      iconClass: string
      onClick?: () => void
      count?: number
    }[]
  }[]
  updateFilters?: (params: {
    dateRange: string
    category: number | string
  }) => void
  setDateMin?: (arg: string) => void
  enabledAddOns?: string[]
  activeItem: string
  action?: {
    href: string
    label: string
  }
}

const SidebarSectioned = ({
  sections,
  enabledAddOns,
  activeItem,
  action,
}: SidebarProps) => {
  return (
    <div id="sidebar" className="sectioned">
      {sections.map((section) => {
        let featureEnabled = true
        let shouldReturn = true
        if (window.location.href.includes("/events")) {
          // External Events
          if (
            section.title === "External Events" &&
            !enabledAddOns?.includes(FeatureName.Events) &&
            !enabledAddOns?.includes(FeatureName.OnDemand)
          ) {
            if (
              process.env.REACT_APP_HQ_URL &&
              window.location.href.includes(process.env.REACT_APP_HQ_URL)
            ) {
              featureEnabled = false
            } else {
              shouldReturn = false
            }
          }

          // Customize
          if (
            section.title === "Customize" &&
            !enabledAddOns?.includes(FeatureName.Events) &&
            !enabledAddOns?.includes(FeatureName.OnDemand)
          ) {
            featureEnabled = false
          }
        }
        if (shouldReturn) {
          return (
            <div key={section.title} style={{ position: "relative" }}>
              <FeatureUpsell
                hidePopover={featureEnabled}
                position={{
                  top: 50,
                  width: 215,
                  marginLeft: 2,
                }}
              >
                <div
                  style={{
                    opacity: featureEnabled ? 1 : 0.5,
                    pointerEvents: featureEnabled ? "all" : "none",
                  }}
                  id={section.id}
                >
                  <h3>{section.title}</h3>
                  <ul>
                    {section.children.map((child) => {
                      const count = child.count ? child.count : 0

                      return (
                        <li key={child.title}>
                          <Button
                            className={classNames(
                              activeItem === child.title && "active"
                            )}
                            style={{
                              backgroundColor:
                                activeItem === child.title
                                  ? theme.palette.secondary.main
                                  : "inherit",
                            }}
                            onClick={child.onClick}
                          >
                            <span className={"icon " + child.iconClass} />
                            <div style={{ flexGrow: 1 }}>{child.title}</div>
                            {count > 0 && (
                              <span className="count">{count}</span>
                            )}
                          </Button>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </FeatureUpsell>
            </div>
          )
        }
        return null
      })}

      {action && (
        <a style={{ padding: 15, display: "flex" }} href={action.href}>
          <ButtonLarge style={{ width: "100%" }} className="button primary">
            <span>{action.label}</span>
          </ButtonLarge>
        </a>
      )}
    </div>
  )
}

export default SidebarSectioned
