import { Button, Menu, MenuItem } from "@mui/material"
import { MouseEvent, useState } from "react"
import { useDialogState } from "@mobilemind/common/src/hooks/useDialogState"
import { AIGenieMenuItem } from "./AIGenieMenuItem"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setCurrentTab } from "./entityListSlice"
import { CreateCourseGenieContainer } from "features/genie/containers/CreateCourseGenieContainer"
import plusImg from "../../img/plus.svg"
import {
  selectGenieWishesRemaining,
  selectHasGenieAccess,
  selectHasGenieWishAccess,
} from "store/selectors"
import { getGenieCreatedCourses } from "store/reducers/session"
import { FeatureUpsell } from "@mobilemind/common/src/components/FeatureUpsell"

type CreateCourseMenuProps = {
  hidden?: boolean
}

export const CreateCourseMenu = (props: CreateCourseMenuProps) => {
  const dispatch = useAppDispatch()

  const hasGenieAccess = useAppSelector(selectHasGenieAccess)
  const hasGenieWishAccess = useAppSelector(selectHasGenieWishAccess)
  const genieWishesRemaining = useAppSelector(selectGenieWishesRemaining)

  const showNoWishesRemaining =
    !hasGenieAccess && hasGenieWishAccess && genieWishesRemaining === 0

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { isDialogOpen, openDialog, closeDialog } = useDialogState()

  function goToCommunity() {
    setAnchorEl(null)
    dispatch(setCurrentTab({ page: "orgCourses", tab: "Templates" }))
  }

  return (
    <>
      <Button
        style={{
          opacity: props.hidden ? 0 : 1,
          marginTop: props.hidden ? -150 : 0,
          transition: ".3s",
        }}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
          dispatch(getGenieCreatedCourses())
        }}
        className="button small"
        aria-owns={anchorEl ? "preview-menu" : undefined}
      >
        <img alt={"Create Course"} src={plusImg} /> Create Course
      </Button>
      <Menu
        id="create-menu"
        className="create-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {hasGenieAccess && (
          <AIGenieMenuItem
            onClick={() => {
              setAnchorEl(null)
              openDialog()
            }}
          />
        )}
        {!showNoWishesRemaining && !hasGenieAccess && (
          <FeatureUpsell
            position={{
              top: -10,
              right: 190,
            }}
          >
            <AIGenieMenuItem hideWishesMessage={true} onClick={() => {}} />
          </FeatureUpsell>
        )}
        <MenuItem>
          <Link to="/create/courses/new">
            <span className="icon laptop" />
            New Course
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create/courses" onClick={goToCommunity}>
            <span className="icon community" />
            From Community
          </Link>
        </MenuItem>
        {showNoWishesRemaining && (
          <AIGenieMenuItem
            onClick={() => {
              setAnchorEl(null)
              openDialog()
            }}
          />
        )}
      </Menu>
      <CreateCourseGenieContainer open={isDialogOpen} onClose={closeDialog} />
    </>
  )
}
