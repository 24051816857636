import { Switch, Route } from "react-router-dom"
import { useAppSelector } from "store/hooks"

import CreateGoalLayout from "features/createGoal/CreateGoalLayout"
import GoalListContainer from "features/entityList/GoalListContainer"

import CreateBadgeLayout from "features/createBadge/CreateBadgeLayout"
import BadgeListContainer from "features/entityList/BadgeListContainer"

import CreateAnnouncementLayout from "features/createAnnouncement/CreateAnnouncementLayout"
import AnnouncementsListContainer from "features/entityList/AnnouncementsListContainer"
import { CourseFormContainer } from "features/courseForm/CourseFormContainer"
import CourseListContainer from "features/entityList/CourseListContainer"

import CreateLearningPathLayout from "features/createLearningPath/CreateLearningPathLayout"
import LearningPathListContainer from "features/entityList/LearningPathListContainer"
import CreateQuickLinksForm from "features/createQuickLinks/CreateQuickLinksForm"
import { EditRubricContainer } from "features/rubrics/EditRubricContainer"
import CreateMandatedTrainingLayout from "features/createMandatedTraining/CreateMandatedTrainingLayout"
import MandatedTrainingListContainer from "features/entityList/MandatedTrainingListContainer"
import { CourseTemplateContainer } from "features/courseForm/CourseTemplateContainer"
import { RubricListContainer } from "features/entityList/RubricListContainer"

import Unauthorized from "@mobilemind/common/src/components/Unauthorized"
import { FeatureName } from "store/reducers/session"

export const ContentSwitch = (props) => {
  const session = useAppSelector((state) => state.session)

  const isHQ =
    session.isSiteAdmin ||
    (session.group &&
      session.group.field_hq[0] &&
      session.group.field_hq[0].value)

  const {
    enabledAddOns,
    isOrgAdmin,
    isGroupAdmin,
    canCreate,
    canCreateGoals,
    isCollectionAdmin,
  } = session

  const {
    activeCourse,
    activeBadge,
    activeGoal,
    activeLearningPath,
    activeMandatedTraining,
    activeAnnouncement,
  } = props

  const canAccessGoals =
    canCreateGoals && enabledAddOns.includes(FeatureName.OnDemand)

  const canBadge =
    enabledAddOns.includes(FeatureName.Badges) && isHQ && canCreate

  const canViewMandatedTraining =
    enabledAddOns.includes(FeatureName.OnDemand) &&
    (isOrgAdmin || isGroupAdmin || canCreate)

  const canRubric =
    isHQ && canCreate && enabledAddOns.includes(FeatureName.Observations)
  return (
    <Switch>
      <Route
        exact
        path="/create/rubrics"
        component={canRubric && isOrgAdmin ? RubricListContainer : Unauthorized}
      />
      <Route
        exact
        path="/create/rubrics/new"
        component={canRubric && canCreate ? EditRubricContainer : Unauthorized}
      />
      <Route
        exact
        path="/create/rubrics/:uuid"
        component={canRubric ? EditRubricContainer : Unauthorized}
      />
      <Route
        exact
        path="/create/courses"
        component={isHQ && canCreate ? CourseListContainer : Unauthorized}
      />
      <Route
        exact
        path="/create/courses/:id/template"
        component={isHQ && canCreate ? CourseTemplateContainer : Unauthorized}
      />
      <Route
        exact
        path={["/create/courses/new", "/create/courses/:id/edit"]}
        component={
          isHQ && canCreate && !activeCourse.unauthorized
            ? CourseFormContainer
            : Unauthorized
        }
      />

      <Route
        exact
        path="/create/goals"
        component={canAccessGoals ? GoalListContainer : Unauthorized}
      />

      <Route
        exact
        path="/create/mandated-training"
        component={
          canViewMandatedTraining ? MandatedTrainingListContainer : Unauthorized
        }
      />

      <Route
        exact
        path="/create/mandated-training/:id/:type?"
        component={
          canViewMandatedTraining && !activeMandatedTraining.unauthorized
            ? CreateMandatedTrainingLayout
            : Unauthorized
        }
      />

      <Route
        exact
        path="/create/goals/:id/:type?"
        component={
          canAccessGoals && !activeGoal.unauthorized
            ? CreateGoalLayout
            : Unauthorized
        }
      />

      <Route
        exact
        path="/create/badges"
        component={canBadge ? BadgeListContainer : Unauthorized}
      />

      <Route
        exact
        path="/create/badges/:id/:type?"
        component={
          canBadge && !activeBadge.unauthorized
            ? CreateBadgeLayout
            : Unauthorized
        }
      />

      <Route
        exact
        path="/create/learning-paths"
        component={isHQ && canCreate ? LearningPathListContainer : Unauthorized}
      />
      <Route
        exact
        path="/create/learning-paths/:id/:type?"
        component={
          isHQ && canCreate && !activeLearningPath.unauthorized
            ? CreateLearningPathLayout
            : Unauthorized
        }
      />

      <Route
        exact
        path="/create/announcements"
        component={
          canCreate || isCollectionAdmin
            ? AnnouncementsListContainer
            : Unauthorized
        }
      />

      <Route
        exact
        path="/create/announcements/:id/:type?"
        component={
          (canCreate || isCollectionAdmin) && !activeAnnouncement.unauthorized
            ? CreateAnnouncementLayout
            : Unauthorized
        }
      />

      <Route
        exact
        path="/create/quick-links"
        component={isOrgAdmin ? CreateQuickLinksForm : Unauthorized}
      />
    </Switch>
  )
}
