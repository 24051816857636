import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import ReactGA from "react-ga4"
import { StyledEngineProvider, ThemeProvider } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import App from "./App"
import Login from "./components/Login"
import BadgeStudio from "./features/createBadge/BadgeStudio"
import store from "./store/store"
import {
  getToken,
  getUser,
  getUserId,
} from "@mobilemind/common/src/actions/user"
import theme from "@mobilemind/common/src/theme/theme"
import "./styles/index.scss"

import SignUpLayout from "./features/signup/SignUpLayout"
import Snackbar from "components/Snackbar"
if (process.env.REACT_APP_HQ_GA4_ID) {
  ReactGA.initialize(process.env.REACT_APP_HQ_GA4_ID)
}

store.dispatch(getUserId()).then((response) => {
  if (response) {
    store.dispatch(getUser(store.getState().session.userId)).then(() => {
      // We have to do this now for PATCH requests, see
      // https://drupal.stackexchange.com/questions/230159/message-x-csrf-token-request-header-is-missing/230238

      const { user } = store.getState().session
      // Identify the current user for Google Analytics
      ReactGA.gtag("set", "user_properties", {
        userid: user.id,
      })

      if (process.env.REACT_APP_HQ_CLARITY_TAG) {
        // Set the custom ID for Clarity to the session user's ID.
        window.clarity("identify", user.id)
      }

      store.dispatch(getToken()).then(() => {
        ReactDOM.render(
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Provider store={store}>
                  <App />
                </Provider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>,
          document.getElementById("root")
        )
      })
    })
  }
  // Or if we're at the sign-up page and the user is anonymous
  else if (window.location.href.includes("sign-up")) {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Provider store={store}>
            <SignUpLayout />
            <Snackbar />
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>,
      document.getElementById("root")
    )
  }

  // Or if we're at the public badge studio
  else if (window.location.href.includes("/badge-studio")) {
    ReactDOM.render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={store}>
              <BadgeStudio />
            </Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>,
      document.getElementById("root")
    )
  } else {
    ReactDOM.render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={store}>
              <Login />
            </Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>,
      document.getElementById("root")
    )
  }
})
