import { useEffect } from "react"
import { fetchObservationUserRubric } from "./activeEventSlice"
import { useAppDispatch } from "store/hooks"
import { UserRubricResultsContainer } from "@mobilemind/common/src/components/UserRubricResultsContainer"
import { Box } from "@mui/material"

export function EventRubricCompleted({ activeEvent }) {
  const { userRubricId, observationUserRubric } = activeEvent
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!observationUserRubric.fetched && userRubricId) {
      dispatch(fetchObservationUserRubric({ userRubricId }))
    }
  }, [observationUserRubric.fetched, userRubricId, dispatch])

  const rubricData = observationUserRubric.data

  return (
    <Box sx={{ marginTop: 10 }}>
      {rubricData && rubricData.title && (
        <UserRubricResultsContainer userRubric={rubricData} />
      )}
    </Box>
  )
}
