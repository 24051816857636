import { useState, Component } from "react"
import { useAppDispatch } from "store/hooks"
import { connect } from "react-redux"

import {
  FormControl,
  RadioGroup,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Radio,
} from "@mui/material"
import _ from "lodash"

import classNames from "classnames"
import DeleteButton from "../../../components/DeleteButton"
import { getOrgBadges } from "../../../actions/badges"

import plusImg from "../../../img/plus.svg"
import attendeesPic from "../../../img/attendees-blue.svg"

import UserSelect from "../../userSelect/UserSelect"
import BadgeStudio from "../../createBadge/BadgeStudio"

import { changeAttendees, updateField } from "./activeEventSlice"
import {
  updateConferenceField,
  changeConferenceAttendees,
} from "../conference/activeConferenceSlice"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import { FeatureName } from "store/reducers/session"
import { FeatureUpsell } from "@mobilemind/common/src/components/FeatureUpsell"

const mapStateToProps = (
  { activeConference, entityList, session },
  ownProps
) => {
  return {
    activeConference,
    isConference: ownProps.isConference,
    session,
    entityList,
    orgBadges: _.orderBy(entityList.orgBadges.data, (badge) => badge.name),
    jobTitles: session.jobTitles.data,
  }
}

const mapDispatchToProps = {
  getOrgBadges,
}

function EventAttendeesContainer(props) {
  const { session, isConference, isObservation } = props
  const event = props.activeEvent
  const activeConference = props.activeConference

  let isOrgAdmin =
    session.orgRoles.includes("organization-admin") ||
    session.orgRoles.includes("organization-scheduler")

  const dispatch = useAppDispatch()

  const [isBadgeModalOpen, setBadgeModalOpen] = useState(false)
  const allRoles = session.groupRoles.concat(session.orgRoles).join(",")

  let groupLabel
  if (
    session.group &&
    (event.isOrgLevel || isOrgAdmin || activeConference.isOrgLevel)
  ) {
    groupLabel = session.group.label[0].value
  } else if (session.user.attributes.field_subgroup) {
    groupLabel = session.user.attributes.field_subgroup
  }

  let isGroupLevel =
    (allRoles.includes("group-admin") ||
      allRoles.includes("group-scheduler")) &&
    !allRoles.includes("organization-admin") &&
    !allRoles.includes("organization-scheduler")

  if (isOrgAdmin && !event.isOrgLevel) {
    groupLabel = event.subGroups[0] && event.subGroups[0].attributes.label
  }

  const updateInfo = props.isConference ? updateConferenceField : updateField
  const updateAttendees = props.isConference
    ? changeConferenceAttendees
    : changeAttendees

  let title = props.isConference ? "Conference" : "Event"
  if (props.isSession) {
    title = "Session"
  }
  if (props.isObservation) {
    title = "Observation"
  }

  let disablePDSelection =
    event.id &&
    event.awardPD === "sessions" &&
    event.originalAwardPD !== "conference"

  const showGroupRadio =
    (!isGroupLevel && event.originalAttendeeMethod === "all-group") ||
    isGroupLevel

  return (
    <div className="event-content two-column">
      <div className="flexRow columns">
        <div className="column left">
          <div
            className={classNames(
              "required",
              event.missingFields.includes(
                "Participants: Attendee Selection"
              ) && "active"
            )}
          >
            {!isObservation ? (
              <h2>{title} Participants*</h2>
            ) : (
              <h2>Observation Participant</h2>
            )}

            {!isObservation && (
              <FormControl variant="standard">
                <RadioGroup
                  value={event.attendeeMethod}
                  onChange={(event) =>
                    dispatch(
                      updateInfo({
                        field: "attendeeMethod",
                        value: event.target.value,
                      })
                    )
                  }
                >
                  {!isGroupLevel && (
                    <FormControlLabel
                      value={"all"}
                      control={<Radio />}
                      label={
                        "All " + session.group.label[0].value + " Learners"
                      }
                    />
                  )}

                  {showGroupRadio && (
                    <FormControlLabel
                      value={"all-group"}
                      control={<Radio />}
                      label={"All " + groupLabel + " Learners"}
                    />
                  )}

                  <FormControlLabel
                    value={"custom"}
                    control={<Radio />}
                    label={"Custom Attendee List"}
                  />
                </RadioGroup>
              </FormControl>
            )}
          </div>

          <div className="panel current-selection current-people current-attendees">
            {event.attendeeMethod === "all" ||
            event.attendeeMethod === "all-group" ? (
              <p>
                All{" "}
                {event.attendeeMethod === "all-group" ? (
                  <strong>{groupLabel} </strong>
                ) : (
                  <strong>{session.group.label[0].value} </strong>
                )}
                learners will be invited to this{" "}
                <span style={{ textTransform: "lowercase" }}>{title}</span>.
              </p>
            ) : (
              <>
                {!event.subGroups.length &&
                  !event.jobTitles.length &&
                  !event.attendees.length &&
                  !isObservation && (
                    <>
                      {isOrgAdmin ? (
                        <p>
                          Select a combination of <strong>Groups</strong>,{" "}
                          <strong>Job Titles</strong>, and/or{" "}
                          <strong>Learners</strong> to invite to this{" "}
                          <span style={{ textTransform: "lowercase" }}>
                            {title}
                          </span>
                          .
                        </p>
                      ) : (
                        <p>
                          Select a combination of <strong>Job Titles</strong>{" "}
                          and/or <strong>Learners</strong> to invite to this{" "}
                          <span style={{ textTransform: "lowercase" }}>
                            {title}
                          </span>
                          .
                        </p>
                      )}
                    </>
                  )}
              </>
            )}
            {isObservation && (
              <p>Select an individual learner for this observation.</p>
            )}

            {(event.attendeeMethod === "custom" || isObservation) && (
              <>
                <ul className="current-people-list">
                  {event.subGroups.length > 0 && (
                    <>
                      {event.subGroups.map((group) => {
                        if (group) {
                          return (
                            <li key={group.id} className={"event-select-item"}>
                              <div className="flexRow">
                                <span className="icon group"></span>
                                <strong>{group.attributes.label}</strong>
                                <DeleteButton
                                  onClick={() => {
                                    dispatch(
                                      updateAttendees({
                                        field: "subGroups",
                                        value: group,
                                        type: "remove",
                                      })
                                    )
                                  }}
                                />
                              </div>
                            </li>
                          )
                        }
                        return null
                      })}
                    </>
                  )}
                  {event.jobTitles.length > 0 &&
                    props.session.jobTitles.fetched && (
                      <>
                        {event.jobTitles.map((jobTitle) => {
                          const activeJobTitle =
                            props.session.jobTitles.data.find(
                              (item) => item.id === jobTitle.id
                            )

                          return (
                            <li
                              key={activeJobTitle.id}
                              className={classNames("event-select-item")}
                            >
                              <div className="flexRow">
                                <span
                                  className={classNames(
                                    "icon role",
                                    activeJobTitle.attributes.name.toLowerCase()
                                  )}
                                ></span>
                                <strong>
                                  {activeJobTitle.attributes.name}
                                </strong>
                                <DeleteButton
                                  onClick={() => {
                                    dispatch(
                                      updateAttendees({
                                        field: "jobTitles",
                                        value: activeJobTitle,
                                        type: "remove",
                                      })
                                    )
                                  }}
                                />
                              </div>
                            </li>
                          )
                        })}
                      </>
                    )}

                  {event.attendees.length > 0 && (
                    <>
                      {event.attendees.map((user) => {
                        const {
                          field_first_name,
                          field_last_name,
                          field_subgroup,
                        } = user.attributes ?? user

                        return (
                          <li
                            key={user.id}
                            className={classNames("event-select-item")}
                          >
                            <div className="flexRow">
                              <UserInitial
                                containerStyle={{ marginRight: 10 }}
                                size={40}
                                initials={
                                  field_first_name.toUpperCase().charAt(0) +
                                  field_last_name.toUpperCase().charAt(0)
                                }
                              />

                              <div className="flexRow">
                                {field_first_name ? (
                                  <strong className="learner-name">
                                    {field_first_name &&
                                      field_first_name.toLowerCase()}{" "}
                                    {field_last_name &&
                                      field_last_name.toLowerCase()}
                                  </strong>
                                ) : (
                                  <strong className="learner-name">
                                    {field_first_name &&
                                      field_first_name.toLowerCase()}{" "}
                                    {field_last_name &&
                                      field_last_name.toLowerCase()}
                                  </strong>
                                )}

                                {!field_first_name && !user.attributes && (
                                  <strong>Blocked User</strong>
                                )}

                                <span>{field_subgroup}</span>
                              </div>
                              <DeleteButton
                                onClick={() => {
                                  dispatch(
                                    updateAttendees({
                                      field: "attendees",
                                      value: user,
                                      type: "remove",
                                    })
                                  )
                                }}
                              />
                            </div>
                          </li>
                        )
                      })}
                    </>
                  )}
                </ul>
              </>
            )}

            {(isObservation || event.attendeeMethod !== "unselected") && (
              <div className="flexRow" style={{ justifyContent: "flex-end" }}>
                <Button
                  onClick={() =>
                    dispatch(
                      updateInfo({
                        field: "isAttendeeModalOpen",
                        value: true,
                      })
                    )
                  }
                  className="button small"
                >
                  <img src={attendeesPic} alt="Add Participants" />
                  Add Participant{!isObservation && "s"}
                </Button>
              </div>
            )}
          </div>

          <UserSelect
            isGroupAdmin={isGroupLevel}
            update={updateAttendees}
            maxSelectable={isObservation ? 1 : null}
            initialTab={"users"}
            entity={event}
            open={event.isAttendeeModalOpen}
            onClose={() =>
              dispatch(
                updateInfo({ field: "isAttendeeModalOpen", value: false })
              )
            }
            visibleTabs={isObservation ? "users" : "groups, job-titles, users"}
            userLabel={"learners"}
            searchText={
              isObservation
                ? "Search for a specific learner to invite them."
                : "Search for individual learners to invite them."
            }
          />
        </div>

        <div className="column right">
          {
            // Show PD Credit if it's a conference session and the conference awards PD session-by-session
            ((props.isSession && activeConference.awardPD === "sessions") ||
              // Or if it's a regular event
              (!props.isConference && !props.isSession) ||
              // Or if it's a conference
              props.isConference) &&
              // And it's not an observation
              !isObservation && (
                <>
                  <h3>
                    PD Credit
                    {props.isConference && (
                      <div className="pdCreditToolTip">
                        <span className="icon info" />
                        <div className="inner">
                          <p>
                            <strong>
                              When awarding PD credit for individual session
                              attendance
                            </strong>
                            , facilitators simply need to provide the session
                            Join Code to participants during each session.
                            Participants will earn PD credit for each session
                            they attend.
                          </p>
                          <p>
                            <strong>
                              When awarding PD credit for conference attendance
                            </strong>
                            , participants are awarded credit when checking into
                            the overall conference. When using a Join Code,
                            you'll need to provide the code to all participants
                            at some point during the conference.
                            <br />
                            <br />
                            The Join Code option will still be available for
                            individual sessions, so you'll still be able to
                            track each session's attendance.
                          </p>
                        </div>
                      </div>
                    )}
                  </h3>
                  {props.isConference && (
                    <FormControl variant="standard">
                      <RadioGroup
                        value={event.awardPD}
                        onChange={(event) =>
                          dispatch(
                            updateInfo({
                              field: "awardPD",
                              value: event.target.value,
                            })
                          )
                        }
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormControlLabel
                            style={
                              disablePDSelection
                                ? { pointerEvents: "none", opacity: 0.4 }
                                : {}
                            }
                            value={"conference"}
                            control={<Radio />}
                            label={"Award PD credit for conference attendance"}
                          />
                          <FormControlLabel
                            value={"sessions"}
                            control={<Radio />}
                            label={
                              "Award PD credit for individual session attendance"
                            }
                          />
                        </div>
                      </RadioGroup>
                      {event.awardPD === "sessions" && (
                        <p
                          style={{
                            maxWidth: 425,
                            color: "#E75C5D",
                            fontWeight: "700",
                            fontSize: 14,
                          }}
                        >
                          If you select this option and save, you will not be
                          able to change how PD credit is awarded for this
                          conference.
                        </p>
                      )}
                    </FormControl>
                  )}
                  {((props.isConference &&
                    activeConference.awardPD === "conference") ||
                    (props.isSession &&
                      activeConference.awardPD === "sessions") ||
                    (!props.isConference && !props.isSession)) &&
                    !isObservation && (
                      <div className="flexRow pdCreditRow">
                        <FormControl variant="standard">
                          <TextField
                            variant="standard"
                            inputProps={{ type: "number", min: 0 }}
                            className="pdCredit"
                            value={event.pdCredit.hours}
                            onChange={(newValue) => {
                              let hours =
                                newValue.target.value < 0
                                  ? 0
                                  : newValue.target.value
                              dispatch(
                                updateInfo({
                                  field: "pdCredit",
                                  value: {
                                    hours,
                                    minutes: event.pdCredit.minutes,
                                  },
                                })
                              )
                            }}
                          />
                        </FormControl>
                        <span className="label">Hours</span>

                        <FormControl variant="standard" className="minutes">
                          <TextField
                            variant="standard"
                            inputProps={{ type: "number", max: 59, min: 0 }}
                            className="pdCredit"
                            value={event.pdCredit.minutes}
                            onChange={(newValue) => {
                              let minutes =
                                newValue.target.value < 0
                                  ? 0
                                  : newValue.target.value
                              dispatch(
                                updateInfo({
                                  field: "pdCredit",
                                  value: {
                                    hours: event.pdCredit.hours,
                                    minutes,
                                  },
                                })
                              )
                            }}
                          />
                        </FormControl>
                        <span className="label">Minutes</span>
                      </div>
                    )}
                </>
              )
          }

          {!props.isSession && (
            <>
              <h3>{title} Reminders</h3>
              <FormControl variant="standard" style={{ maxWidth: 300 }}>
                <Select
                  variant="standard"
                  id="event-type-select"
                  multiple
                  value={event.sendReminders}
                  onChange={(event) =>
                    dispatch(
                      updateInfo({
                        field: "sendReminders",
                        value: event.target.value,
                      })
                    )
                  }
                >
                  <MenuItem className="multiSelect" value={"none"}>
                    No Reminders
                  </MenuItem>
                  <MenuItem className="multiSelect" value={"1_day"}>
                    1 Day Before
                  </MenuItem>
                  <MenuItem className="multiSelect" value={"3_day"}>
                    3 Days Before
                  </MenuItem>
                  <MenuItem className="multiSelect" value={"1_week"}>
                    1 Week Before
                  </MenuItem>
                  <MenuItem className="multiSelect" value={"1_month"}>
                    1 Month Before
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          )}

          {(allRoles.includes("admin") || allRoles.includes("scheduler")) &&
            !isObservation && (
              <div style={{ position: "relative" }}>
                <FeatureUpsell
                  hidePopover={props.session.enabledAddOns.includes(
                    FeatureName.Badges
                  )}
                  position={{ top: 50, right: "auto" }}
                >
                  <h3>{title} Badge</h3>
                  <div className="flexRow">
                    {event.badgeAwarded && (
                      <div
                        style={{ marginTop: 10 }}
                        className="flexRow event-badge-container"
                      >
                        {event.badgeAwarded.image && (
                          <>
                            <img
                              alt={"Badge Awarded"}
                              style={{ width: 80, height: 80, marginRight: 10 }}
                              className="badge"
                              src={
                                event.badgeAwarded.image.attributes
                                  ? process.env.REACT_APP_API_URL +
                                    event.badgeAwarded.image.attributes.uri.url
                                  : event.badgeAwarded.image
                              }
                            />
                            <strong>{event.badgeName}</strong>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <Button
                    style={{
                      maxWidth: 200,
                      pointerEvents: props.session.enabledAddOns.includes(
                        FeatureName.Badges
                      )
                        ? "auto"
                        : "none",
                      opacity: props.session.enabledAddOns.includes(
                        FeatureName.Badges
                      )
                        ? 1
                        : 0.6,
                      marginTop: 10,
                    }}
                    onClick={() => {
                      setBadgeModalOpen(true)
                    }}
                    className="button small"
                  >
                    <img alt={"Create New Badge"} src={plusImg} /> Create New
                    Badge
                  </Button>
                </FeatureUpsell>
              </div>
            )}
        </div>
      </div>

      <BadgeStudio
        event={event}
        badgeName={event.badgeName}
        hasName={true}
        sync={{ event, isConference }}
        open={isBadgeModalOpen}
        onClose={() => setBadgeModalOpen(false)}
      />
    </div>
  )
}

class EventAttendees extends Component {
  componentDidMount() {
    this.props.getOrgBadges({
      searchQuery: "",
      status: "any",
      category: null,
      subGroup: "any",
      jobTitle: "any",
      sortBy: "created",
      sortOrder: "DESC",
    })
  }

  render() {
    const props = this.props

    return <EventAttendeesContainer {...props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendees)
