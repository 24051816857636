import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment"
import qs from "qs"
import { getSubGroups } from "store/reducers/session"
import { updateEventField } from "../../../functions"
import sessionColors from "../calendar/sessionColors"
import initialState from "./initialState"

export const fetchObservationUserRubric = createAsyncThunk(
  "activeEventSlice/fetchObservationUserRubric",
  async (args) => {
    const { userRubricId } = args
    if (userRubricId) {
      let response = await fetchWrapper.get(
        "/api/user_rubric_details/" + userRubricId
      )

      if (response.ok) {
        let data = await response.json()
        return data.rubric_data
      }
    }
  }
)
export const fetchRoles = createAsyncThunk(
  "activeEventSlice/fetchRoles",
  async () => {
    let response = await fetchWrapper.get("/api/taxonomy_term/event_role")
    if (response.ok) {
      let data = await response.json()
      return data.data
    }
  }
)

export const inviteLearners = createAsyncThunk(
  "activeEventSlice/inviteLearners",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()
    const { selectedUsers, eventId, addOnId } = args

    let newlyAddedInvitees = 0
    let existingUsersArray
    // First get the current participant users from the addOn and combine them with our selected users
    let addOnResponse = await fetchWrapper.get(
      "/api/mobilemind_event_addon/event_addon_base/" + addOnId
    )

    let addOnData = await addOnResponse.json()
    let existingUsers =
      addOnData.data.relationships.field_participants_users.data

    existingUsersArray = existingUsers
      ? existingUsers.map((user) => {
          return {
            type: "user--user",
            id: user.id,
          }
        })
      : []

    let i = 0

    // Go through our selected users to invite
    while (i < selectedUsers.length) {
      // Check to see if they are invited

      const invitedResponse = await thunkAPI.dispatch(
        getEventInvitees({ eventId, uid: selectedUsers[i].uid })
      )

      // If they are not invited
      if (!invitedResponse.payload.invitee_data.length) {
        // Push them into our existing user array
        existingUsersArray.push({
          type: "user--user",
          id: selectedUsers[i].user_uuid ?? selectedUsers[i].id,
        })

        const addOnBody = JSON.stringify({
          data: {
            type: "mobile_mind_event_addon--event_addon_base",
            id: addOnId,
            relationships: {
              field_participants_users: {
                data: existingUsersArray,
              },
            },
          },
        })

        // And PATCH them onto the addon
        await fetchWrapper.patch(
          "/api/mobilemind_event_addon/event_addon_base/" + addOnId,
          session.token,
          addOnBody
        )

        newlyAddedInvitees++
      }
      i++
    }

    return { newlyAddedInvitees }
  }
)
export const createParticipantUserEvent = createAsyncThunk(
  "activeEventSlice/createParticipantUserEvent",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()
    const { invitee, eventId, eventUUID, eventBundle, rsvp } = args

    const bundle =
      eventBundle === "event_base" ? "user_event_base" : eventBundle

    // Check to see if they already have a user event for this event
    let userEventResponse = await fetchWrapper.get(
      "/api/mobile_mind_user_event/" +
        bundle +
        "?" +
        qs.stringify({
          filter: {
            "field_user.id": invitee.user_uuid ?? invitee.id,
            "field_event.id": eventUUID,
          },
        })
    )

    if (userEventResponse.ok) {
      let existingUserEventData = await userEventResponse.json()
      // If there is no user event already
      if (!existingUserEventData.data.length) {
        let body = {
          data: {
            type: "mobile_mind_user_event--" + bundle,
            attributes: {
              name: "UID: " + invitee.uid + " / Event: " + eventId,
            },
            relationships: {
              field_user: {
                data: {
                  type: "user--user",
                  id: invitee.user_uuid ?? invitee.id,
                },
              },
              field_event: {
                data: {
                  type: "mobilemind_event_entity--" + eventBundle,
                  id: eventUUID,
                },
              },
            },
          },
        }

        if (rsvp) {
          body.data.attributes.field_rsvp = rsvp
        }

        // POST a new user event
        await fetchWrapper.post(
          "/api/mobile_mind_user_event/" + bundle,
          session.token,
          JSON.stringify(body)
        )
      }
    }

    return true
  }
)

export const fetchObservationTypes = createAsyncThunk(
  "activeEventSlice/fetchObservationTypes",
  async () => {
    let response = await fetchWrapper.get("/api/taxonomy_term/observation_type")
    if (response.ok) {
      let data = await response.json()
      return data.data
    }
  }
)

export const fetchFeedbackSummary = createAsyncThunk(
  "activeEventSlice/fetchFeedbackSummary",
  async (args, thunkAPI) => {
    const { activeEvent } = thunkAPI.getState()

    const response = await fetchWrapper.get(
      "/api/event_feedback/" + activeEvent.drupal_internal__id
    )
    if (response.ok) {
      const data = await response.json()
      return data.data
    }
  }
)

export const fetchActiveEvent = createAsyncThunk(
  "activeEventSlice/fetchActiveEvent",
  async (args, thunkAPI) => {
    const { id, isSession, isObservation } = args
    const { session } = thunkAPI.getState()

    let event = {}
    let include = ["field_location", "field_current_editor"]
    if (isObservation) {
      include = include.concat([
        "field_rubric",
        "field_observation_type",
        "field_rubric",
      ])
    } else {
      include = include.concat([
        "field_tags",
        "field_personnel",
        "field_event_image",
        "field_attachment",
        "field_event_category",
        "user_id",
      ])
    }
    if (isSession) {
      include = include.concat(["field_parent_conference"])
    }

    let query = {
      filter: {
        drupal_internal__id: id,
      },
      include: include.join(","),
    }

    let bundle = isSession ? "conference_event" : "event_base"
    if (isObservation) {
      bundle = "observation"
    }
    let response = await fetchWrapper.get(
      "/api/mobilemind_event_entity/" + bundle + "?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      const parentConference = data.included?.find(
        (included) => included.type === "mobilemind_event_entity--conference"
      )

      let personnel =
        data.included &&
        data.included.filter(
          (included) =>
            included.type === "mm_event_personnel--mm_event_personnel"
        )
      event.category =
        data.included &&
        data.included.filter(
          (included) => included.type === '"taxonomy_term--category"'
        )

      const eventAuthor = data.included
        ?.filter((included) => included.type === "user--user")
        ?.find((user) => {
          return user.id === data.data[0].relationships.user_id.data.id
        })

      // If we need to try to get a conference event
      if (!data.data || !data.data.length) {
        let conferenceEvent = await fetchWrapper.get(
          "/api/mobilemind_event_entity/conference_event?" + qs.stringify(query)
        )
        if (conferenceEvent.ok) {
          data = await conferenceEvent.json()
        }
      }
      const currentEditor =
        data.included &&
        data.included.filter((included) => included.type === "user--user")

      let addOn, rooms
      let conferenceEvents =
        data.included &&
        data.included.filter(
          (included) => included.type === "mobilemind_event_entity--event_base"
        )

      // Get rooms for location
      let location =
        data.included &&
        data.included.find(
          (included) => included.type === "mobile_mind_location--location_base"
        )

      if (location) {
        let locationQuery = {
          filter: {
            "field_location.id": location.id,
          },
        }

        rooms = await fetchWrapper.get(
          "/api/mobile_mind_room/room_base?" + qs.stringify(locationQuery)
        )

        if (rooms.ok) {
          let roomsData = await rooms.json()
          rooms = roomsData.data
        }
      }
      include = [
        "field_badge",
        "field_prereq_courses",
        "field_prereq_learning_path",
        "field_participants_group",
        "field_participants_users",
        "field_participants_job_title",
        "field_feedback_form",
        "field_replacement_courses",
      ]

      query = {
        include: include.join(","),
      }

      let addOnResponse = await fetchWrapper.get(
        "/api/mobilemind_event_addon/event_addon_base/" +
          data.data[0].relationships.field_addon.data.id +
          "?" +
          qs.stringify(query)
      )
      if (addOnResponse.ok) {
        addOn = await addOnResponse.json()

        let subGroups = session.subgroups ? session.subgroups.data : []

        if (session.isOrgAdmin) {
          const subGroupResponse = await thunkAPI.dispatch(getSubGroups())
          subGroups = subGroupResponse.payload
        }
        let participantSubGroups = []

        const participantUsers = addOn.included?.filter(
          (included) => included.type === "user--user"
        )
        addOn.data.participantUsers = participantUsers

        addOn.data.relationships.field_participants_group.data.forEach(
          (participant) => {
            if (participant.type === "group--group") {
              let targetSubGroup = {
                ...subGroups.find((group) => group.id === participant.id),
              }
              targetSubGroup.type = "group_content--organization-subgroup-group"
              participantSubGroups.push(targetSubGroup)
            }
          }
        )

        addOn.data.subGroups = participantSubGroups
      }

      let log,
        logId = addOn.data.relationships.field_attendance_log.data?.id

      if (logId) {
        let logResponse = await fetchWrapper.get(
          "/api/mm_attendance_log/attendance_log_base/" + logId
        )
        if (logResponse.ok) {
          log = await logResponse.json()
        }
      }

      event = data

      const feedbackForm =
        addOn.included &&
        addOn.included.find(
          (included) => included.type === "mm_form--event_feedback"
        )

      let badge =
        addOn.included &&
        addOn.included.find(
          (included) => included.type === "badges_entity--badges_entity"
        )

      let badgeData

      if (badge) {
        let badgeResponse = await fetchWrapper.get(
          "/api/badges_entity/badges_entity/" +
            badge.id +
            "?" +
            qs.stringify({ include: "field_badge_image" })
        )
        if (badgeResponse.ok) {
          badgeData = await badgeResponse.json()
        }
      }

      if (badge && badgeData) {
        event.badgeAwarded = badge &&
          badgeData && {
            id: badgeData.data.id,
            name: badgeData.data.attributes.name,
            image: badgeData.included[0],
          }
      }

      if (!window.location.href.includes("view")) {
        if (!data.data[0].attributes.field_editor_lock_time) {
          let lockBody = {
            data: {
              id: event.data[0].id,
              type: event.data[0].type,
              attributes: {
                field_editor_lock_time: moment().format(),
              },
              relationships: {
                field_current_editor: {
                  data: {
                    type: "user--user",
                    id: session.user.id,
                  },
                },
              },
            },
          }

          fetchWrapper.patch(
            "/api/mobilemind_event_entity/" + bundle + "/" + data.data[0].id,
            session.token,
            JSON.stringify(lockBody)
          )
        }
      }
      if (isObservation) {
        event.observationType =
          data.included &&
          data.included.find(
            (inc) => inc.type === "taxonomy_term--observation_type"
          )
        event.observationRubric =
          data.included &&
          data.included.find(
            (inc) => inc.type === "mobilemind_rubric--mobilemind_rubric"
          )
        event.observationUser =
          addOn.included &&
          addOn.included.find((inc) => inc.type === "user--user")

        // We need to get the user rubric UUID if it exists for an observation
        let eventDetailsResponse = await fetchWrapper.get(
          "/api/event_details/" + id + "/" + session.group.id[0].value
        )

        const eventDetailsData = await eventDetailsResponse.json()

        event.userRubricUUID =
          eventDetailsData.data?.observation_attendee.user_rubric_uuid
        event.userRubricId =
          eventDetailsData.data?.observation_attendee.user_rubric_id
      }
      event.parentConferenceId =
        parentConference?.attributes?.drupal_internal__id
      event.feedbackForm = feedbackForm
      event.attendanceLog = log
      event.addOn = addOn
      event.rooms = rooms
      event.personnel = personnel
      event.currentEditor = currentEditor
      event.conferenceEvents = conferenceEvents
      event.eventAuthor = eventAuthor
    }
    return event
  }
)

export const fetchEventMessages = createAsyncThunk(
  "activeEventSlice/fetchEventMessages",
  async (args, thunkAPI) => {
    let query = {
      filter: {
        "field_event.id": thunkAPI.getState().activeEvent.id,
      },
      include: "user_id, field_attachment",
    }

    let response = await fetchWrapper.get(
      "/api/mm_event_message/event_message_base?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()
      const users = data.included
        ? data.included.filter((included) => included.type === "user--user")
        : []

      data.data.forEach((message) => {
        message.user = users.find(
          (user) =>
            message.relationships.user_id.data &&
            user.id === message.relationships.user_id.data.id
        )
      })

      return data.data
    }
  }
)

export const getEventInvitees = createAsyncThunk(
  "activeEventSlice/getEventInvitees",
  async (args, thunkAPI) => {
    /**
     * Represents MobileMind Invited Participants records as resources.
     * * * /api/invited_participants/{event_id}?
     * * search= [str] (user first and last name)
     * * subgroup= [int] (subgroup id)
     * * rsvp= [str] (rsvp status (accept, decline, tentative))
     * * attended= [str] ('1', '0'))
     * * &sort_by= [str] (name, rsvp, attended)
     * * &sort_order= [str] (ASC, DESC)
     * * &page[offset]= [int]
     */

    const { activeEvent } = thunkAPI.getState()

    let eventId =
      args?.eventId ?? thunkAPI.getState().activeEvent.drupal_internal__id
    const {
      inviteeSearch,
      filterRSVP,
      filterCheckedIn,
      filterGroup,
      inviteesCurrentPage,
    } = activeEvent

    let query = {
      search: inviteeSearch,
      sort_order: activeEvent.sortOrder,
      uid: args?.uid,
      page: {
        offset: 25 * inviteesCurrentPage,
      },
    }
    if (activeEvent.sortBy) {
      query.sort_by = activeEvent.sortBy
    }

    if (filterGroup !== "all") {
      query.subgroup = filterGroup
    }
    if (filterRSVP !== "any") {
      query.rsvp = filterRSVP
    }
    if (filterCheckedIn !== "any") {
      query.attended = filterCheckedIn
    }

    const response = await fetchWrapper.get(
      "/api/invited_participants/" + eventId + "?" + qs.stringify(query)
    )

    if (response.ok) {
      const data = await response.json()
      return data
    }
  }
)

export const getRooms = createAsyncThunk(
  "activeEventSlice/getRooms",
  async (args) => {
    let query = {
      filter: {
        "field_location.id": args.id,
        archive: {
          group: {
            conjunction: "OR",
          },
        },
        "null-archive": {
          condition: {
            operator: "IS NULL",
            path: "field_archive",
            memberOf: "archive",
          },
        },
        "is-archive": {
          condition: {
            operator: "=",
            path: "field_archive",
            value: false,
            memberOf: "archive",
          },
        },
      },
    }
    let i = 0
    let pages = 1
    let allRooms = []

    while (i < pages) {
      let response = await fetchWrapper.get(
        "/api/mobile_mind_room/room_base?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        pages = Math.ceil(Number(data.meta.count) / 50)

        if (data.data) {
          data.data.forEach((room) => {
            allRooms.push(room)
          })
        }

        i++
      }
    }

    return allRooms
  }
)

const debouncedgetEventInvitees = debounceThunk(getEventInvitees, 750)

const getCourses = createAsyncThunk(
  "activeEventSlice/getCourses",
  async (args) => {
    let response = await fetchWrapper.get(
      "/api/v2/course_entity/explore?search=" + args.value
    )

    if (response.ok) {
      let data = await response.json()
      let courses = []

      data.data.forEach((course) => {
        if (!courses.find((existing) => existing.id === course.id)) {
          courses.push(course)
        }
      })

      return { courses, args }
    }
  }
)

const debouncedGetCourses = debounceThunk(getCourses, 750)
export const updateCourseSearch = createAsyncThunk(
  "activeEventSlice/updateCourseSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetCourses(args))

    return args
  }
)

const getLearningPaths = createAsyncThunk(
  "activeEventSlice/getLearningPaths",
  async (search) => {
    let response = await fetchWrapper.get(
      "/api/learning_path/explore?search=" + search
    )
    if (response.ok) {
      let data = await response.json()
      return data
    }
  }
)

const debouncedGetLearningPaths = debounceThunk(getLearningPaths, 750)
export const updateLPSearch = createAsyncThunk(
  "activeEventSlice/updateLPSearch",
  async (search, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetLearningPaths(search))

    return search
  }
)

export const updateInviteeSearch = createAsyncThunk(
  "activeEventSlice/updateInviteeSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedgetEventInvitees())
    return args
  }
)
export const increaseInviteePage = createAsyncThunk(
  "activeEventSlice/increaseInviteePage",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedgetEventInvitees())
    return args
  }
)

export const updateInviteeRSVPFilter = createAsyncThunk(
  "activeEventSlice/updateInviteeRSVPFilter",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedgetEventInvitees())
    return args
  }
)

export const updateEventSortBy = createAsyncThunk(
  "activeConferenceSlice/updateEventSortBy",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedgetEventInvitees())
    return args
  }
)

export const updateEventSortOrder = createAsyncThunk(
  "activeConferenceSlice/updateEventSortOrder",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedgetEventInvitees())
    return args
  }
)

export const updateCheckedInFilter = createAsyncThunk(
  "activeEventSlice/updateCheckedInFilter",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedgetEventInvitees())
    return args
  }
)

export const updateGroupFilter = createAsyncThunk(
  "activeEventSlice/updateGroupFilter",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedgetEventInvitees())
    return args
  }
)

export const activeEventSlice = createSlice({
  name: "activeEventSlice",
  initialState,
  reducers: {
    startNewEvent: (state) => {
      state.eventAuthor = {}
      state.parentConferenceId = null
      state.addOnId = null
      state.allDay = false
      state.allOrgUsers = []
      state.attachments = []
      state.attendanceLogId = null
      state.attendanceMethod = "join_code"
      state.attendeeMethod = "unselected"
      state.attendees = []

      state.observationType = "coaching"
      state.observationRubric = null
      state.observationUser = null

      state.observationRubricUUID = null
      state.fullRubric = null
      state.observationUserRubric = {
        data: null,
        fetched: false,
      }
      state.badgeAwarded = null
      state.badgeName = ""

      state.category = null
      state.checkInStart = moment().endOf("hour").add(1, "minute").format()
      state.checkInEnd = moment()
        .endOf("hour")
        .add(1, "hours")
        .add(1, "minute")
        .format()
      state.checkedInAttendees = []
      state.color = sessionColors[6]
      state.currentLocation = null

      state.currentTab = "details"
      state.description = ""
      state.difficulty = "N/A"
      state.drupal_internal__id = null

      state.startDate = moment().endOf("hour").add(1, "minute").format()
      state.endDate = moment()
        .endOf("hour")
        .add(1, "hours")
        .add(1, "minute")
        .format()

      state.eventImage = { file: null, filename: null }
      state.eventMax = 0
      state.eventRoom = "none"

      state.fetched = true
      state.field_archive = false
      state.field_draft = false
      state.filterCheckedIn = "any"
      state.filterRSVP = "any"
      state.filterGroup = "all"
      state.sortBy = null
      state.sortOrder = "DESC"
      state.groupData = null

      state.helpfulLinks = ""
      state.pushNotifications = false
      state.id = null
      state.inviteeSearch = ""
      state.invitees = []
      state.inviteesCurrentPage = 0
      state.isAttendeeModalOpen = false
      state.isBadgeSaving = false
      state.isJoinCodeModalOpen = false
      state.isNew = true
      state.isSaving = false
      state.isSession = false

      state.jobTitles = []
      state.joinCode = ""
      state.tags = []
      state.learnerSearch = ""

      state.meetingLink = ""
      state.messages = {
        fetched: false,
        data: [],
      }
      state.missingFields = []

      state.name = ""
      state.newBadge = {
        image: null,
        filename: null,
        name: "",
      }
      state.newMessage = ""
      state.newMessageEmailTarget = "rsvp_only"

      state.notes = ""

      state.orgUsers = []
      state.originalAttendeeMethod = "unselected"
      state.originalParticipants = []
      state.pdCredit = {
        hours: 0,
        minutes: 0,
      }
      state.personnel = []
      state.personnelSearch = ""

      state.preRequisiteCourses = []
      state.preRequisiteCoursesSearch = ""
      state.preRequisiteCoursesResults = []
      state.replacementCourses = []
      state.replacementCoursesSearch = ""
      state.replacementCoursesResults = []

      state.feedbackMethod = "custom"
      state.feedbackURL = ""
      state.feedbackFormId = null
      state.feedbackRequired = false
      state.feedbackIsAnonymous = false
      state.relatedLP = null
      state.relatedLPSearch = ""
      state.relatedLPResults = []

      state.registrationStarts = moment()
        .endOf("hour")
        .add(1, "minute")
        .format()
      state.registrationEnds = moment()
        .add(1, "hours")
        .endOf("hour")
        .add(1, "minute")
        .format()
      state.registrationWindow = "unlimited"

      state.sendPublishEmail = false
      state.sendRegistrationReminders = ["none"]
      state.sendReminders = ["none"]

      state.subGroupSearch = ""
      state.subGroups = []
      state.supportContact = ""
      state.editorLock = null
      state.currentEditor = null
      state.totalInviteePages = 0
      state.type = "In Person Live"
      state.feedbackSummary = {
        sortBy: "name",
        data: {},
        fetched: false,
      }
      state.observationUserRubric = {
        data: null,
        fetched: false,
      }
    },
    setRelatedLP: (state, action) => {
      state.relatedLP = action.payload
    },
    selectCourse: (state, action) => {
      const { field, value } = action.payload

      if (value) {
        let course = { ...value }

        course.id = value.uuid
        if (!state[field].find((existing) => existing.id === course.id)) {
          state[field] = state[field].concat([course])
        }

        state[field + "Search"] = ""
      }
    },
    deleteCourse: (state, action) => {
      const { field, value } = action.payload
      state[field] = state[field].filter((course) => course.id !== value.id)
    },
    changeEventTab: (state, action) => {
      if (!state.loadEvent) {
        state.currentTab = action.payload
      }
    },
    clearBadgeImage: (state, action) => {
      state.newBadge.image = null
      state.newBadge.filename = null
    },
    updateField: (state, action) => {
      state = updateEventField(state, action)
    },
    setActiveLocation: (state, action) => {
      state.currentLocation = action.payload
    },
    setNewLocation: (state, action) => {
      state.availableRooms = []
    },
    addPersonnel: (state, action) => {
      let personnel = { ...action.payload }
      personnel.role = state.eventRoles[0].id
      personnel.field_user_image_path = personnel.user_picture
      state.personnel.push(personnel)
    },
    removePersonnel: (state, action) => {
      state.personnel = state.personnel.filter((user) => {
        if (user.user && action.payload.user) {
          return user.user.id !== action.payload.user.id
        } else if (user.uuid && action.payload.uuid) {
          return user.uuid !== action.payload.uuid
        } else {
          return true
        }
      })
    },
    setPersonnelRole: (state, action) => {
      let allPersonnel = [...state.personnel]
      let targetUser = allPersonnel.find(
        (user) => user.id === action.payload.user.id
      )
      targetUser.role = action.payload.role
      state.personnel = allPersonnel
    },
    setEventImage: (state, action) => {
      state.eventImage.image = action.payload
    },
    setEventImageFilename: (state, action) => {
      state.eventImage.filename = action.payload
    },
    removeEventImage: (state, action) => {
      state.eventImage = {
        file: null,
        filename: null,
      }
    },
    setIsFetched: (state, action) => {
      state.fetched = action.payload
    },
    setIsContinued: (state, action) => {
      state.isContinued = action.payload.value
      state.currentLocation = action.payload.conference.currentLocation
      state.attendeeMethod = action.payload.conference.attendeeMethod
      state.subGroups = action.payload.conference.subGroups
      state.jobTitles = action.payload.conference.jobTitles
      state.attendees = action.payload.conference.attendees
    },
    setEventSaving: (state, action) => {
      state.isSaving = action.payload
    },
    setBadgeName: (state, action) => {
      state.badgeName = action.payload
    },
    addEventTag: (state, action) => {
      state.tags.push(action.payload)
    },
    deleteEventTag: (state, action) => {
      state.tags.splice(action.payload, 1)
    },
    addNewRoom: (state, action) => {
      state.availableRooms.push(action.payload)
      state.eventRoom = action.payload
    },
    addNewBadge: (state, action) => {
      state.badgeAwarded = action.payload
    },
    setBadgeSaving: (state, action) => {
      state.isBadgeSaving = action.payload
    },
    changeAttendees: (state, action) => {
      if (action.payload.type === "add") {
        state[action.payload.field].push(action.payload.value)
      } else if (action.payload.type === "replace") {
        state[action.payload.field] = [action.payload.value]
      } else {
        state[action.payload.field] = state[action.payload.field].filter(
          (item) => {
            return (
              item.id !== action.payload.value.id &&
              item.id !== action.payload.value.uuid
            )
          }
        )
      }
    },
    addAttachment: (state, action) => {
      state.attachments.push(action.payload)
    },
    removeAttachment: (state, action) => {
      state.attachments = state.attachments.filter(
        (file) => file.file !== action.payload.file
      )
    },
    regenerateJoinCode: (state, action) => {
      state.isGeneratingCode = true
    },
    getNewJoinCode: (state, action) => {
      state.joinCode = action.payload.attributes.field_join_code
      state.isGeneratingCode = false
    },
    addSavedMessage: (state, action) => {
      state.newMessage = ""
      state.messages.data.unshift(action.payload)
    },
    removeMessage: (state, action) => {
      state.messages.data = state.messages.data.filter(
        (message) => message.id !== action.payload.id
      )
    },
    draftSaved: (state, action) => {
      state.field_draft = true
      state.addOnId = action.payload.addOn.data.id
      state.id = action.payload.event.id
      state.attendanceLogId = action.payload.logId
    },
    updatedCheckedInAttendees: (state, action) => {
      if (!action.meta.undoCheckIn) {
        state.checkedInAttendees.push(action.payload)
      } else {
        state.checkedInAttendees = state.checkedInAttendees.filter(
          (attendee) => attendee.id !== action.payload.id
        )
      }
    },
    filterByRSVP: (state, action) => {
      state.filterRSVP = action.payload
    },
    startConferenceEvent: (state, action) => {
      const { conference, clickEvent, currentDate, currentLocation } =
        action.payload
      state.isSession = true
      state.isNew = true
      state.currentLocation = currentLocation
      state.attendeeMethod = conference.attendeeMethod
      state.subGroups = conference.subGroups
      state.jobTitles = conference.jobTitles

      state.category = conference.category
      state.isOrgLevel = conference.isOrgLevel
      state.eventImage = { file: null, filename: null }

      state.orgUsers = conference.orgUsers
      state.attendees = conference.attendees
      state.sendPublishEmail = false
      state.pushNotifications = false
      state.currentTab = "details"

      if (currentDate) {
        state.startDate = moment(currentDate).format()
        state.endDate = moment(currentDate).add(1, "hour").format()
      }

      if (conference.type === "Virtual Live") {
        state.type = "Virtual Live"
      }

      if (clickEvent && clickEvent.groupingInfo[0]) {
        state.eventRoom = clickEvent.groupingInfo[0]
        if (
          clickEvent.groupingInfo[0].id === "none" ||
          conference.type === "Virtual Live"
        ) {
          state.type = "Virtual Live"
        }
      } else if (
        conference.availableRooms &&
        conference.availableRooms.length
      ) {
        state.eventRoom = conference.availableRooms[0]
        state.eventMax = conference.availableRooms[0].attributes.field_capacity
      }
    },
    addSavedIds: (state, action) => {
      state.id = action.payload.id
      state.addOnId = action.payload.addOnId
    },
    breakEventLock: (state) => {
      state.currentEditor = null
      state.editorLock = null
    },
    updateInviteeRSVP: (state, action) => {
      state.invitees.forEach((invitee) => {
        if (invitee.user_uuid === action.payload.invitee.user_uuid) {
          invitee.rsvp_status = action.payload.rsvp
        }
      })
    },
    startFromCalendar: (state, action) => {
      state.startDate = moment(action.payload.startDate)
        .add(10, "hours")
        .format()
      state.endDate = moment(action.payload.startDate).add(11, "hours").format()
    },
  },
  extraReducers: {
    [createParticipantUserEvent.fulfilled]: (state, action) => {
      const { manualRSVPUpdate, rsvp, invitee } = action.meta?.arg

      if (manualRSVPUpdate) {
        state.invitees.forEach((existing) => {
          if (existing.user_uuid === invitee.user_uuid) {
            existing.rsvp_status = rsvp
          }
        })
      }
    },

    [fetchObservationUserRubric.fulfilled]: (state, action) => {
      state.observationUserRubric.data = action.payload
      state.observationUserRubric.fetched = true
    },

    [fetchFeedbackSummary.fulfilled]: (state, action) => {
      state.feedbackSummary.data = action.payload
      state.feedbackSummary.fetched = true
    },
    [increaseInviteePage.fulfilled]: (state, action) => {
      state.inviteesLoading = true
      state.inviteesCurrentPage = state.inviteesCurrentPage + 1
    },
    [fetchEventMessages.fulfilled]: (state, action) => {
      state.messages.data = action.payload ? action.payload.reverse() : []
      state.messages.fetched = true
    },
    [updateInviteeSearch.fulfilled]: (state, action) => {
      state.inviteeSearch = action.payload
      state.inviteesCurrentPage = 0
      state.totalInviteePages = 0
    },
    [updateCheckedInFilter.fulfilled]: (state, action) => {
      state.filterCheckedIn = action.payload
      state.inviteesCurrentPage = 0
      state.totalInviteePages = 0
    },
    [updateInviteeRSVPFilter.fulfilled]: (state, action) => {
      state.filterRSVP = action.payload
      state.inviteesCurrentPage = 0
      state.totalInviteePages = 0
    },
    [updateGroupFilter.fulfilled]: (state, action) => {
      state.filterGroup = action.payload
      state.inviteesCurrentPage = 0
      state.totalInviteePages = 0
    },

    [getLearningPaths.pending]: (state, action) => {
      state.relatedLPSearch = action.meta.arg
      state.relatedLPResults = []
    },
    [getLearningPaths.fulfilled]: (state, action) => {
      state.relatedLPResults = action.payload.lp_data
    },
    [getCourses.pending]: (state, action) => {
      const { field, value } = action.meta.arg
      state[field] = value
    },
    [getCourses.fulfilled]: (state, action) => {
      const { field } = action.meta.arg
      if (field === "preRequisiteCoursesSearch") {
        state.preRequisiteCoursesResults = action.payload.courses
      } else {
        state.replacementCoursesResults = action.payload.courses
      }
    },

    [getEventInvitees.pending]: (state, action) => {
      state.inviteesLoading = true
      state.totalInviteePages = 0
    },
    [getEventInvitees.fulfilled]: (state, action) => {
      const { invitee_data, total_records } = action.payload
      state.inviteesLoading = false

      if (action.meta.arg?.initial) {
        state.totalInvitees = total_records
      }

      invitee_data.forEach((invitee) => {
        if (!invitee.rsvp_status) {
          invitee.field_rsvp = "None"
        }
      })

      if (state.inviteesCurrentPage) {
        state.invitees = state.invitees.concat(invitee_data)
      } else {
        state.invitees = invitee_data
      }

      state.totalInviteePages = Math.ceil(total_records / 50)
    },

    [updateEventSortBy.pending]: (state) => {
      state.inviteesCurrentPage = 0
    },
    [updateEventSortBy.fulfilled]: (state, action) => {
      state.sortBy = action.meta.arg.sortBy
    },
    [updateEventSortOrder.fulfilled]: (state, action) => {
      state.sortOrder = action.meta.arg.sortOrder
    },
    [getRooms.fulfilled]: (state, action) => {
      state.availableRooms = action.payload ? action.payload : []
      if (!action.meta.arg.refresh) {
        if (!state.availableRooms.find((room) => room.id === state.eventRoom)) {
          state.eventRoom = "none"
        }
      }
    },
    [fetchRoles.fulfilled]: (state, action) => {
      state.eventRoles = action.payload
    },
    [fetchObservationTypes.fulfilled]: (state, action) => {
      state.observationTypes = action.payload
      state.observationType = action.payload[0].id
    },
    [fetchActiveEvent.pending]: (state) => {
      state.fetched = false
    },
    [fetchActiveEvent.fulfilled]: (state, action) => {
      const addOn = action.payload.addOn
      const attributes = action.payload.data[0].attributes

      if (attributes.field_enabled_certificate_values?.length) {
        state.certificateSettings = {
          pdCredit:
            attributes.field_enabled_certificate_values.includes("pd_credit"),
          signature:
            attributes.field_enabled_certificate_values.includes("signature"),
          orgLogo:
            attributes.field_enabled_certificate_values.includes("org_logo"),
        }
      }

      state.userRubricUUID = action.payload.userRubricUUID ?? null
      state.userRubricId = action.payload.userRubricId ?? null

      state.observationRubricUUID = action.payload.observationRubric?.id ?? null
      state.observationType = action.payload.observationType?.id ?? null
      state.observationUser = action.payload.observationUser ?? null

      state.eventAuthor = action.payload.eventAuthor ?? {}

      if (action.payload.parentConferenceId) {
        state.parentConferenceId = action.payload.parentConferenceId
      }

      state.fullRubric = action.payload.fullRubric

      if (addOn.data.attributes.field_feedback_form_url) {
        state.feedbackMethod = "link"
      }

      state.editorLock = attributes.field_editor_lock_time
      state.currentEditor =
        action.payload.currentEditor && action.payload.currentEditor[0]

      const includedCourses =
        addOn.included &&
        addOn.included.filter(
          (included) => included.type === "course_entity--course_entity"
        )
      const preRequisiteCourseIds =
        addOn.data.relationships.field_prereq_courses.data.map(
          (course) => course.id
        )
      const replacementCourseIds =
        addOn.data.relationships.field_replacement_courses.data.map(
          (course) => course.id
        )

      const relatedLP =
        addOn.included &&
        addOn.included.find(
          (included) => included.type === "learning_path--learning_path"
        )

      if (relatedLP) {
        state.relatedLP = {
          name: relatedLP.attributes.name,
          field_lp_uuid: relatedLP.id,
          field_lp_id: relatedLP.attributes.drupal_internal__id,
        }
      }

      const badgeAwarded = action.payload.badgeAwarded
      const images =
        action.payload.included &&
        action.payload.included.filter(
          (included) => included.type === "file--image"
        )

      state.isOrgLevel = !action.payload.data[0].relationships.field_group.data
        ? true
        : false

      if (action.payload.data[0].relationships.field_group.data) {
        state.groupData = action.payload.data[0].relationships.field_group.data
      }

      const tags =
        action.payload.included &&
        action.payload.included.filter(
          (included) => included.type === "taxonomy_term--tags"
        )
      if (tags && tags.length) {
        state.tags = tags.map((tag) => {
          return {
            id: tag.id,
            uuid: tag.id,
            text: tag.attributes.name,
          }
        })
      }

      state.id = action.payload.data[0].id
      state.drupal_internal__id = attributes.drupal_internal__id
      state.name = attributes.name
      state.newBadge.name = attributes.name
      state.field_draft = attributes.field_draft
      state.helpfulLinks = attributes.field_helpful_links
        ? attributes.field_helpful_links.value
        : ""

      state.pushNotifications =
        attributes.field_notify === "push" ? true : false

      state.category = action.payload.included
        ? action.payload.included.find(
            (included) => included.type === "taxonomy_term--category"
          )
        : null
      state.color = attributes.field_hex_color
      state.supportContact = attributes.field_contact_info
        ? attributes.field_contact_info.value
        : ""

      state.availableRooms = action.payload.rooms

      state.personnel = action.payload.personnel
        ? action.payload.personnel.map((user) => {
            return {
              parentConference: user.attributes.field_parent_conference_name,
              personnelId: user.id,
              drupal_internal__id: user.attributes.drupal_internal__id,
              existing: true,
              field_bio: user.attributes.field_bio,
              field_first_name: user.attributes.field_first_name,
              field_last_name: user.attributes.field_last_name,
              field_subgroup: user.attributes.field_subgroup,
              field_event_role_name: user.attributes.field_event_role_name,
              field_user_name: user.attributes.field_user_name,
              field_user_image_path: user.attributes.field_user_image_path,
              id: user.relationships.field_user.data.id,
              role:
                user.relationships.field_event_role_term.data &&
                user.relationships.field_event_role_term.data.id,
              uuid: user.relationships.field_user.data.id,
            }
          })
        : []

      state.eventRoom = action.payload.data[0].relationships.field_room.data
        ? action.payload.data[0].relationships.field_room.data.id
        : null

      if (
        state.eventRoom === "none" &&
        action.payload.rooms &&
        action.payload.rooms.length
      ) {
        state.eventRoom = action.payload.rooms[0].id
      }

      let startDate = moment(attributes.field_event_date_time[0].value)
      let endDate = moment(attributes.field_event_date_time[0].end_value)

      state.startDate = moment(startDate).format()
      state.endDate = moment(endDate).format()

      state.allDay = attributes.field_all_day

      state.description =
        attributes.field_description && attributes.field_description.value
          ? attributes.field_description.value
          : ""
      state.type = attributes.field_type

      state.notes =
        attributes.field_event_notes && attributes.field_event_notes.value
          ? attributes.field_event_notes.value
          : ""

      state.currentLocation =
        action.payload.included &&
        action.payload.included.find(
          (included) => included.type === "mobile_mind_location--location_base"
        )
      state.meetingLink = attributes.field_meeting_link
        ? attributes.field_meeting_link.uri
        : ""
      state.eventMax = attributes.field_capacity

      if (attributes.field_event_reminder) {
        state.sendReminders = attributes.field_event_reminder.length
          ? attributes.field_event_reminder
          : ["none"]
      }
      if (attributes.field_reminder) {
        state.sendRegistrationReminders = attributes.field_reminder.length
          ? attributes.field_reminder
          : ["none"]
      }

      state.attendanceMethod = addOn.data.attributes.field_attendance_method
        ? addOn.data.attributes.field_attendance_method
        : "join_code"
      state.feedbackURL = addOn.data.attributes.field_feedback_form_url
        ? addOn.data.attributes.field_feedback_form_url
        : ""
      if (action.payload.feedbackForm) {
        state.feedbackFormId = action.payload.feedbackForm.id
        state.feedbackForm = action.payload.feedbackForm
        state.feedbackMethod = "custom"
      }
      state.feedbackRequired =
        addOn.data.attributes.field_feedback_required_for_cred
      state.feedbackIsAnonymous = addOn.data.attributes.field_anonymous_feedback

      if (
        attributes.field_registration_start &&
        attributes.field_registration_end
      ) {
        state.registrationWindow = "dateRange"
      }
      if (attributes.field_registration_start) {
        state.registrationStarts = moment(
          attributes.field_registration_start
        ).format()
      }

      if (attributes.field_registration_end) {
        state.registrationEnds = moment(
          attributes.field_registration_end
        ).format()
      }

      if (
        action.payload.attendanceLog &&
        action.payload.attendanceLog.data.attributes.field_check_in_period
      ) {
        state.checkInStart =
          action.payload.attendanceLog.data.attributes.field_check_in_period.value
        state.checkInEnd =
          action.payload.attendanceLog.data.attributes.field_check_in_period.end_value
      }

      state.joinCode =
        action.payload.attendanceLog &&
        action.payload.attendanceLog.data.attributes.field_join_code
      state.attendanceLogId =
        action.payload.attendanceLog && action.payload.attendanceLog.data.id
      state.checkedInAttendees = action.payload.attendanceLog
        ? action.payload.attendanceLog.data.relationships.field_attendees.data
        : []

      state.difficulty = addOn.data.attributes.field_level
        ? addOn.data.attributes.field_level
        : "N/A"

      let minutes = addOn.data.attributes.field_credit
        ? addOn.data.attributes.field_credit
        : 0

      const hours = minutes / 60
      const rhours = Math.floor(hours)
      minutes = (hours - rhours) * 60

      state.pdCredit.hours = rhours
      state.pdCredit.minutes = Math.round(minutes)

      state.preRequisiteCourses = includedCourses
        ? includedCourses.filter((course) =>
            preRequisiteCourseIds.includes(course.id)
          )
        : []
      state.replacementCourses = includedCourses
        ? includedCourses.filter((course) =>
            replacementCourseIds.includes(course.id)
          )
        : []
      state.badgeAwarded = badgeAwarded
      state.badgeName = badgeAwarded && badgeAwarded.name

      state.addOnId = addOn.data.id
      state.field_archive = attributes.field_archive

      state.attachments = []

      // Handle attachments
      if (action.payload.included) {
        action.payload.included.forEach((included) => {
          let isAttachment =
            action.payload.data[0].relationships.field_attachment.data &&
            action.payload.data[0].relationships.field_attachment.data.find(
              (attachment) => attachment.id === included.id
            )
          if (isAttachment) {
            state.attachments.push({
              id: included.id,
              filename: included.attributes.filename,
              extension: included.attributes.filename.split(".").pop(),
              file: included.attributes.uri.url,
            })
          }
        })
      }

      state.attendees = []
      state.subGroups = []
      state.jobTitles = []

      const { field_participants_job_title } = addOn.data.relationships
      const field_participants_users =
        addOn.included?.filter((included) => included.type === "user--user") ??
        []

      // Handle attendees
      if (field_participants_users.length) {
        field_participants_users.forEach((user) => {
          let existing = state.attendees.find((item) => item.id === user.id)
          !existing && state.attendees.push(user)
        })
      }

      if (addOn.data.subGroups.length > 0) {
        state.subGroups = addOn.included
          ? addOn.included.filter(
              (included) => included.type === "group--group"
            )
          : []

        if (!state.subGroups.length) {
          state.subGroups = addOn.data.subGroups
        }
      }
      state.participantUsers = []
      if (addOn.data.participantUsers?.length) {
        state.participantUsers = addOn.data.participantUsers
      }

      if (field_participants_job_title.data.length) {
        field_participants_job_title.data.forEach((jobTitle) => {
          let existing = state.jobTitles.find((item) => item.id === jobTitle.id)
          !existing && state.jobTitles.push(jobTitle)
        })
      }

      state.originalParticipants = field_participants_users.concat(
        state.subGroups,
        field_participants_job_title.data
      )

      state.attendeeMethod =
        addOn.data.attributes.field_participants_type ?? "unselected"
      state.originalAttendeeMethod = state.attendeeMethod
      state.eventImage = {
        file:
          images &&
          images.find(
            (image) =>
              action.payload.data[0].relationships.field_event_image.data &&
              image.id ===
                action.payload.data[0].relationships.field_event_image.data.id
          ),
      }

      state.fetched = true
    },
  },
})

export const {
  startFromCalendar,
  changeEventTab,
  selectCourse,
  setRelatedLP,
  deleteCourse,
  updateField,
  addEventTag,
  deleteEventTag,
  updateLocationField,
  removePersonnel,
  addPersonnel,
  setEventImage,
  removeEventImage,
  setNewEventAsActive,
  setEventImageFilename,
  clearBadgeImage,
  breakEventLock,
  setBadgeName,
  setPersonnelRole,
  setIsContinued,
  setBadgeSaving,
  filterByRSVP,
  startNewEvent,
  changeAttendees,
  startConferenceEvent,
  regenerateJoinCode,
  addAttachment,
  setEventSaving,
  setIsFetched,
  removeAttachment,
} = activeEventSlice.actions

export default activeEventSlice.reducer
