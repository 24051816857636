import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { SubmissionType } from "@mobilemind/common/src/types/course"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import { Box, Checkbox, Divider, Stack, Typography } from "@mui/material"
import { isGroupLevel } from "functions"
import moment from "moment"
import { useAppSelector } from "store/hooks"
import BookLeaf from "../../../img/book-leaf.svg"
import CardboardBox from "../../../img/cardboard-box.svg"
import Homework from "../../../img/homework.svg"
import IdeaBox from "../../../img/idea-box.svg"
import MobileIcon from "../../../img/mobile-friendly.svg"
import PencilBox from "../../../img/pencil-box.svg"
import ReviewerPerson from "../../../img/reviewer-person.svg"

import { CourseFormHeader } from "./CourseFormHeader"
import { CourseVideoContainer } from "./CourseVideoContainer"
import { ReviewersListGroups } from "./ReviewersListGroups"
import { ReviewersListIndividuals } from "./ReviewersListIndividuals"
import { TemplateChallengeQuestion } from "./TemplateChallengeQuestion"
import { TemplateChallengeQuiz } from "./TemplateChallengeQuiz"
import { TemplateMultipleChoiceOptions } from "./TemplateMultipleChoiceOptions"
import { TemplatePollOptions } from "./TemplatePollOptions"
import { CourseFormValues } from "../types"
import nl2br from "nl2br"
import { CommunityContribution } from "./CommunityContribution"
import { FieldArray } from "formik"
import { JobTitle } from "@mobilemind/common/src/types/taxonomy/jobTitle"
import classNames from "classnames"

type CourseTemplateViewProps = {
  course: CourseFormValues
}

export const CourseTemplateView = ({ course }: CourseTemplateViewProps) => {
  const session = useAppSelector((state) => state.session)

  // @todo: get rid of duplicate subgroups or subGroups property on session slice.
  let subGroups: any
  // @ts-ignore
  if (session.subgroups && session.subgroups.data && isGroupLevel(session)) {
    subGroups = session.subgroups
  } else {
    subGroups = session.subGroups
  }

  const fullSubGroupOwner = subGroups.data.find(
    (group: SubGroup) => group.id === course.subGroupOwner
  )

  const manualReviewPossible =
    course.submissionType === SubmissionType.Text ||
    course.submissionType === SubmissionType.Image ||
    course.submissionType === SubmissionType.Url ||
    course.submissionType === SubmissionType.File

  return (
    <>
      <CourseFormHeader course={course} />
      <Box sx={{ padding: 6, paddingTop: 3 }}>
        <Stack direction="column" spacing={6}>
          <Box>
            <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
              <img src={IdeaBox} width={30} height={30} alt="Learn" />
              <Typography variant="h3">Learn</Typography>
            </Stack>

            <Box sx={{ marginTop: 3 }}>
              <SanitizedHTML html={nl2br(course.objective)} />
            </Box>
          </Box>
          <Box>
            <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
              <img src={PencilBox} width={30} height={30} alt="Apply" />
              <Typography variant="h3">Apply</Typography>
            </Stack>

            <Box sx={{ marginTop: 3 }}>
              <SanitizedHTML html={nl2br(course.content)} />
            </Box>
          </Box>
          <CourseVideoContainer videoLink={course.field_video_link} />

          {course.resources && (
            <Box>
              <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
                <img
                  src={CardboardBox}
                  width={30}
                  height={30}
                  alt="Materials"
                />
                <Typography variant="h3">Materials</Typography>
              </Stack>

              <Box
                sx={{
                  img: {
                    maxWidth: "100%",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: course.resources }}
              />
            </Box>
          )}
          {course.mobileFriendly && (
            <Box>
              <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
                <img
                  src={MobileIcon}
                  width={30}
                  height={30}
                  alt="Mobile Friendly"
                />
                <Typography variant="h3">Mobile Friendly</Typography>
              </Stack>
            </Box>
          )}
          <Divider>Challenge</Divider>
          <Box>
            <Stack
              direction="row"
              sx={{ alignItems: "center", marginBottom: 2 }}
              spacing={2}
            >
              <img
                src={BookLeaf}
                width={30}
                height={30}
                alt="Learner Instructions"
              />
              <Typography variant="h3">Learner Instructions</Typography>
            </Stack>
            <Typography>
              <SanitizedHTML html={nl2br(course.learnerInstructions)} />
            </Typography>
          </Box>
          {course.submissionType === SubmissionType.Quiz && (
            <TemplateChallengeQuiz course={course} />
          )}
          {course.submissionType === SubmissionType.Checkbox && (
            <TemplatePollOptions course={course} />
          )}
          {manualReviewPossible && (
            <TemplateChallengeQuestion course={course} />
          )}
          {course.submissionType === SubmissionType.MultipleChoice && (
            <TemplateMultipleChoiceOptions course={course} />
          )}
          {manualReviewPossible && !course.isTemplate && (
            <>
              {course.reviewerInstructions && (
                <Box>
                  <Stack
                    direction="row"
                    sx={{ alignItems: "center", marginBottom: 2 }}
                    spacing={2}
                  >
                    <img
                      src={Homework}
                      width={30}
                      height={30}
                      alt="Reviewer Instructions"
                    />
                    <Typography variant="h3">Reviewer Instructions</Typography>
                  </Stack>
                  <Typography>{course.reviewerInstructions}</Typography>
                </Box>
              )}
              {course.reviewers.length > 0 && (
                <Box>
                  <Stack
                    direction="row"
                    sx={{ alignItems: "center", marginBottom: 5 }}
                    spacing={2}
                  >
                    <img
                      src={ReviewerPerson}
                      width={30}
                      height={30}
                      alt="Reviewers"
                    />
                    <Typography variant="h3">Reviewers</Typography>
                  </Stack>
                  <Stack sx={{ flexWrap: "wrap" }} direction="row" spacing={2}>
                    <ReviewersListIndividuals viewOnly={true} />
                    <ReviewersListGroups viewOnly={true} />
                  </Stack>
                </Box>
              )}
            </>
          )}
          {!course.isTemplate && (
            <>
              <Divider>Settings</Divider>

              <Stack direction={"row"} spacing={2}>
                <Stack
                  spacing={2}
                  sx={{ flex: 1, marginTop: "-11px !important" }}
                >
                  {!course.isTemplate && course.excludeFromExplore && (
                    <Box>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Stack direction="row" sx={{ flex: 1 }}>
                          <Typography variant="h3">
                            Exclude From Course Library
                          </Typography>
                        </Stack>
                        <Checkbox
                          checked={course.excludeFromExplore}
                          disabled={true}
                        />
                      </Stack>
                    </Box>
                  )}

                  {!course.isTemplate && (
                    <Box>
                      <Typography variant="h3" gutterBottom={true}>
                        Release Date
                      </Typography>
                      <Typography sx={{ fontSize: 14 }}>
                        {moment(course.releaseDate).format("MMMM Do, YYYY")}
                      </Typography>
                    </Box>
                  )}

                  {!course.isTemplate &&
                    course.subGroupOwner &&
                    fullSubGroupOwner && (
                      <Box>
                        <Typography variant="h3" gutterBottom={true}>
                          Course Owner
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>
                          {fullSubGroupOwner.attributes.label}
                        </Typography>
                      </Box>
                    )}
                  {!course.isTemplate && course.supportEmail && (
                    <Box>
                      <Typography variant="h3" gutterBottom={true}>
                        Support Email
                      </Typography>
                      <Typography sx={{ fontSize: 14 }}>
                        {course.supportEmail}
                      </Typography>
                    </Box>
                  )}
                </Stack>

                <Stack spacing={2} sx={{ flex: 1 }}>
                  {!course.isTemplate && (
                    <Box>
                      <Typography variant="h3">Course Visibility</Typography>
                      <ul
                        style={{ marginTop: 10 }}
                        className={"visibility-list"}
                      >
                        {!course.subGroups.length &&
                        !course.jobTitles.length ? (
                          <li>Everyone</li>
                        ) : (
                          <>
                            <FieldArray
                              name="subGroups"
                              render={() => (
                                <>
                                  {course.subGroups.map((group: SubGroup) => {
                                    return (
                                      <li key={group.id}>
                                        <span className="icon group" />
                                        <span className="label">
                                          {group.attributes?.label}
                                        </span>
                                      </li>
                                    )
                                  })}
                                </>
                              )}
                            />
                            <FieldArray
                              name="jobTitles"
                              render={(arrayHelpers) => (
                                <>
                                  {course.jobTitles.map(
                                    (jobTitle: JobTitle) => {
                                      return (
                                        <li key={jobTitle.id}>
                                          <span
                                            className={classNames(
                                              "icon role",
                                              jobTitle.attributes.name.toLowerCase()
                                            )}
                                          />
                                          <span className="label">
                                            {jobTitle.attributes.name}
                                          </span>
                                        </li>
                                      )
                                    }
                                  )}
                                </>
                              )}
                            />
                          </>
                        )}
                      </ul>
                    </Box>
                  )}

                  {course.relatedCourses.length > 0 && (
                    <Box>
                      <Typography variant="h3">Related Courses</Typography>
                      {course.relatedCourses.map((course) => {
                        return (
                          <Typography
                            sx={{ fontSize: 14, paddingBlock: 1 }}
                            key={course.uuid}
                          >
                            {course.name}
                          </Typography>
                        )
                      })}
                    </Box>
                  )}
                </Stack>
              </Stack>
            </>
          )}
          <CommunityContribution course={course} />
        </Stack>
      </Box>
    </>
  )
}
