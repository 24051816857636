import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Select,
  TextField,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"

import { useAppDispatch, useAppSelector } from "../../store/hooks"

import Loading from "@mobilemind/common/src/components/Loading"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import * as htmlToImage from "html-to-image"

import Values from "values.js"
import {
  saveCategory,
  setCategoryModalOpen,
  setIsVisible,
  updateCategories,
} from "../../store/reducers/categories"
import { allImages } from "../createBadge/BadgeImages"

import { HexColorPicker, HexColorInput } from "react-colorful"

import "../../styles/categories.scss"
import "../../styles/modal.scss"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import theme from "@mobilemind/common/src/theme/theme"
import classNames from "classnames"
import ButtonSmall from "../../components/ButtonSmall"

import CategorySelect from "@mobilemind/common/src/components/CategorySelect"
import { CategoryTaxonomy } from "@mobilemind/common/src/types/taxonomy/category"
import Rating from "../../components/Rating"

function ManageCategoriesModal() {
  const categories = useAppSelector((state) => state.categories)
  const productTour = useAppSelector((state) => state.productTour)
  const session = useAppSelector((state) => state.session)

  const { isPartner } = session

  const categoryImageRef = useRef(null)
  const dispatch = useAppDispatch()

  const [isCreating, setIsCreating] = useState(false)
  const [isPreviewOpen, setPreviewOpen] = useState(false)
  const [isLargeImageOpen, showLargeImage] = useState(false)

  const [categoryColor, setCategoryColor] = useState("#59C3E5")

  const [imageSearch, setImageSearch] = useState("")

  const [newCategory, updateNewCategory] = useState({
    name: "",
    parentCategoryId: "none",
    customImageFilename: "",
    customImage: "",
    id: null,
  })

  const [currentPack, setPack] = useState("education")
  const [activeIcon, selectIcon] = useState("")

  const [currentPackImages, setCurrentPackImages] = useState(
    Object.values(
      importAll(
        require.context("../../img/studio/education", false, /\.(svg)$/)
      )
    )
  )

  useEffect(() => {
    if (imageSearch.length) {
      setCurrentPackImages(
        allImages.filter((image) =>
          image.toLowerCase().includes(imageSearch.toLowerCase())
        )
      )
    } else if (currentPack) {
      let newPack: any

      if (currentPack === "all") {
        newPack = allImages
      }
      if (currentPack === "education") {
        newPack = Object.values(
          importAll(
            require.context("../../img/studio/education", false, /\.(svg)$/)
          )
        )
      }
      if (currentPack === "animals") {
        newPack = Object.values(
          importAll(
            require.context("../../img/studio/animals", false, /\.(svg)$/)
          )
        )
      }
      if (currentPack === "food") {
        newPack = Object.values(
          importAll(require.context("../../img/studio/food", false, /\.(svg)$/))
        )
      }
      if (currentPack === "office") {
        newPack = Object.values(
          importAll(
            require.context("../../img/studio/office", false, /\.(svg)$/)
          )
        )
      }
      if (currentPack === "mentoring-training") {
        newPack = Object.values(
          importAll(
            require.context(
              "../../img/studio/mentoring-training",
              false,
              /\.(svg)$/
            )
          )
        )
      }
      if (currentPack === "nature") {
        newPack = Object.values(
          importAll(
            require.context("../../img/studio/nature", false, /\.(svg)$/)
          )
        )
      }
      if (currentPack === "teamwork") {
        newPack = Object.values(
          importAll(
            require.context("../../img/studio/teamwork", false, /\.(svg)$/)
          )
        )
      }
      if (currentPack === "holidays") {
        newPack = Object.values(
          importAll(
            require.context("../../img/studio/holidays", false, /\.(svg)$/)
          )
        )
      }
      if (currentPack === "rewards-badges") {
        newPack = Object.values(
          importAll(
            require.context(
              "../../img/studio/rewards-badges",
              false,
              /\.(svg)$/
            )
          )
        )
      }

      setCurrentPackImages(newPack)
    } else {
      setPack("all")
    }
  }, [imageSearch, currentPack])

  function importAll(r: any) {
    let images: any = {}
    r.keys().forEach((item: string) => {
      images[item.replace("./", "")] = r(item)
    })
    return images
  }

  function cancelCreate() {
    setIsCreating(false)

    showLargeImage(false)
    setImageSearch("")
    selectIcon("")
    setPack("education")

    setCurrentPackImages(
      Object.values(
        importAll(
          require.context("../../img/studio/education", false, /\.(svg)$/)
        )
      )
    )

    updateNewCategory({
      name: "",
      parentCategoryId: "none",
      customImageFilename: "",
      customImage: "",
      id: null,
    })
  }

  function editCategory(category: any) {
    updateNewCategory({
      name: category.attributes.name,
      id: category.id,

      parentCategoryId:
        category.relationships.parent.data[0].id !== "virtual"
          ? category.relationships.parent.data[0].id
          : "none",

      customImageFilename: category.attributes.field_icon_path
        ? ""
        : process.env.REACT_APP_API_URL + category.image.attributes.filename,

      customImage: category.attributes.field_icon_path
        ? null
        : process.env.REACT_APP_API_URL + category.image.attributes.uri.url,
    })

    if (category.attributes.field_hex_color) {
      setCategoryColor(category.attributes.field_hex_color)
    }

    selectIcon(category.attributes.field_icon_path)
    setIsCreating(true)
  }

  function startNewCategory() {
    updateNewCategory({
      name: "",
      parentCategoryId: "none",
      customImageFilename: "",
      customImage: "",
      id: null,
    })

    setCategoryColor("#59C3E5")
    selectIcon("")
    setIsCreating(true)
  }

  const initSave = async () => {
    // If we have a custom image we've uploaded
    if (newCategory.customImage) {
      // Determine whether to pass raw image data or leave it alone
      // since we haven't touched our custom uploaded image
      let rawImage = newCategory.customImage.includes("data:image")
        ? newCategory.customImage
        : null
      finishSave(rawImage, "")
    }
    // Otherwise we're just working with Studio...
    else {
      // And we should convert the DOM node to a PNG
      categoryImageRef.current &&
        (await htmlToImage
          .toPng(categoryImageRef.current)
          .then((rawImage: string) => {
            finishSave(rawImage, activeIcon)
          }))
    }
  }

  async function saveChanges() {
    await dispatch(updateCategories({}))
    dispatch(setCategoryModalOpen(false))
  }

  const finishSave = (rawImage: any, iconPath: string) => {
    dispatch(
      saveCategory({
        newCategory,
        activeIcon: iconPath.replaceAll(" ", "%20"),
        image: rawImage ? rawImage : null,
        categoryColor,
      })
    )

    setTimeout(() => {
      setIsCreating(false)
      showLargeImage(false)
      selectIcon("")
      setPack("education")
    }, 500)
  }

  const activeIconPath: string = activeIcon
    ? window.location.origin + activeIcon.replace(" ", "%20")
    : ""
  const editingParent = categories.data.find(
    (cat: any) => cat.id === newCategory.parentCategoryId
  )

  function uploadFile(file: any) {
    let uploaded = file[0] ? file[0] : file.target.files[0]

    // Read the file
    let reader = new FileReader()
    reader.readAsDataURL(uploaded)

    // Once we're done, get the file and name it
    reader.onloadend = async (event: any) => {
      updateNewCategory({
        name: newCategory.name,
        parentCategoryId: newCategory.parentCategoryId,
        customImageFilename: uploaded.name,
        customImage: event.target.result,
        id: newCategory.id,
      })
    }
  }

  let disableCategorySelect = false
  let allowedLevels = 2

  const editingCategory = categories.data.find(
    (cat: any) => cat.id === newCategory.id
  )

  let availableCategories = { ...categories }

  if (editingCategory) {
    // If it's a top level category and it has 2 generations of children, prevent moving it down a rung
    if (editingCategory.isTopLevel) {
      const allSecondLevel: any[] = categories.data.filter(
        (cat: any) => cat.relationships.parent.data[0].id === editingCategory.id
      )
      const hasThirdLevel: boolean = allSecondLevel.find(
        (sub: any) => sub.children && sub.children.length
      )
        ? true
        : false
      if (hasThirdLevel) {
        disableCategorySelect = true
      }
    }

    // Prevent moving a secondary category down a rung if it has children
    if (
      editingCategory.isSecondLevel &&
      editingCategory.children &&
      editingCategory.children.length > 0
    ) {
      allowedLevels = 1
    }
  }

  // Prevent making a category it's own parent
  const categoryRef = editingCategory ?? newCategory
  availableCategories.data = availableCategories.data.filter(
    (cat: CategoryTaxonomy) => cat.id !== categoryRef?.id
  )
  availableCategories.subCategories = availableCategories.subCategories.filter(
    (cat: CategoryTaxonomy) => cat.id !== categoryRef?.id
  )
  availableCategories.topCategories = availableCategories.topCategories.filter(
    (cat: CategoryTaxonomy) => cat.id !== categoryRef?.id
  )

  return (
    <>
      <Dialog
        data-no-focus-lock
        onClose={() => {
          dispatch(setCategoryModalOpen(false))
        }}
        className={classNames(
          "modal manageCategories",
          isCreating && "isCreating"
        )}
        open={categories.isOpen}
      >
        <div
          style={{
            opacity: categories.isSaving ? 0.6 : 1,
            pointerEvents: categories.isSaving ? "none" : "all",
          }}
        >
          <div
            id="tour-categories"
            style={{
              pointerEvents: productTour.isStarted ? "none" : "all",
              backgroundColor: theme.palette.secondary.main,
              padding: 20,
            }}
          >
            <h2 style={{ display: "flex" }}>
              <span style={{ flex: 1 }}>PD Categories</span>

              {!isCreating && (
                <div
                  style={{
                    pointerEvents: categories.isSaving ? "none" : "all",
                    opacity: categories.isSaving ? 0.5 : 1,
                  }}
                  className="add-button"
                >
                  <ButtonLarge onClick={() => startNewCategory()}>
                    <span className="icon plus" />
                    New Category
                  </ButtonLarge>
                </div>
              )}

              {isCreating && !isPreviewOpen && (
                <div
                  style={{
                    pointerEvents: categories.isSaving ? "none" : "all",
                    opacity: categories.isSaving ? 0.5 : 1,
                  }}
                  className="add-button"
                >
                  <ButtonLarge onClick={() => setPreviewOpen(true)}>
                    <span className="icon eye" />
                    Preview Course Card
                  </ButtonLarge>
                </div>
              )}
            </h2>
            <p style={{ fontSize: 12, marginBottom: 0 }}>
              <strong>Categories</strong> are used to classify PD credit for{" "}
              <strong>Courses, Events, and External PD</strong>.<br />
              If a category is not marked as{" "}
              <strong>Visible to Learners</strong>, content assigned to that
              category will not appear in the Learn app.
            </p>

            <div className="category-tooltip-container">
              <p
                style={{ fontSize: 12, display: "flex", alignItems: "center" }}
              >
                <span
                  style={{ width: 15, height: 15, marginRight: 5 }}
                  className="icon info"
                />
                Need category ideas?{" "}
              </p>
              <div className="inner">
                <p style={{ margin: 0 }}>
                  Here are some category ideas to better organize your content:
                </p>
                <div className="flexRow" style={{ alignItems: "flex-start" }}>
                  <ul style={{ marginRight: 35 }}>
                    <li>Student Engagement</li>
                    <li>Classroom Management</li>
                    <li>SEL</li>
                    <li>Leadership</li>
                    <li>Educator Wellness</li>
                    <li>Standards</li>
                    <li>Instructional Resources</li>
                    <li>Reading</li>
                  </ul>

                  <ul>
                    <li>STEM</li>
                    <li>Teaching Strategies</li>
                    <li>Tech & Acceptable Use</li>
                    <li>Student Privacy</li>
                    <li>Safety & Security</li>
                    <li>Athletics</li>
                    <li>Facilities</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {categories.isSaving && <Loading />}

          <div className={classNames("flexRow pager", isCreating && "active")}>
            <div
              className={classNames(
                "inner page-1",
                categories.isSaving && "saving"
              )}
            >
              <header className="flexRow">
                <div>Image</div>
                <div className="name">Name</div>
                <div className="grow">Visible to Learners</div>
              </header>

              {!categories.fetched ? (
                <Loading message={"Getting categories..."} />
              ) : (
                <ol className="categoryList">
                  {categories.topCategories &&
                    categories.topCategories.map(
                      (category: CategoryTaxonomy, index: number) => {
                        const children = categories.subCategories.filter(
                          (sub: CategoryTaxonomy) =>
                            sub.relationships.parent.data[0].id === category.id
                        )

                        return (
                          <li key={index} className="topCategory">
                            <Accordion>
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div className="flexRow">
                                  {category.image && (
                                    <img
                                      className="categoryImage"
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        category.image.attributes.uri.url
                                      }
                                      alt={category.attributes.name}
                                    />
                                  )}

                                  <div className="title">
                                    {category.attributes.name}
                                  </div>
                                  {(category.relationships.field_organization
                                    .data ||
                                    (category.relationships.field_partner
                                      .data &&
                                      isPartner)) && (
                                    <ButtonSmall
                                      onClick={() => {
                                        editCategory(category)
                                      }}
                                    >
                                      <span className="icon edit" />
                                      Edit
                                    </ButtonSmall>
                                  )}
                                  <Checkbox
                                    checked={category.isVisible}
                                    onChange={(event) => {
                                      dispatch(
                                        setIsVisible({
                                          category,
                                          value: event.target.checked,
                                        })
                                      )
                                    }}
                                    name="visible"
                                  />
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                {children && children.length > 0 && (
                                  <ol className="subCategoryList">
                                    {children.map((child: CategoryTaxonomy) => {
                                      const hasChildren =
                                        child.children &&
                                        child.children.length > 0
                                      const isEditable =
                                        child.relationships.field_organization
                                          .data ||
                                        (child.relationships.field_partner
                                          .data &&
                                          isPartner)

                                      return (
                                        <li key={child.id}>
                                          <div className="flexRow subCat">
                                            {child.image && (
                                              <img
                                                className="categoryImage"
                                                src={
                                                  process.env
                                                    .REACT_APP_API_URL +
                                                  child.image.attributes.uri.url
                                                }
                                                alt={child.attributes.name}
                                              />
                                            )}
                                            <div className="title">
                                              {child.attributes.name}
                                            </div>

                                            {isEditable && (
                                              <ButtonSmall
                                                onClick={() => {
                                                  editCategory(child)
                                                }}
                                              >
                                                <span className="icon edit" />
                                                Edit
                                              </ButtonSmall>
                                            )}

                                            <Checkbox
                                              checked={child.isVisible}
                                              onChange={(event) => {
                                                dispatch(
                                                  setIsVisible({
                                                    category: child,
                                                    value: event.target.checked,
                                                  })
                                                )
                                              }}
                                              name="visible"
                                            />
                                          </div>
                                          {hasChildren && (
                                            <ol className="thirdLevelList">
                                              {child.children &&
                                                child.children.map(
                                                  (thirdLevel: any) => {
                                                    return (
                                                      <li key={thirdLevel.id}>
                                                        {thirdLevel.image && (
                                                          <img
                                                            className="categoryImage"
                                                            src={
                                                              process.env
                                                                .REACT_APP_API_URL +
                                                              thirdLevel.image
                                                                .attributes.uri
                                                                .url
                                                            }
                                                            alt={
                                                              thirdLevel
                                                                .attributes.name
                                                            }
                                                          />
                                                        )}
                                                        <div className="title">
                                                          {
                                                            thirdLevel
                                                              .attributes.name
                                                          }
                                                        </div>

                                                        {isEditable && (
                                                          <ButtonSmall
                                                            onClick={() => {
                                                              editCategory(
                                                                thirdLevel
                                                              )
                                                            }}
                                                          >
                                                            <span className="icon edit" />
                                                            Edit
                                                          </ButtonSmall>
                                                        )}

                                                        <Checkbox
                                                          checked={
                                                            thirdLevel.isVisible
                                                          }
                                                          onChange={(event) => {
                                                            dispatch(
                                                              setIsVisible({
                                                                category:
                                                                  thirdLevel,
                                                                value:
                                                                  event.target
                                                                    .checked,
                                                              })
                                                            )
                                                          }}
                                                          name="visible"
                                                        />
                                                      </li>
                                                    )
                                                  }
                                                )}
                                            </ol>
                                          )}
                                        </li>
                                      )
                                    })}
                                  </ol>
                                )}
                                {!children.length && (
                                  <ol className="subCategoryList">
                                    <li
                                      style={{
                                        paddingLeft: 35,
                                        paddingRight: 35,
                                      }}
                                    >
                                      <p>
                                        To add a new subcategory, click{" "}
                                        <strong>New Category</strong> and select{" "}
                                        <strong>
                                          {category.attributes.name}
                                        </strong>{" "}
                                        as the Parent Category.
                                      </p>
                                    </li>
                                  </ol>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </li>
                        )
                      }
                    )}
                </ol>
              )}

              <footer className="buttonContainer">
                <ButtonLarge
                  onClick={() => {
                    saveChanges()
                  }}
                >
                  Save Changes
                </ButtonLarge>
              </footer>
            </div>

            <div className="inner page-2">
              <div style={{ display: "flex", padding: "10px 20px" }}>
                <div
                  style={{
                    width: 400,
                    transition: ".3s",
                    opacity: isLargeImageOpen ? 0.3 : 1,
                    pointerEvents: isLargeImageOpen ? "none" : "all",
                  }}
                >
                  <div style={{ width: 300, marginTop: 10 }}>
                    {newCategory.customImage ? (
                      <div
                        style={{
                          width: 100,
                          borderRadius: 100,
                          margin: "0 auto",
                          boxShadow: "0 2px 4px 0 rgb(0 0 0 / 18%)",
                          border: "5px solid white",
                          height: 100,
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt={newCategory.name}
                          src={newCategory.customImage}
                          style={{ width: "auto", height: "100%" }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: categoryColor,
                          backgroundSize: "80%",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundImage: "url(" + activeIconPath + ")",
                        }}
                        className="newCategoryImage"
                      />
                    )}
                  </div>

                  <div className="image-upload">
                    <ButtonSmall>
                      <span className="icon camera" />
                      Upload Custom Image
                      <input
                        onChange={(event) => uploadFile(event)}
                        type="file"
                      />
                    </ButtonSmall>
                  </div>

                  <TextField
                    variant="standard"
                    label="Category Name"
                    value={newCategory.name}
                    onChange={(event: any) => {
                      let updatedCategory = { ...newCategory }
                      updatedCategory.name = event.target.value
                      updateNewCategory(updatedCategory)
                    }}
                  />

                  {isCreating && (
                    <div
                      className={classNames(
                        disableCategorySelect && "category-disabled"
                      )}
                      style={{ marginTop: 20 }}
                    >
                      <CategorySelect
                        onChange={(category) => {
                          let updatedCategory = { ...newCategory }
                          if (category) {
                            updatedCategory.parentCategoryId = category.id
                            updateNewCategory(updatedCategory)
                          }
                        }}
                        categories={availableCategories}
                        currentValue={editingParent ?? null}
                        label={"Parent Category"}
                        allowedLevels={allowedLevels}
                      />
                    </div>
                  )}

                  {disableCategorySelect && (
                    <strong
                      style={{
                        paddingTop: 10,
                        maxWidth: 370,
                        display: "block",
                        fontSize: 14,
                        fontStyle: "italic",
                      }}
                    >
                      <span
                        style={{
                          width: 15,
                          height: 15,
                          position: "relative",
                          top: 2,
                          marginRight: 5,
                        }}
                        className="icon question"
                      />
                      Because this category has 2 levels of child categories,
                      you aren't able to move all 3 levels under another parent.
                    </strong>
                  )}
                  <div style={{ marginTop: 35 }}>
                    <h3>Category Color</h3>
                    <HexColorPicker
                      color={categoryColor}
                      onChange={setCategoryColor}
                    />
                    <div
                      style={{
                        backgroundColor: "black",
                        marginTop: -26,
                        maxWidth: 284,
                        borderRadius: "0 0 12px 12px",
                        paddingTop: 15,
                      }}
                      className="hexPicker"
                    >
                      <span className="hash">#</span>
                      <HexColorInput
                        color={categoryColor}
                        onChange={setCategoryColor}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    transition: ".3s",
                    opacity: isLargeImageOpen ? 0.3 : 1,
                    pointerEvents: isLargeImageOpen ? "none" : "all",
                  }}
                >
                  <div style={{ paddingBottom: 20 }}>
                    <h3>Image</h3>

                    <Stack direction="row" spacing={5}>
                      <FormControl
                        variant="standard"
                        className="inputSelect collection"
                        style={{ marginRight: 2, width: 180 }}
                      >
                        <InputLabel id="label-collection-select">
                          Image Collection
                        </InputLabel>
                        <Select
                          variant="standard"
                          labelId="label-collection-select"
                          id="collection-select"
                          onChange={(event: any) => {
                            setImageSearch("")
                            setPack(event.target.value)
                          }}
                          value={currentPack}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"education"}>Education</MenuItem>
                          <MenuItem value={"mentoring-training"}>
                            Mentoring & Training
                          </MenuItem>
                          <MenuItem value={"teamwork"}>Teamwork</MenuItem>
                          <MenuItem value={"rewards-badges"}>
                            Rewards & Badges
                          </MenuItem>
                          <MenuItem value={"office"}>
                            Office & Organization
                          </MenuItem>
                          <MenuItem value={"animals"}>Animals</MenuItem>
                          <MenuItem value={"food"}>Food</MenuItem>
                          <MenuItem value={"holidays"}>Holidays</MenuItem>
                          <MenuItem value={"nature"}>Nature</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        variant="standard"
                        className="inputSearch"
                        label="Image Search"
                        value={imageSearch}
                        inputProps={{ maxLength: 50 }}
                        onChange={(event: any) => {
                          setPack("all")
                          setImageSearch(event.target.value)
                        }}
                      />
                    </Stack>
                  </div>

                  <div className="image-grid">
                    <ul>
                      {currentPackImages.map((image: any, index: number) => {
                        return (
                          <li
                            key={index}
                            className={classNames(
                              activeIcon === image && "active"
                            )}
                            onClick={() => {
                              updateNewCategory({
                                name: newCategory.name,
                                parentCategoryId: newCategory.parentCategoryId,
                                customImageFilename: "",
                                customImage: "",
                                id: newCategory.id,
                              })
                              selectIcon(image)
                            }}
                          >
                            <img alt={image} src={image} />
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {isLargeImageOpen && (
                <div className="imagePreview">
                  <div className="preview-inner">
                    {newCategory.customImage ? (
                      <div
                        ref={categoryImageRef}
                        className="newCategoryImage large"
                        style={{
                          width: 200,
                          borderRadius: 200,
                          height: 200,
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt={newCategory.name}
                          src={newCategory.customImage}
                          style={{ width: "auto", height: "100%" }}
                        />
                      </div>
                    ) : (
                      <div
                        ref={categoryImageRef}
                        style={{
                          backgroundColor: categoryColor,
                          backgroundSize: "80%",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundImage: "url(" + activeIconPath + ")",
                        }}
                        className="newCategoryImage large"
                      />
                    )}
                    <div className="progress">
                      <div
                        style={{
                          backgroundColor: theme.palette.secondary.main,
                        }}
                      />
                    </div>
                    <strong>Saving category...</strong>
                  </div>
                </div>
              )}

              {isPreviewOpen && (
                <div
                  className="category-preview"
                  onClick={() => setPreviewOpen(false)}
                >
                  <Card variant={"elevation"} className="card small">
                    <div
                      className="card-image"
                      style={{
                        backgroundColor: new Values(categoryColor)
                          .shade(20)
                          .hexString(),
                      }}
                    />
                    <CardContent>
                      <div className="card-header">
                        {newCategory.customImage ? (
                          <div
                            className="category-icon-wrapper custom"
                            style={{
                              borderRadius: 100,
                              margin: "0 auto",
                              boxShadow: "0 2px 4px 0 rgb(0 0 0 / 18%)",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              alt={newCategory.name}
                              src={newCategory.customImage}
                              style={{ width: "auto", height: "100%" }}
                            />
                          </div>
                        ) : (
                          <div className="category-icon-wrapper">
                            <span
                              className="category-icon"
                              style={{
                                boxSizing: "border-box",
                                backgroundColor: categoryColor,
                                backgroundImage: "url(" + activeIconPath + ")",
                              }}
                            />
                          </div>
                        )}

                        <div className="course-label">
                          <span className="category-label">
                            {newCategory.name}
                          </span>
                          <div className="course-level">
                            <Rating level={"advanced"} />
                          </div>
                        </div>
                      </div>

                      <h2>Example Course</h2>
                    </CardContent>
                    <footer className="course-details">
                      <p>
                        A description of the micro-course with this category.
                      </p>
                    </footer>
                  </Card>
                </div>
              )}

              <footer className="buttonContainer">
                <Button
                  className="button secondary large"
                  onClick={cancelCreate}
                >
                  Cancel
                </Button>
                <div
                  className={classNames(
                    ((!activeIcon && !newCategory.customImage) ||
                      !newCategory.name ||
                      (isPartner && newCategory.parentCategoryId === "none")) &&
                      "disabled"
                  )}
                >
                  <ButtonLarge
                    onClick={() => {
                      showLargeImage(true)
                      setTimeout(() => {
                        initSave()
                      }, 1000)
                    }}
                  >
                    Save Category
                  </ButtonLarge>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ManageCategoriesModal
