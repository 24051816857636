import qs from "qs"
import fetchWrapper from "../functions/fetchWrapper"
import { isMMContentHidden } from "../functions"
import { setUserLearningPaths, setAssessments } from "./index"
import _ from "lodash"
import moment from "moment"
import { filterByPartners } from "../functions/index"

/**
 * Get a single course entity
 * @param {number} id The id of the course to fetch
 */
export function getCourse(id, drupal_internal__id) {
  let url =
    "/api/course_entity/course_entity/" +
    id +
    "?include=field_mul,field_related_courses,field_quiz,field_attachment"

  if (drupal_internal__id) {
    let query = {
      filter: {
        drupal_internal__id: id,
      },
      include:
        "field_tags,field_mul,field_related_courses,field_quiz,field_source_template,field_attachment",
    }

    url = "/api/course_entity/course_entity?" + qs.stringify(query)
  }

  return async (dispatch) => {
    try {
      let response = await fetchWrapper.get(url)
      if (response.ok) {
        let data = await response.json()

        let courses = []

        data.included &&
          data.included.forEach((included) => {
            if (included.type === "course_entity--course_entity") {
              courses.push(included)
            }
          })

        data.data[0] && courses.push(data.data[0])

        let quiz =
          data.included &&
          data.included.find(
            (included) => included.type === "quiz_entity--course_quiz"
          )
        let fullQuiz

        if (quiz) {
          let quizRequest = await fetchWrapper.get(
            "/api/quiz/" + quiz.attributes.drupal_internal__id
          )
          fullQuiz = await quizRequest.json()
        }

        if (data) {
          dispatch({
            type: "courses/get",
            payload: courses,
            meta: {
              field_mul:
                data.included &&
                data.included.filter(
                  (included) => included.type === "paragraph--multiple_choice"
                ),
              quiz,
              sourceTemplate:
                data.included &&
                data.included.find(
                  (included) =>
                    included.type === "course_template--course_template"
                ),
              attachments:
                data.included &&
                data.included.filter((included) =>
                  included.type.includes("file--")
                ),
              tags:
                data.included &&
                data.included.filter(
                  (included) => included.type === "taxonomy_term--tags"
                ),
            },
          })

          // Set active course on extension
          const meta = {
            field_mul:
              data.included &&
              data.included.filter(
                (included) => included.type === "paragraph--multiple_choice"
              ),
            quiz: fullQuiz,
            attachments:
              data.included &&
              data.included.filter((included) =>
                included.type.includes("file--")
              ),
          }

          dispatch({
            type: "course/get",
            payload: data.data,
            meta,
          })

          return {
            course: data.data[0],
            quizId: quiz && quiz.attributes.drupal_internal__id,
            quiz,
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Get a single quiz entity for a course
 * @param {number} id The id of the quiz to fetch
 */
export function getQuiz(id, courseId) {
  return async (dispatch) => {
    try {
      let response = await fetchWrapper.get("/api/quiz/" + id)

      if (response.ok) {
        let data = await response.json()
        if (courseId) {
          dispatch({
            type: "courses/getQuiz",
            payload: data[0],
            meta: {
              courseId,
            },
          })
          return data[0]
        }

        dispatch({
          type: "course/getQuiz",
          payload: data[0],
          meta: {
            courseId,
          },
        })

        // Get it to the mobile app
        dispatch({
          type: "activeCourseSlice/getQuiz",
          payload: data[0],
        })

        return data[0]
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getCourses(trial, courseIds) {
  return async (dispatch, getState) => {
    const { session } = getState()
    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value
    let groupId =
      session.subgroup &&
      session.subgroup.uuid &&
      session.subgroup.uuid[0].value

    try {
      let pages = 1
      let i = 0

      let query = {
        filter: {
          draft: {
            group: {
              conjunction: "OR",
            },
          },
          "null-draft": {
            condition: {
              operator: "IS NULL",
              path: "field_draft",
              memberOf: "draft",
            },
          },
          "is-draft": {
            condition: {
              path: "field_draft",
              value: false,
              memberOf: "draft",
            },
          },
        },
        include: "field_mul",
      }

      if (courseIds) {
        query.filter.course = {
          group: {
            conjunction: "OR",
          },
        }
        courseIds.forEach((id, index) => {
          query.filter["course-" + index] = {
            condition: {
              path: "id",
              value: id,
              memberOf: "course",
            },
          }
        })
      }

      while (i < pages) {
        query.page = {
          offset: i * 50,
        }

        let response = await fetchWrapper.get(
          "/api/course_entity/course_entity?" + qs.stringify(query)
        )
        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(data.meta.count / 50)

          // Only include courses from MM or the user's org
          let coursesByOrg, coursesByGroup
          if (orgId) {
            coursesByOrg = data.data.filter(
              (course) =>
                !course.relationships.field_organization.data ||
                course.relationships.field_organization.data.id === orgId
            )
          }
          if (coursesByOrg) {
            coursesByGroup = coursesByOrg.filter(
              (course) =>
                !course.relationships.field_subgroup.data.length ||
                course.relationships.field_subgroup.data.id === groupId
            )
          }

          dispatch({
            type: "courses/get",
            payload: coursesByGroup ? coursesByGroup : data.data,
            meta: {
              fetched: pages - 1 === i,
            },
          })
        }
        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getLearningPaths() {
  return async (dispatch, getState) => {
    const { session } = getState()

    let orgId = session.group.uuid && session.group.uuid[0].value
    let organization = session.group
    const userJobTitleId =
      session.user.relationships.field_job_title.data &&
      session.user.relationships.field_job_title.data.id

    let pages = 1
    let i = 0

    try {
      while (i < pages) {
        let query = {
          filter: {
            draft: {
              group: {
                conjunction: "OR",
              },
            },
            "null-draft": {
              condition: {
                operator: "IS NULL",
                path: "field_draft",
                memberOf: "draft",
              },
            },
            "is-draft": {
              condition: {
                path: "field_draft",
                value: false,
                memberOf: "draft",
              },
            },
          },
          page: {
            offset: i * 50,
          },
          include: "field_image,field_type,field_courses",
        }

        // If we're excluding MM content
        if (isMMContentHidden(session)) {
          query.filter["field_organization.id"] =
            session.group.uuid && session.group.uuid[0].value
        }

        let response = await fetchWrapper.get(
          "/api/learning_path/learning_path?" + qs.stringify(query)
        )
        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)

          let orgPartnerIds = session.group.field_partner
            ? session.group.field_partner.map((partner) => partner.target_uuid)
            : []
          let groupPartnerId = session.subgroup
            ? session.subgroup.field_partner.map(
                (partner) => partner.target_uuid
              )
            : []
          let partnerIds = orgPartnerIds.concat(groupPartnerId)

          let paths = data.data

          let excludedPaths =
            session.group && session.group.field_learning_path_to_exclude
          let excludedPathIds =
            excludedPaths && excludedPaths.map((path) => path.target_uuid)
          let filteredPaths =
            excludedPathIds &&
            paths.filter((path) => !excludedPathIds.includes(path.id))
          let courses =
            data.included &&
            data.included.filter(
              (included) => included.type === "course_entity--course_entity"
            )
          let learningPaths = filteredPaths ? filteredPaths : paths

          // Don't include draft courses
          let activeCourses =
            courses &&
            courses.filter((course) => !course.attributes.field_draft)

          // Only include courses and learning paths from MM or the user's org
          let coursesByOrg =
            activeCourses &&
            activeCourses.filter(
              (course) =>
                !course.relationships.field_organization.data ||
                course.relationships.field_organization.data.id === orgId
            )
          let coursesByPartner =
            coursesByOrg &&
            coursesByOrg.filter(
              (course) =>
                !course.relationships.field_partner.data ||
                partnerIds.includes(course.relationships.field_partner.data.id)
            )
          let pathsByOrg = learningPaths.filter(
            (path) =>
              !path.relationships.field_organization.data ||
              path.relationships.field_organization.data.id === orgId
          )

          // And Group
          let pathsByGroup = pathsByOrg.filter((path) => {
            const isAdmin =
              session.orgRoles.includes("organization-admin") ||
              session.orgRoles.includes("organization-creator")

            let pathGroupIds = []
            if (path.relationships.field_subgroup.data) {
              pathGroupIds = path.relationships.field_subgroup.data.map(
                (group) => group.id
              )
            }

            let subGroups =
              session.subgroups && session.subgroups.data
                ? session.subgroups.data
                : session.subgroups
            if (subGroups && subGroups[0] && subGroups[0][0]) {
              subGroups = subGroups[0]
            }

            let hasOwnGroup =
              subGroups &&
              subGroups.find((group) => {
                return pathGroupIds.find((groupId) => {
                  return (
                    (group[0] && group[0].uuid[0].value === groupId) ||
                    groupId === group.id
                  )
                })
              })

            return (
              !path.relationships.field_subgroup.data ||
              !path.relationships.field_subgroup.data.length ||
              hasOwnGroup ||
              isAdmin
            )
          })

          // And Job Title
          let pathsByJobTitle = pathsByGroup.filter((path) => {
            const jobTitleIds =
              path.relationships.field_job_title.data &&
              path.relationships.field_job_title.data.map((title) => title.id)
            return !jobTitleIds.length || jobTitleIds.includes(userJobTitleId)
          })

          // And user
          let pathsByUser = pathsByJobTitle.filter((path) => {
            const userIds =
              path.relationships.field_teacher.data &&
              path.relationships.field_teacher.data.map((teacher) => teacher.id)
            return !userIds.length || userIds.includes(session.user.id)
          })

          // And partnerships
          let pathsByPartnerships = filterByPartners(
            organization,
            session.subgroups,
            pathsByUser,
            []
          )

          // Finally let's make sure it's not assigned a category that the org has excluded
          // (This will most often just be Microsoft)
          let pathsByCategory = pathsByPartnerships.filter((path) => {
            if (!session.group.field_categories_to_exclude) {
              return path
            }

            const categoryData = path.relationships.field_lp_category.data
            const excludedCategoryIds =
              session.group.field_categories_to_exclude.map(
                (cat) => cat.target_uuid
              )
            let isExcluded = categoryData.find((lpCat) =>
              excludedCategoryIds.includes(lpCat.id)
            )

            return !isExcluded
          })

          dispatch({
            type: "courses/get",
            payload: coursesByPartner,
          })

          dispatch({
            type: "learningPaths/get",
            payload: pathsByCategory,
            meta: {
              included: data.included,
              fetched: i === pages - 1,
            },
          })

          dispatch({
            type: "learningPathsSlice/getLearningPaths",
            payload: pathsByCategory,
            meta: {
              included: data.included,
              fetched: i === pages - 1,
            },
          })
        }
        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getUserLearningPaths(user) {
  return async (dispatch) => {
    let pages = 1
    let i = 0
    let paths = []
    try {
      while (i < pages) {
        let query = {
          filter: {
            "field_user.id": user.id,
          },
          page: {
            offset: i * 50,
          },
          include: "field_learning_path,field_time_spent",
        }

        let response = await fetchWrapper.get(
          "/api/user_learning_path/user_learning_path?" + qs.stringify(query)
        )

        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)

          dispatch(setUserLearningPaths(data, true))
          paths.push(data.data)
          return data.data
        }

        i++
      }

      return paths
    } catch (err) {
      console.log(err)
    }
  }
}

export function getCategories() {
  return async (dispatch) => {
    dispatch({ type: "categories/setIsFetching" })
    let pages = 1
    let i = 0
    let categories = []

    try {
      while (i < pages) {
        let query = {
          include: "field_category_image",
          page: {
            offset: i * 50,
          },
        }

        let response = await fetchWrapper.get(
          "/api/taxonomy_term/category?" + qs.stringify(query)
        )

        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)

          categories.push(data.data)

          dispatch({
            type: "categories/get",
            payload: data.data,
            meta: {
              included: data.included,
              fetched: i === pages - 1,
            },
          })
          dispatch({
            type: "categories/getCategories",
            payload: {
              data: data.data,
              included: data.included,
              fetched: i === pages - 1,
            },
          })
        }
        i++
      }
      return categories
    } catch (err) {
      console.log(err)
    }
  }
}

export function getTeacherImage(uid) {
  return async (dispatch) => {
    let query = {
      include: "user_picture",
    }

    try {
      let response = await fetchWrapper.get(
        "/api/user/user/" + uid + "?" + qs.stringify(query)
      )
      if (response.ok) {
        let status = await response.json()
        if (status) {
          return dispatch({
            type: "teacher/getImage",
            payload: status.included && status.included[0],
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function setIsGoToNext(value) {
  return async (dispatch) => {
    dispatch({
      type: "assessment/setIsGoToNext",
      payload: value,
    })
  }
}

export function setReviewedByMe(value) {
  return async (dispatch) => {
    dispatch({
      type: "assessment/setReviewedByMe",
      payload: value,
    })
  }
}

export function getAssessmentRevisions(assessmentId) {
  return async (dispatch) => {
    try {
      let response = await fetchWrapper.get(
        "/api/assessment_entity/revisions/" + assessmentId
      )
      if (response.ok) {
        let data = await response.json()

        let filteredBySubmission = []

        if (!data.rows.content) {
          data.rows.forEach((row) => {
            row.created = row.created.split('"')[1]
            row.submission = row.field_text_submission__revision_id
            row.image = row.field_image_submission__revision_id
            row.url = row.field_document_url__revision_id
          })

          filteredBySubmission = data.rows.filter((row) => {
            return (
              row.submission ||
              row.image ||
              row.url ||
              row.field_file_submission__revision_id
            )
          })
        }

        dispatch({
          type: "assessments/getRevisions",
          payload: filteredBySubmission,
          meta: {
            assessmentId,
          },
        })

        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getAssessmentsForReviewer(args) {
  let {
    course_title,
    field_category,
    assessmentId,
    onReviewedByMe,
    isNext,
    reviewType,
  } = args

  return async (dispatch, getState) => {
    const { session } = getState()
    try {
      let query, data
      let extractColumn = (arr, column) => arr.map((x) => x[column])

      const orgId = session.group && session.group.id[0].value
      const groupId = session.subgroup && session.subgroup.id[0].value

      const roles = extractColumn(session.roles, "target_id")
      let reviewAPIPath

      if (onReviewedByMe) {
        reviewAPIPath = "/api/reviewed"
      } else if (
        roles.includes("administrator") ||
        roles.includes("reviewer")
      ) {
        reviewAPIPath = "/api/reviewer/mm"
      } else if (roles.includes("tier_1_grader")) {
        reviewAPIPath = "/api/reviewer/mm/tier-1"
      } else if (reviewType === "admin") {
        let isOrgLevel =
          session.orgRoles.includes("organization-admin") ||
          session.orgRoles.includes("organization-reviewer")
        reviewAPIPath = isOrgLevel
          ? "/api/reviewer/organization/" + orgId
          : "/api/reviewer/group/" + groupId
      } else {
        reviewAPIPath = "/api/reviewer"
      }

      // If we have an assessment ID, we're getting a specific assessment that was clicked
      if (assessmentId) {
        query = { assessment_uuid: assessmentId }
        let byId = await fetchWrapper.get(
          reviewAPIPath + "?" + qs.stringify(query)
        )
        if (byId.ok) {
          data = await byId.json()
        }
      }

      // Otherwise if we're going to the "next" assessment
      if (isNext) {
        let courseTitle = course_title && _.unescape(course_title)

        // See if we can find one for the same course
        query = { combine: courseTitle }

        let byCourse = await fetchWrapper.get(
          reviewAPIPath + "?" + qs.stringify(query)
        )

        if (byCourse.ok) {
          data = await byCourse.json()

          // If no more assessments for this course are found
          if (data.rows.content) {
            query = { field_category_target_id: field_category }
            let byCategory = await fetchWrapper.get(
              reviewAPIPath + "?" + qs.stringify(query)
            )

            // See if we can find one in the same category
            if (byCategory.ok) {
              data = await byCategory.json()

              // If no more category courses are found, just get the first one
              if (data.rows.content) {
                let getFirst = await fetchWrapper.get(reviewAPIPath)
                if (getFirst.ok) {
                  data = await getFirst.json()
                }
              }
            }
          }
        }
      }

      let payload

      if (data.rows.content) {
        payload = null
      } else {
        if (isNext) {
          let assessment = data.rows[0]
          assessment.course_title = _.unescape(assessment.course_title)
          payload = assessment
        } else {
          payload = data.rows.find(
            (assessment) => assessment.assessment_uuid === assessmentId
          )
        }
      }

      dispatch({
        type: "assessment/get",
        payload: payload,
      })

      return data.rows[0]
    } catch (err) {
      console.log(err)
    }
  }
}

export function getAssessments(userId) {
  return async (dispatch, getState) => {
    try {
      let { user } = getState().session
      let pages = 1
      let i = 0

      while (i < pages) {
        let query = {
          filter: {
            "field_teacher.id": userId ? userId : user.id,
          },
          page: {
            offset: i * 50,
          },
          include: "field_course",
        }

        let response = await fetchWrapper.get(
          "/api/assessment_entity/assessment_entity?" + qs.stringify(query)
        )
        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)
          dispatch(setAssessments(data, !pages || i === pages - 1))
        }
        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Get a single assessment by its id, and include the course, teacher, and image submission
 * @param {string} id The id of the assessment to fetch
 */
export function getAssessment(id) {
  return async (dispatch, getState) => {
    let query = {
      include:
        "field_course,field_teacher,field_image_submission,field_quiz_answers",
    }

    try {
      const { session } = getState()
      let orgId = session.group.uuid && session.group.uuid[0].value

      let response = await fetchWrapper.get(
        "/api/assessment_entity/assessment_entity/" +
          id +
          "?" +
          qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        if (data) {
          let courses = data.included.filter(
            (included) => included.type === "course_entity--course_entity"
          )

          // Don't include draft courses
          let activeCourses = courses.filter(
            (course) => course.attributes.status
          )

          // Only include courses from MM or the user's org
          let coursesByOrg = activeCourses.filter(
            (course) =>
              !course.relationships.field_organization.data ||
              course.relationships.field_organization.data.id === orgId
          )

          let rewardsResponse = await fetchWrapper.get(
            "/api/assessment-result/" + data.data.attributes.drupal_internal__id
          )
          let rewards
          if (rewardsResponse.ok) {
            rewards = await rewardsResponse.json()
          }

          dispatch({
            type: "courses/get",
            payload: coursesByOrg,
            meta: {
              quizAnswers: data.included.filter(
                (included) => included.type === "paragraph--quiz_submission"
              ),
            },
          })

          dispatch({
            type: "assessment/get",
            payload: data.data,
            meta: {
              rewards,
              quizAnswers: data.included.filter(
                (included) => included.type === "paragraph--quiz_submission"
              ),
              userData: data.included.filter(
                (included) => included.type === "user--user"
              ),
              image: data.included.filter(
                (included) => included.type === "file--image"
              ),
            },
          })

          dispatch({
            type: "activeCourseSlice/getAssessment",
            payload: data.data,
            meta: {
              rewards,
              quizAnswers: data.included.filter(
                (included) => included.type === "paragraph--quiz_submission"
              ),
              userData: data.included.filter(
                (included) => included.type === "user--user"
              ),
              image: data.included.filter(
                (included) => included.type === "file--image"
              ),
            },
          })

          dispatch(setAssessments(data))
          return data
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateAssessment(id, status, comments) {
  return async (dispatch, getState) => {
    let body = JSON.stringify({
      data: {
        id: id,
        type: "assessment_entity--assessment_entity",
        attributes: {
          field_status: status,
          field_comments: comments,
          field_review_date: moment().format(),
          field_last_updated_by: [
            {
              value: getState().session.user.attributes.uid,
            },
          ],
        },
        relationships: {
          field_reviewer: {
            data: {
              type: "user--user",
              id: getState().session.user.id,
            },
          },
        },
      },
    })

    try {
      let response = await fetchWrapper.patch(
        "/api/assessment_entity/assessment_entity/" + id,
        getState().session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()
        return dispatch({ type: "assessment/update", payload: data.data })
      }

      return true
    } catch (err) {
      console.log(err)
    }
  }
}

export function submitAssessment(args) {
  let { course, content, learnerComments, status, assessment, isNext } = args

  // The teacher Assessment component will not pass a status argument to this function,
  // so we'll take the assessment response and always update the assessment to "submitted"
  if (!status) {
    return async (dispatch, getState) => {
      let attributes = {
        field_status: "submitted",
        field_comments: "",
        field_learner_comments: learnerComments,
      }

      if (course.attributes.field_submission_type === "Multiple Choice") {
        let answer = course.field_mul.find(
          (option) => option.attributes.drupal_internal__id === Number(content)
        )
        attributes.field_multiple_choice_submission =
          Number(content) + "=" + answer.attributes.field_mc_answer
      } else if (
        course.attributes.field_submission_type === "Text" ||
        !course.attributes.field_submission_type
      ) {
        attributes.field_text_submission = content
      } else if (course.attributes.field_submission_type === "URL") {
        attributes.field_document_url = content
      } else if (course.attributes.field_submission_type === "Checkbox") {
        attributes.field_checkbox_options = content
      }

      attributes.field_last_updated_by = [
        {
          value: getState().session.user.attributes.drupal_internal__uid,
        },
      ]

      let body = JSON.stringify({
        data: {
          id: assessment.id,
          type: "assessment_entity--assessment_entity",
          attributes: attributes,
          relationships: {
            field_reviewer: {
              data: null,
            },
          },
        },
      })

      try {
        let response = await fetchWrapper.patch(
          "/api/assessment_entity/assessment_entity/" + assessment.id,
          getState().session.token,
          body
        )

        if (response.ok) {
          let data = await response.json()

          // Hit the user goal update endpoint
          fetchWrapper.get(
            "/api/user_goals/update/" +
              assessment.attributes.drupal_internal__id
          )

          dispatch({
            type: "assessment/submit",
            payload: data.data,
          })

          // Get this over to mobile
          dispatch({
            type: "activeCourseSlice/submitAssessment",
            payload: data.data,
          })

          return data
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  // The Review component will update the status to either completed or in_progress
  else if (status) {
    return async (dispatch, getState) => {
      let body = JSON.stringify({
        data: {
          id: assessment.assessment_uuid,
          type: "assessment_entity--assessment_entity",
          attributes: {
            field_status: status,
            field_comments: content,
            field_last_updated_by: [
              {
                value: getState().session.user.attributes.drupal_internal__uid,
              },
            ],
            field_review_date: new moment().format(),
          },
          relationships: {
            field_reviewer: {
              data: {
                type: "user--user",
                id: getState().session.user.id,
              },
            },
          },
        },
      })

      try {
        let response = await fetchWrapper.patch(
          "/api/assessment_entity/assessment_entity/" +
            assessment.assessment_uuid,
          getState().session.token,
          body
        )

        if (response.ok) {
          let data = await response.json()

          // Hit the user goal update endpoint
          fetchWrapper.get(
            "/api/user_goals/update/" + assessment.drupal_internal__id
          )

          dispatch({
            type: "assessment/submit",
            meta: {
              isNext: isNext,
            },
          })
          return data
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
}

export function checkAnswerKey(assessment, answer, token) {
  return async (dispatch, getState) => {
    const { session } = getState()
    if (!token) {
      token = session.token
    }

    try {
      let body = JSON.stringify({
        data: {
          id: assessment.id,
          type: "assessment_entity--assessment_entity",
          attributes: {
            field_status: "submitted",
            field_text_submission: answer,
            field_comments: "",
          },
          relationships: {
            field_reviewer: {
              data: null,
            },
          },
        },
      })

      let updatedAssessment = await fetchWrapper.patch(
        "/api/assessment_entity/assessment_entity/" + assessment.id,
        token,
        body
      )

      if (updatedAssessment.ok) {
        let checkedAnswer = await fetchWrapper.get(
          "/api/answer_auto/" + assessment.attributes.drupal_internal__id
        )
        if (checkedAnswer.ok) {
          let data = await checkedAnswer.json()

          // Hit the user goal update endpoint
          fetchWrapper.get(
            "/api/user_goals/update/" +
              assessment.attributes.drupal_internal__id
          )

          dispatch({ type: "assessment/checkAnswerKey", payload: data })
          dispatch({ type: "assessment/increaseAttempts" })

          // Mobile reducer
          dispatch({
            type: "activeCourseSlice/checkTextAnswer",
            payload: data,
          })

          return data
        } else {
          dispatch({ type: "assessment/missingKey" })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function setAssessmentInProgress(assessmentId) {
  return async (dispatch, getState) => {
    const token = getState().session.token

    try {
      let body = {
        data: {
          id: assessmentId,
          type: "assessment_entity--assessment_entity",
          attributes: {
            field_status: "in_progress",
          },
        },
      }

      let updatedAssessment = await fetchWrapper.patch(
        "/api/assessment_entity/assessment_entity/" + assessmentId,
        token,
        JSON.stringify(body)
      )
      if (updatedAssessment.ok) {
        let data = await updatedAssessment.json()
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function completeAutomatedAssessment(assessmentId) {
  return async (dispatch, getState) => {
    const token = getState().session.token

    try {
      let body = {
        data: {
          id: assessmentId,
          type: "assessment_entity--assessment_entity",
          attributes: {
            field_comments: "",
            field_status: "completed",
          },
        },
      }

      let updatedAssessment = await fetchWrapper.patch(
        "/api/assessment_entity/assessment_entity/" + assessmentId,
        token,
        JSON.stringify(body)
      )
      if (updatedAssessment.ok) {
        let data = await updatedAssessment.json()

        // Hit the user goal update endpoint
        fetchWrapper.get(
          "/api/user_goals/update/" + data.data.attributes.drupal_internal__id
        )

        dispatch({
          type: "assessment/submit",
          payload: data.data,
        })
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getRecommendedCourses(
  assessmentId,
  isMobile,
  learningPathId,
  mandatedTrainingId
) {
  return async (dispatch) => {
    try {
      let url = isMobile
        ? "/api/mobile_friendly_course_recommendation/" + assessmentId
        : "/api/course_recommendation/" + assessmentId

      const query = {}
      if (learningPathId) {
        query.lp = learningPathId
      }
      if (mandatedTrainingId) {
        query.rcs = mandatedTrainingId
      }

      let recommendedCourses

      if (isMobile) {
        recommendedCourses = await fetchWrapper.get(url)
      } else {
        recommendedCourses = await fetchWrapper.get(
          url + "?" + qs.stringify(query)
        )
      }

      if (recommendedCourses.ok) {
        let data = await recommendedCourses.json()

        dispatch({
          type: "recommendedCourses/get",
          payload: data.data ?? data,
        })

        // Get this to mobile
        dispatch({
          type: "activeCourseSlice/getRecommendedCourses",
          payload: data,
        })

        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function submitQuizSingleAnswer(
  answer,
  assessment,
  finalQuestion,
  firstQuestion,
  mobileToken,
  singleQuestionQuiz
) {
  return async (dispatch, getState) => {
    const { session } = getState()

    const userAssessment = assessment.assessmentData
      ? assessment.assessmentData
      : assessment
    const token = mobileToken ? mobileToken : session.token

    try {
      if (firstQuestion || singleQuestionQuiz) {
        let emptyAssessment = {
          data: {
            id: userAssessment.id,
            type: "assessment_entity--assessment_entity",
            relationships: {
              field_quiz_answers: {
                data: [],
              },
            },
          },
        }
        await fetchWrapper.patch(
          "/api/assessment_entity/assessment_entity/" + userAssessment.id,
          token,
          JSON.stringify(emptyAssessment)
        )
      }

      let currentAssessment = await fetchWrapper.get(
        "/api/assessment_entity/assessment_entity/" + userAssessment.id
      )
      let currentData = await currentAssessment.json()

      let existingAnswers =
        currentData.data.relationships.field_quiz_answers.data

      let answerBody = {
        data: {
          type: "paragraph--quiz_submission",
          attributes: {
            status: true,
            parent_id: userAssessment.attributes.drupal_internal__id,
            parent_type: "assessment_entity",
            parent_field_name: "field_quiz_answers",
            field_quiz_answer: answer,
          },
        },
      }

      let answerPost = await fetchWrapper.post(
        "/api/paragraph/quiz_submission",
        token,
        JSON.stringify(answerBody)
      )
      let answerData = await answerPost.json()

      existingAnswers.push({
        type: "paragraph--quiz_submission",
        id: answerData.data.id,
        meta: {
          target_revision_id:
            answerData.data.attributes.drupal_internal__revision_id,
        },
      })

      let assessmentBody = {
        data: {
          id: userAssessment.id,
          type: "assessment_entity--assessment_entity",
          attributes: {
            field_status: finalQuestion ? "submitted" : "in_progress",
          },
          relationships: {
            field_quiz_answers: {
              data: existingAnswers,
            },
          },
        },
      }

      let patchedAssessment = await fetchWrapper.patch(
        "/api/assessment_entity/assessment_entity/" + userAssessment.id,
        token,
        JSON.stringify(assessmentBody)
      )
      let patchedData = await patchedAssessment.json()

      if (finalQuestion) {
        let updatedAssessment = await fetchWrapper.get(
          "/api/assessment_entity/assessment_entity/" + userAssessment.id
        )
        let data = await updatedAssessment.json()

        // Hit the user goal update endpoint
        fetchWrapper.get(
          "/api/user_goals/update/" +
            userAssessment.attributes.drupal_internal__id
        )

        dispatch({
          type: "assessment/submit",
          payload: data.data,
        })

        // Mobile reducer
        dispatch({
          type: "activeCourseSlice/submitQuizAnswer",
          payload: data.data,
          meta: {
            finalQuestion,
          },
        })

        return updatedAssessment
      } else {
        dispatch({
          type: "assessment/nextQuizQuestion",
        })

        // Mobile reducer
        dispatch({
          type: "activeCourseSlice/submitQuizAnswer",
          payload: patchedData.data,
          meta: {
            finalQuestion,
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getTags({ isLearn }) {
  return async (dispatch, getState) => {
    const { session } = getState()
    try {
      let pages = 1
      let i = 0
      let allTags = []

      let query = !isLearn
        ? // HQ version
          {
            filter: {
              org: {
                condition: {
                  path: session.isPartner
                    ? "field_partner.id"
                    : "field_organization.id",
                  operator: "=",
                  value: session.group.uuid && session.group.uuid[0].value,
                },
              },
            },
          }
        : {
            filter: {
              main: {
                group: {
                  conjunction: "OR",
                },
              },
              org: {
                condition: {
                  path: session.isPartner
                    ? "field_partner.id"
                    : "field_organization.id",
                  operator: "=",
                  value: session.group.uuid && session.group.uuid[0].value,
                  memberOf: "main",
                },
              },
            },
          }

      if (!session.isPartner) {
        query.filter.partner = {
          condition: {
            path: "field_partner.id",
            operator: "IN",
            value: {},
            memberOf: "main",
          },
        }
      }

      if (isLearn) {
        session.group.field_partner?.forEach((partner) => {
          query.filter.partner.condition.value[partner.target_id] =
            partner.target_uuid
        })
      }

      while (i < pages) {
        query.page = { offset: i * 50 }

        let response = await fetchWrapper.get(
          "/api/taxonomy_term/tags?" + qs.stringify(query)
        )

        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)
          allTags = allTags.concat(data.data)
        }
        i++
      }

      dispatch({
        type: "tags/getTags",
        payload: allTags,
      })

      return allTags
    } catch (err) {
      console.log(err)
    }
  }
}
