import { Formik } from "formik"
import yup from "@mobilemind/common/src/utility/yup"
import { SignUpFormValues, signUpState } from "../../store/reducers/signup"

import { SignUpUserInformation } from "./components/SignUpUserInformation"
import { Box, Card } from "@mui/material"
import "../../styles/signup.scss"

export function SignUpStep1() {
  const initialValues: SignUpFormValues = signUpState

  const validationSchema = yup.object({
    username: yup.string().required(),
    email: yup.string().required(),
    confirmGoogleMS: yup.boolean().required(),
    confirmTAS: yup.boolean().required(),
  })

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Card
        sx={{
          width: 600,
          background: "white",
          padding: 3,
          paddingInline: 5,
          maxWidth: 550,
        }}
      >
        <img alt="MobileMind" src={require("../../img/logo.png")} />

        <Formik
          initialValues={initialValues}
          onSubmit={async (values: SignUpFormValues) => {}}
          validationSchema={validationSchema}
        >
          {(values) => {
            return <SignUpUserInformation />
          }}
        </Formik>
      </Card>
    </Box>
  )
}

export default SignUpStep1
