import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { SignUpStep2Values } from "features/signup/SignUpStep2"

export const sendVerificationEmail = createAsyncThunk(
  "signup/sendVerificationEmail",
  async (userData: SignUpFormValues, thunkAPI: any) => {
    const token = thunkAPI.getState().session.token

    const body = {
      data: {
        type: "user--user",
        attributes: {
          name: userData.username,
          mail: userData.email,
          mass_contact_opt_out: [],
        },
      },
    }

    let options = {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        "X-CSRF-Token": token,
        "Accept-Encoding": "gzip, deflate, br",
      }),
      body: JSON.stringify(body),
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/mm/user/register",
        // @ts-ignore
        options
      )

      const data = await response.json()
      return data
    } catch (e) {
      return e
    }
  }
)

export const requestCreateOrganization = createAsyncThunk(
  "signup/requestCreateOrganization",
  async (orgData: SignUpStep2Values, thunkAPI: any) => {
    const token = thunkAPI.getState().session.token

    const body = {
      data: {
        type: "group--organization",
        attributes: {
          label: orgData.organizationName,
          first: orgData.firstName,
          last: orgData.lastName,
        },
      },
    }

    let options = {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
        "Accept-Encoding": "gzip, deflate, br",
      }),
      body: JSON.stringify(body),
    }

    const response = await fetch(
      process.env.REACT_APP_API_URL + "/api/mm/user/register/organization",
      // @ts-ignore
      options
    )

    const data = await response.json()
    return data
  }
)

export type SignUpFormValues = {
  username: string
  email: string
  confirmGoogleMS: boolean
  confirmTAS: boolean
}

export const signUpState: SignUpFormValues = {
  username: "",
  email: "",
  confirmGoogleMS: false,
  confirmTAS: false,
}

export const signUpSlice = createSlice({
  name: "signup",
  initialState: signUpState,
  reducers: {},
})

export default signUpSlice.reducer
