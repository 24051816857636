import { useState, useEffect } from "react"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import HubspotForm from "react-hubspot-form"
import { Link } from "react-router-dom"

import Loading from "@mobilemind/common/src/components/Loading"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"

function RequestUpgradeLayout() {
  const [showReturnButton, setShowReturnButton] = useState(false)

  useEffect(() => {
    window.addEventListener("message", handler)
    return () => {
      window.removeEventListener("message", handler)
    }
  }, [])

  function handler(event: {
    data: {
      type: string
      eventName: string
    }
  }) {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      setShowReturnButton(true)
    }
  }
  return (
    <div className="page page-hubspot">
      <div
        style={{
          minWidth: 768,
          maxWidth: 960,
          paddingTop: showReturnButton ? 100 : 0,
        }}
      >
        {!showReturnButton && (
          <div style={{ marginBottom: 50 }}>
            <LearningBuddyMessage
              message={
                <>
                  <strong>
                    Get the most out of MobileMind with an upgrade!
                  </strong>
                  <br /> Complete the form below and we'll be in touch.
                </>
              }
            />
          </div>
        )}
        {!showReturnButton && (
          <HubspotForm
            portalId={"20989892"}
            formId={"58406561-8f8a-4be1-910c-120971a4e0ee"}
            onSubmit={handler}
            loading={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 100,
                }}
              >
                <Loading />
              </div>
            }
          />
        )}

        {showReturnButton && (
          <>
            <div style={{ marginBottom: 50 }}>
              <LearningBuddyMessage
                message={
                  <>
                    <strong>Thank you!</strong> A member of the team will reach
                    out to you soon to schedule a call. We look forward to
                    connecting!
                  </>
                }
              />
            </div>
            <footer>
              <Link to="/">
                <ButtonLarge>Back to Dashboard</ButtonLarge>
              </Link>
            </footer>
          </>
        )}
      </div>
    </div>
  )
}

export default RequestUpgradeLayout
