import { Box, TextField } from "@mui/material"
import { useState } from "react"
import { Field, Form, useFormikContext } from "formik"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"

import { SignUpStep2Values } from "../SignUpStep2"
import Loading from "@mobilemind/common/src/components/Loading"
import { useAppDispatch } from "store/hooks"
import { requestCreateOrganization } from "store/reducers/signup"

import { openSnackbar } from "features/snackbar/snackbarSlice"

export const OrganizationSetup = () => {
  const { errors, isValid, touched, values, isSubmitting } =
    useFormikContext<SignUpStep2Values>()
  const [isLoading, setIsLoading] = useState(false)
  const canContinue =
    isValid && values.firstName && values.lastName && values.organizationName

  const dispatch = useAppDispatch()

  return (
    <Box
      className="sign-up-user-form"
      component={Form}
      sx={{ display: "contents" }}
    >
      <Box className="learning-buddy-animation" sx={{ marginBottom: 5 }}>
        <LearningBuddyMessage
          message={
            <>
              <strong>Almost done!</strong> Just tell me your name and the name
              of your organization and I'll finish setting everything up.
            </>
          }
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
        <Field
          style={{ flex: 1, marginRight: 15 }}
          as={TextField}
          name="firstName"
          label="First Name"
          required
          fullWidth={true}
          error={touched.firstName && !!errors.firstName}
          disabled={isSubmitting}
          autoFocus={true}
        />

        <Field
          style={{ flex: 1 }}
          as={TextField}
          name="lastName"
          label="Last Name"
          required
          fullWidth={true}
          error={touched.lastName && !!errors.lastName}
          disabled={isSubmitting}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
        <Field
          as={TextField}
          name="organizationName"
          label="Organization Name"
          required
          fullWidth={true}
          error={touched.organizationName && !!errors.organizationName}
          disabled={isSubmitting}
        />
      </Box>

      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loading message="Just a moment..." />
        </Box>
      )}
      {canContinue && !isLoading && (
        <Box>
          <ButtonLarge
            onClick={() => {
              setIsLoading(true)

              // Handle creating organization
              dispatch(requestCreateOrganization(values)).then((response) => {
                if (response?.payload?.message?.includes("already exists")) {
                  // Handle errors
                  dispatch(
                    openSnackbar({
                      message: response?.payload?.message,
                    })
                  )
                  setIsLoading(false)
                } else {
                  //  Redirect to dashboard
                  // @ts-ignore
                  window.location.href = process.env.REACT_APP_HQ_URL
                }
              })
            }}
          >
            Finish Signup
          </ButtonLarge>
        </Box>
      )}
    </Box>
  )
}
