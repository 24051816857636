import { Box } from "@mui/material"

import { useFormikContext } from "formik"
import { SignUpFormValues } from "../../../store/reducers/signup"

import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import "../../../styles/signup.scss"

export const VerificationEmailSent = () => {
  const { values } = useFormikContext<SignUpFormValues>()

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box className="learning-buddy-animation" sx={{ marginBottom: 3 }}>
        <LearningBuddyMessage
          message={
            <>
              <strong>Nice to meet you!</strong> You'll receive an email with a
              verification link at <strong>{values.email}</strong>. This link
              will expire in 10 minutes.
              <br />
              <br />
              After verifying your email, you'll be redirected to continue
              setting up your account.
            </>
          }
        />
      </Box>
    </Box>
  )
}
