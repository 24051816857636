import Loading from "@mobilemind/common/src/components/Loading"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { RootState } from "../../store/types"
import classNames from "classnames"
import { Field, Formik } from "formik"
import ImageUpload from "../../components/ImageUpload"
import IconLogo from "../../img/logo.svg"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"
import {
  Box,
  Button,
  Card,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"
import ReactQuill from "react-quill"
import { formats, modules } from "../../constants/reactQuillConfig"
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import {
  removeKB4APIKey,
  updateOrgSettings,
} from "features/session/sessionSlice"
import moment from "moment"
import LeaderboardIcon from "../../img/i-leaderboards.svg"
import KB4Icon from "../../img/kb4.svg"
import Mail from "../../img/invitation-color.svg"

import IconCertificate from "../../img/certificate-color.svg"

import ObservationIcon from "../../img/observation-color.svg"
import RewindIcon from "../../img/school-bus.svg"
import SettingsIcon from "../../img/settings-color.svg"
import TimeZoneIcon from "../../img/time-zone.svg"
import ClockIcon from "../../img/working-hours.svg"
import { convertSchoolStartEndTime } from "./utility"
import ButtonSmall from "components/ButtonSmall"

import SignatureFontSelectModal from "./SignatureFontSelectionModal"

export const OrgSettingsLayout = () => {
  const session = useAppSelector((state: RootState) => state.session)

  const {
    field_teacher_leaderboard_visibi,
    field_rewind_begin,
    field_rewind_end,
    field_open_time,
    field_close_time,
    field_knowbe4_api_key,
    field_org_time_zone,
    field_disable_observation,
    field_custom_mail,
    field_email_subject,
    field_email_body,
    field_signature_font,
    field_signature_name,
    field_signature_title,
    field_logo_path,
  } = session.group

  type OrgSettings = {
    field_teacher_leaderboard_visibi: string
    field_rewind_begin: string
    field_rewind_end: string
    field_open_time: string
    field_close_time: string
    field_knowbe4_api_key: string
    field_org_time_zone: string
    field_disable_observation: boolean
    field_custom_mail: boolean
    field_email_subject: string
    field_email_body: string
    field_signature_font: string
    field_signature_name: string
    field_signature_title: string
    field_logo_path:
      | {
          attributes: {
            uri: {
              url: string
            }
          }
        }
      | null
      | string
  }

  const { startTime, endTime } = convertSchoolStartEndTime({
    field_open_time,
    field_close_time,
  })

  const initialValues: OrgSettings = {
    field_teacher_leaderboard_visibi:
      field_teacher_leaderboard_visibi[0]?.value,
    field_org_time_zone: field_org_time_zone[0]?.value,
    field_disable_observation: field_disable_observation[0]?.value,
    field_custom_mail: field_custom_mail[0]?.value,
    field_email_subject: field_email_subject[0]?.value ?? "",
    field_email_body: field_email_body[0]?.value ?? "",
    field_rewind_begin:
      field_rewind_begin[0]?.value ??
      moment()
        .subtract(1, "year")
        .startOf("year")
        .add(7, "months")
        .format("YYYY-MM-DD"),
    field_rewind_end:
      field_rewind_end[0]?.value ??
      moment()
        .startOf("year")
        .add(4, "months")
        .add(27, "days")
        .format("YYYY-MM-DD"),
    field_open_time: startTime,
    field_close_time: endTime,
    field_knowbe4_api_key: field_knowbe4_api_key[0]?.value ?? "",
    field_signature_font: field_signature_font[0]?.value ?? "",
    field_signature_name: field_signature_name[0]?.value ?? "",
    field_signature_title: field_signature_title[0]?.value ?? "",
    field_logo_path: field_logo_path[0]?.value
      ? {
          attributes: {
            uri: {
              url: field_logo_path[0]?.value,
            },
          },
        }
      : null,
  }

  const [isSaving, setIsSaving] = useState(false)
  const [isSignatureFontModalOpen, setIsSignatureFontModalOpen] =
    useState(false)
  const [isMissingKB4] = useState(
    field_knowbe4_api_key[0]?.value ? false : true
  )

  const [isCustomEmail, setIsCustomEmail] = useState(
    field_custom_mail[0]?.value ?? false
  )

  const dispatch = useAppDispatch()

  return (
    <div
      className="page org-settings"
      style={{ maxWidth: 1024, margin: "0 auto", paddingTop: 80 }}
    >
      <h2 style={{ marginBottom: 20, justifyContent: "center" }}>
        <img
          src={SettingsIcon}
          alt="Settings"
          style={{ width: 35, height: 35, marginRight: 10 }}
        />
        Organization Settings
      </h2>
      {isSaving && <Loading fullPage={true} message="Just a moment..." />}

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setIsSaving(true)
          //@ts-ignore
          dispatch(updateOrgSettings({ values })).then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 750)
          })
        }}
      >
        {({ values, setFieldValue, submitForm }) => {
          const disableSave =
            values.field_custom_mail &&
            (!values.field_email_subject.length ||
              !values.field_email_body
                .replaceAll("<p>", "")
                .replaceAll("</p>", "")
                .replaceAll("<br>", ""))

          return (
            <Card sx={{ padding: 5 }}>
              {
                <div
                  style={{
                    opacity: isSaving ? 0.5 : 1,
                    pointerEvents: isSaving ? "none" : "all",
                  }}
                >
                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img
                        src={RewindIcon}
                        alt="School Year"
                        style={styles.headerIcon}
                      />
                      School Year
                    </header>
                    <p style={styles.helperText}>
                      Used for the School Year filter on reports as well as
                      MobileMind Rewind.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <div style={{ marginRight: 20, flex: 1 }}>
                        <Field
                          label="School Year Begins"
                          as={DatePicker}
                          value={moment(values.field_rewind_begin)}
                          onChange={(event: any) => {
                            setFieldValue(
                              "field_rewind_begin",
                              moment(event).format()
                            )
                          }}
                          name="field_rewind_begin"
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Field
                          label="School Year Ends"
                          as={DatePicker}
                          value={moment(values.field_rewind_end)}
                          onChange={(event: any) => {
                            setFieldValue(
                              "field_rewind_end",
                              moment(event).format()
                            )
                          }}
                          name="field_rewind_end"
                        />
                      </div>
                    </div>
                  </FormControl>

                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img
                        src={ClockIcon}
                        alt="School Hours"
                        style={styles.headerIcon}
                      />
                      School Hours
                    </header>
                    <p style={styles.helperText}>
                      Used for the Time of Day filter on Leaderboards.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <div style={{ marginRight: 20, flex: 1 }}>
                        <Field
                          label="School Hours Begin"
                          as={TimePicker}
                          value={moment(values.field_open_time)}
                          onChange={(event: any) => {
                            setFieldValue(
                              "field_open_time",
                              moment(event).format()
                            )
                          }}
                          name="field_open_time"
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <Field
                          label="School Hours End"
                          component={TimePicker}
                          value={moment(values.field_close_time)}
                          onChange={(event: any) => {
                            setFieldValue(
                              "field_close_time",
                              moment(event).format()
                            )
                          }}
                          name="field_close_time"
                        />
                      </div>
                    </div>
                  </FormControl>

                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img
                        src={TimeZoneIcon}
                        alt="Time Zone"
                        style={styles.headerIcon}
                      />
                      Time Zone
                    </header>
                    <p style={styles.helperText}>
                      This will be the default time zone for{" "}
                      <strong>any new users</strong> added to your organization.
                      Users can always change their specific time zone later.
                    </p>

                    <div style={{ maxWidth: 600 }}>
                      <Field
                        as={Select}
                        value={values.field_org_time_zone}
                        name="field_org_time_zone"
                        labelId="label-text-field_org_time_zone-select"
                        id="text-field_org_time_zone-select"
                      >
                        <MenuItem value={"America/New_York"}>
                          Eastern Standard Time
                        </MenuItem>
                        <MenuItem value={"America/Chicago"}>
                          Central Standard Time
                        </MenuItem>
                        <MenuItem value={"America/Denver"}>
                          Mountain Standard Time
                        </MenuItem>
                        <MenuItem value={"America/Los_Angeles"}>
                          Pacific Standard Time
                        </MenuItem>
                        <MenuItem value={"America/Anchorage"}>
                          Alaska Standard Time
                        </MenuItem>
                      </Field>
                    </div>
                  </FormControl>

                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img
                        src={LeaderboardIcon}
                        alt="Leaderboards"
                        style={styles.headerIcon}
                      />
                      Leaderboard Visibility
                    </header>
                    <p style={styles.helperText}>
                      Determines the <strong>other individuals</strong> that
                      learners can see from the Learn leaderboards. Choose from
                      all individuals in the organization, only those in the
                      learner's groups, or none at all (leaderboards are turned
                      off).
                    </p>

                    <div style={{ maxWidth: 500 }}>
                      <Field
                        as={Select}
                        value={values.field_teacher_leaderboard_visibi}
                        name="field_teacher_leaderboard_visibi"
                        labelId="label-text-field_teacher_leaderboard_visibi-select"
                        id="text-field_teacher_leaderboard_visibi-select"
                      >
                        <MenuItem value={"org"}>
                          All Individuals in Organization
                        </MenuItem>
                        <MenuItem value={"group"}>
                          Only Individals in Learner's Groups
                        </MenuItem>
                        <MenuItem value={"none"}>
                          None (Hide Leaderboard)
                        </MenuItem>
                      </Field>
                    </div>
                  </FormControl>

                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img
                        src={ObservationIcon}
                        alt="Observation"
                        style={styles.headerIcon}
                      />
                      Observation Admin Role
                    </header>
                    <p style={styles.helperText}>
                      If turned off, no users will be able to receive the{" "}
                      <strong>Organization Observation Admin</strong> role
                      moving forward. This role is capable of viewing all user
                      rubrics in the organization.
                    </p>

                    <div style={{ maxWidth: 350, marginBottom: -15 }}>
                      <SettingSwitch
                        checked={!values.field_disable_observation}
                        label="Enable Org Observation Admin Role"
                        onChange={() => {
                          setFieldValue(
                            "field_disable_observation",
                            !values.field_disable_observation
                          )
                        }}
                      />
                    </div>
                  </FormControl>

                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img src={Mail} alt="Mail" style={styles.headerIcon} />
                      Welcome Email
                    </header>
                    <p style={styles.helperText}>
                      Customize the message sent to welcome new members of your
                      organization.{" "}
                      <strong style={{ color: "rgb(237, 108, 2)" }}>
                        An email Body and Subject are required.
                      </strong>
                      <br />
                      <a
                        style={{ fontWeight: "bold" }}
                        target="_blank"
                        rel="noreferrer"
                        href="https://knowledge.mobilemind.io/how-should-i-introduce-our-faculty-and-staff-to-mobilemind"
                      >
                        Click here to view sample emails.
                      </a>
                    </p>

                    <Stack
                      sx={{ alignItems: "left" }}
                      direction="column"
                      spacing={2}
                    >
                      <div style={{ maxWidth: 350, marginBottom: -15 }}>
                        <SettingSwitch
                          checked={values.field_custom_mail}
                          label="Enable Custom Welcome Email"
                          onChange={(checked) => {
                            setFieldValue("field_custom_mail", checked)
                            setIsCustomEmail(checked)
                          }}
                        />
                      </div>
                      {isCustomEmail && (
                        <Field
                          disabled={!isCustomEmail}
                          as={TextField}
                          multiline={false}
                          sx={{
                            width: "100%",
                          }}
                          value={values.field_email_subject}
                          name="field_email_subject"
                          labelId="label-text-field_email_subject-text"
                          id="text-field_email_subject-text"
                          label="Subject"
                        />
                      )}
                      {isCustomEmail && (
                        <ReactQuill
                          theme={"snow"}
                          readOnly={!isCustomEmail}
                          onChange={(value) => {
                            setFieldValue("field_email_body", value)
                          }}
                          value={
                            values.field_email_body
                              ? values.field_email_body
                              : ""
                          }
                          modules={modules}
                          formats={formats}
                          placeholder={
                            "Email body supports bold, italics and hyperlinks."
                          }
                        />
                      )}
                    </Stack>
                  </FormControl>

                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img
                        src={KB4Icon}
                        alt="KnowB4"
                        style={styles.headerIcon}
                      />
                      KnowBe4 API Key
                    </header>
                    <p style={styles.helperText}>
                      Necessary to enable the <strong>KnowBe4 Campaign</strong>{" "}
                      progress tracking report.
                    </p>

                    <Stack
                      sx={{ alignItems: "center" }}
                      direction="row"
                      spacing={2}
                    >
                      <Field
                        disabled={!isMissingKB4}
                        as={TextField}
                        multiline={isMissingKB4}
                        sx={{
                          width: isMissingKB4 ? "100%" : 200,
                        }}
                        value={
                          isMissingKB4
                            ? values.field_knowbe4_api_key
                            : "XXXXXXXXXX" +
                              values.field_knowbe4_api_key.slice(-4)
                        }
                        name="field_knowbe4_api_key"
                        labelId="label-text-field_knowbe4_api_key-select"
                        id="text-field_knowbe4_api_key-select"
                      />

                      {!isMissingKB4 && (
                        <Button
                          size="small"
                          onClick={() => {
                            setIsSaving(true)

                            dispatch(removeKB4APIKey()).then(() => {
                              setTimeout(() => {
                                window.location.reload()
                              }, 750)
                            })
                          }}
                        >
                          Remove Key
                        </Button>
                      )}
                    </Stack>
                  </FormControl>

                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img
                        src={IconLogo}
                        alt="Logo"
                        style={styles.headerIcon}
                      />
                      Organization Logo
                    </header>
                    <p style={styles.helperText}>
                      This image may appear on <strong>Event</strong> and{" "}
                      <strong>Learning Path</strong> certificates.
                    </p>

                    <ImageUpload
                      useFormik={true}
                      shape={"rectangle"}
                      activeImage={values.field_logo_path}
                      setImage={(image: string) => {
                        setFieldValue("field_logo_path", image)
                      }}
                      removeImage={() => {
                        setFieldValue("field_logo_path", null)
                      }}
                    />
                  </FormControl>

                  <FormControl margin="normal">
                    <header style={styles.header}>
                      <img
                        src={IconCertificate}
                        alt="Certificate"
                        style={styles.headerIcon}
                      />
                      Certificate Signature
                    </header>
                    <p style={styles.helperText}>
                      This signature may appear on <strong>Event</strong> and{" "}
                      <strong>Learning Path</strong> certificates.
                    </p>

                    <strong>Name</strong>
                    <Box sx={{ marginBottom: 4, marginTop: 2 }}>
                      <Field
                        as={TextField}
                        sx={{
                          width: 350,
                        }}
                        value={values.field_signature_name}
                        name="field_signature_name"
                      />
                    </Box>

                    <strong>Title</strong>
                    <Box sx={{ marginBottom: 4, marginTop: 2 }}>
                      <Field
                        as={TextField}
                        sx={{
                          width: 350,
                        }}
                        value={values.field_signature_title}
                        name="field_signature_title"
                      />
                    </Box>

                    <strong>Style</strong>

                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        marginTop: 2,
                      }}
                    >
                      <div className="active-signature">
                        <strong
                          className={classNames(
                            "signature-text",
                            values.field_signature_font
                          )}
                        >
                          {values.field_signature_name.length > 0
                            ? values.field_signature_name
                            : "Signature Name"}
                        </strong>
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: 10,
                            display: "block",
                            marginTop: 3,
                          }}
                        >
                          {values.field_signature_title.length > 0
                            ? values.field_signature_title
                            : "Signature Title"}
                        </span>
                      </div>

                      <div style={{ marginLeft: 35 }}>
                        <ButtonSmall
                          onClick={() => {
                            setIsSignatureFontModalOpen(true)
                          }}
                        >
                          <span className="icon quill-pen" />
                          Choose New Style
                        </ButtonSmall>
                      </div>
                    </Box>
                  </FormControl>

                  <SignatureFontSelectModal
                    field_signature_font={values.field_signature_font}
                    field_signature_title={values.field_signature_title}
                    field_signature_name={values.field_signature_name}
                    open={isSignatureFontModalOpen}
                    onClose={(font: string) => {
                      setIsSignatureFontModalOpen(false)

                      if (font && typeof font === "string") {
                        setFieldValue("field_signature_font", font)
                      }
                    }}
                  />

                  <footer
                    style={{
                      opacity: disableSave ? 0.5 : 1,
                      pointerEvents: disableSave ? "none" : "all",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <ButtonLarge onClick={submitForm}>
                      Save Settings
                    </ButtonLarge>
                  </footer>
                </div>
              }
            </Card>
          )
        }}
      </Formik>
    </div>
  )
}

const styles = {
  header: {
    fontSize: 18,
    fontWeight: "bold",
    display: "flex",
    marginTop: 10,
    alignItems: "center",
  },
  headerIcon: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  helperText: {
    marginTop: 8,
    opacity: 0.75,
    fontSize: 14,
  },
}
