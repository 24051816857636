import { Box, Dialog } from "@mui/material"
import { Card, Button } from "@mui/material"
import classNames from "classnames"
import "../styles/modal.scss"
import "../styles/widget.scss"

import Rating from "./Rating"
import CategoryIcon from "./CategoryIcon"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

import MandatedTrainingIcon from "../img/mandatory.svg"

function ModalAnnouncementPreview(props) {
  const {
    onClose,
    open,
    label,
    featuredCourse,
    featuredLearningPath,
    announcement,
  } = props

  let isTip = label === "Tip of the Day"
  let isFeaturedCourse = label === "Featured Course"
  let isFeaturedLearningPath = label === "Featured Learning Path"
  let isFeaturedTraining = label === "Mandated Training"

  let imageSrc
  if (announcement.image) {
    if (announcement.image.attributes) {
      imageSrc =
        process.env.REACT_APP_API_URL + announcement.image.attributes.uri.url
    } else {
      imageSrc = announcement.image
    }
  }

  let categoryLabel
  if (isFeaturedCourse) {
    categoryLabel =
      featuredCourse &&
      featuredCourse.category &&
      featuredCourse.category.attributes.name.toLowerCase()
  }

  return (
    <Dialog
      className="modal announcement-preview"
      onClose={() => onClose()}
      open={open}
    >
      <p>
        Here's how this announcement will appear on the{" "}
        <strong>Dashboard</strong> of the <strong>Learn</strong> app.
      </p>
      <>
        {!isFeaturedCourse &&
          !isFeaturedLearningPath &&
          !isFeaturedTraining && (
            <Card className={classNames("widget announcement")}>
              {label && (
                <div className={classNames("widgetLabel", label.toLowerCase())}>
                  {label}
                </div>
              )}
              <div className="timestamp">Posted Today</div>
              {!isTip && !isFeaturedCourse && <h2>{announcement.name}</h2>}
              {imageSrc && (
                <div className="announcementImage">
                  <img src={imageSrc} alt={announcement.name} />
                </div>
              )}
              {isTip && (
                <div className="flexRow">
                  {!imageSrc && <div className="icon tip" />}
                  {announcement.body && <p>{announcement.body}</p>}
                </div>
              )}
              {!isTip && announcement.body && (
                <SanitizedHTML html={announcement.body} />
              )}
            </Card>
          )}
        {isFeaturedCourse && featuredCourse && (
          <Card className={classNames("widget announcement featuredCourse")}>
            {label && (
              <div className={classNames("widgetLabel", label.toLowerCase())}>
                {label}
              </div>
            )}
            <div className="timestamp">Posted Today</div>
            <div className="card-image" data-bg={categoryLabel}>
              <header>
                <CategoryIcon category={featuredCourse.category} />
                <div className="course-label">
                  <span className="category-label">{categoryLabel}</span>
                  <div className="course-level">
                    <Rating
                      level={featuredCourse.attributes.field_level.toLowerCase()}
                    />
                  </div>
                </div>
              </header>
              <h2>{featuredCourse.attributes.name}</h2>
            </div>
            <p>{featuredCourse.attributes.field_course_objective}</p>
            <footer>
              <Button className="button small">View Course</Button>
            </footer>
          </Card>
        )}
        {isFeaturedLearningPath &&
          featuredLearningPath &&
          featuredLearningPath.field_lp_image_uri && (
            <Card
              className={classNames("widget announcement featuredLearningPath")}
            >
              {label && (
                <div className={classNames("widgetLabel", label.toLowerCase())}>
                  {label}
                </div>
              )}
              <div className="timestamp">Posted Today</div>
              <div
                className="card-image"
                style={{
                  backgroundImage:
                    "url(" + featuredLearningPath.field_lp_image_uri + ")",
                }}
              />
              <h2>{featuredLearningPath.name}</h2>
              <SanitizedHTML html={announcement.body} />

              <footer style={{ marginTop: 0, paddingBottom: 0 }}>
                <Button className="button small">View Learning Path</Button>
              </footer>
            </Card>
          )}

        {isFeaturedTraining && announcement.selectedTraining && (
          <Card
            className={classNames("widget announcement featuredLearningPath")}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignitems: "center",
                position: "relative",
                zIndex: 4,
              }}
            >
              {label && (
                <div className={classNames("widgetLabel", label.toLowerCase())}>
                  {label}
                </div>
              )}

              <Box
                style={{
                  backgroundColor: "white",
                  padding: 5,
                  borderRadius: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.42)",
                }}
              >
                <img
                  src={MandatedTrainingIcon}
                  alt="Mandated Training"
                  style={{ width: 20, height: 20 }}
                />
              </Box>
            </Box>

            <div
              className="card-image"
              style={{
                marginTop: -65,
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
                // We don't have the MT image here
                // backgroundImage:  "url(" + featuredLearningPath.field_lp_image_uri + ")",
              }}
            >
              <h2 style={{ marginTop: 50, fontWeight: "bold" }}>
                {announcement.selectedTraining.attributes?.title ??
                  announcement.selectedTraining.name}
              </h2>
            </div>
            <SanitizedHTML html={announcement.body} />

            <footer style={{ marginTop: 0, paddingBottom: 0 }}>
              <Button className="button small">View Training</Button>
            </footer>
          </Card>
        )}
      </>
    </Dialog>
  )
}

export default ModalAnnouncementPreview
