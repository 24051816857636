import { Grid, Card, CardContent } from "@mui/material"
import classNames from "classnames"
import he from "he"
import moment from "moment"
import "../styles/review.scss"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import { useAppSelector } from "store/hooks"

function ReviewCard(props) {
  const categories = useAppSelector((state) => state.categories.data)

  const {
    changed,
    field_first_name,
    field_last_name,
    field_subgroup,
    category_uuid,
    course_title,
    field_category,
    user_picture,
    field_status,
  } = props.assessment

  const fullCategory = categories.find(
    (category) => category.id === category_uuid
  )

  if (course_title) {
    return (
      <>
        <Grid item xs={3}>
          <Card
            onClick={() => {
              props.onClick()
            }}
            variant={"elevation"}
            className={classNames(
              "card small card-assessment",
              props.currentTab === "reviewed" && "reviewed-by-me-card",
              props.disabled && "grading-in-progress"
            )}
          >
            {props.disabled && (
              <div className="in-progress-indicator">
                <span>Grading in Progress</span>
              </div>
            )}

            <div
              className="card-image"
              style={{
                backgroundColor: fullCategory?.attributes?.field_hex_color,
              }}
            />
            <CardContent>
              <header>
                <div className="category-icon-wrapper">
                  <span
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_API_URL +
                        fullCategory?.image?.attributes.uri.url
                      })`,
                    }}
                    className={classNames("category-icon")}
                  />
                </div>
                <div className="course-label">
                  <span className="category-label">
                    {field_category ? field_category.toLowerCase() : "Other"}
                  </span>
                  {props.currentTab === "reviewed" ? (
                    <>
                      {field_status === "InProgress" && (
                        <span className="icon status laptop" />
                      )}
                      {field_status === "Completed" && (
                        <span className="icon status check" />
                      )}
                    </>
                  ) : (
                    <span className="submitted-date">
                      {moment(changed).format("M/D/YYYY")}
                    </span>
                  )}
                </div>
              </header>
              <h2>{he.decode(course_title)}</h2>
            </CardContent>
            <footer>
              <div className="inner">
                <UserLabel
                  isReviewCard={true}
                  firstName={field_first_name}
                  lastName={field_last_name}
                  subgroups={field_subgroup}
                  imagePath={process.env.REACT_APP_API_URL + user_picture}
                />
              </div>
            </footer>
          </Card>
        </Grid>
      </>
    )
  }

  return null
}

export default ReviewCard
