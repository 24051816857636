import { Box, Card, Stack, Typography } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"

import { useAppSelector } from "../../store/hooks"
import { FeatureName } from "store/reducers/session"

import GenieIcon from "../../img/genie.svg"
import CalendarIcon from "../../img/calendar-color.svg"
import ObservationIcon from "../../img/observation-color.svg"
import LaptopIcon from "../../img/laptop.svg"
import BadgeIcon from "../../img/i-badge.svg"
import { Link } from "react-router-dom"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"

const WidgetFeatureUpsell = () => {
  const { enabledAddOns } = useAppSelector((state) => state.session)
  const iconSize = 30
  const featureNameSize = 14
  return (
    <>
      <Card className="dashboard-widget widget-support">
        <header style={{ backgroundColor: theme.palette.secondary.main }}>
          Get More From MobileMind
        </header>
        <div className="inner" style={{ padding: 20 }}>
          <Typography sx={{ marginBottom: 5, fontSize: 14 }}>
            <Link
              style={{
                fontWeight: "bold",
                color: "rgb(89, 195, 229)",
              }}
              to="/upgrade"
            >
              Contact us
            </Link>{" "}
            to find out how you can unlock these features with an upgrade.
          </Typography>
          <Stack spacing={4}>
            {!enabledAddOns.includes(FeatureName.OnDemand) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  fontSize: featureNameSize,
                }}
              >
                <img
                  src={LaptopIcon}
                  alt="On Demand"
                  width={iconSize}
                  style={{ marginRight: 10 }}
                />
                <strong>On Demand PD</strong>
              </Box>
            )}

            {!enabledAddOns.includes(FeatureName.Genie) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  fontSize: featureNameSize,
                }}
              >
                <img
                  src={GenieIcon}
                  alt="AI Genie"
                  width={iconSize}
                  style={{ marginRight: 10 }}
                />
                <strong>AI Genie</strong>
              </Box>
            )}

            {!enabledAddOns.includes(FeatureName.Events) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  fontSize: featureNameSize,
                }}
              >
                <img
                  src={CalendarIcon}
                  alt="Events"
                  width={iconSize}
                  style={{ marginRight: 10 }}
                />
                <strong>Events</strong>
              </Box>
            )}

            {!enabledAddOns.includes(FeatureName.Observations) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  fontSize: featureNameSize,
                }}
              >
                <img
                  src={ObservationIcon}
                  alt="Events"
                  width={iconSize}
                  style={{ marginRight: 10 }}
                />
                <strong>Observations</strong>
              </Box>
            )}

            {!enabledAddOns.includes(FeatureName.Badges) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  fontSize: featureNameSize,
                }}
              >
                <img
                  src={BadgeIcon}
                  alt="Badges"
                  width={iconSize}
                  style={{ marginRight: 10 }}
                />
                <strong>Badges</strong>
              </Box>
            )}
          </Stack>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonLarge style={{ marginTop: 20 }}>
              <Link
                style={{
                  color: "black",
                }}
                to="/upgrade"
              >
                Contact Us
              </Link>
            </ButtonLarge>
          </Box>
        </div>
      </Card>
    </>
  )
}

export default WidgetFeatureUpsell
