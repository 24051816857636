import { SubmissionType } from "@mobilemind/common/src/types/course"
import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { Field, useFormikContext } from "formik"
import "react-quill/dist/quill.snow.css"
import { useAppSelector } from "store/hooks"
import "../../../styles/create.scss"
import { CourseFormValues } from "../types"

export const CourseSubmissionTypeSelect = (props: FormControlProps) => {
  const session = useAppSelector((state) => state.session)
  const { values, setFieldValue } = useFormikContext<CourseFormValues>()

  const canViewManualSubmissions =
    !session.isPartner ||
    (session.group.field_manual_course_submissions &&
      session.group.field_manual_course_submissions[0]?.value)

  return (
    <FormControl variant="standard" {...props}>
      <InputLabel htmlFor="label-submission-type-select">
        Submission Type
      </InputLabel>
      <Field
        as={Select}
        label="Submission Type"
        id="label-submission-type-select"
        name="submissionType"
      >
        <MenuItem value={SubmissionType.MultipleChoice}>
          Multiple Choice (Fully Automated)
        </MenuItem>
        {canViewManualSubmissions && (
          <MenuItem value={SubmissionType.Text}>
            Text (Automation Available)
          </MenuItem>
        )}

        {canViewManualSubmissions && (
          <MenuItem value={SubmissionType.Url}>
            URL (Manual Review Required)
          </MenuItem>
        )}
        {canViewManualSubmissions && (
          <MenuItem value={SubmissionType.Image}>
            Image (Manual Review Required)
          </MenuItem>
        )}
        {canViewManualSubmissions && (
          <MenuItem
            onClick={() => {
              setFieldValue("mobileFriendly", false)
            }}
            value={SubmissionType.File}
          >
            File Upload (Manual Review Required)
          </MenuItem>
        )}
        {!session.isPartner && (
          <MenuItem value={SubmissionType.Checkbox}>
            Poll (Credit Awarded Upon Completion)
          </MenuItem>
        )}
        <MenuItem value={SubmissionType.Quiz}>Quiz (Fully Automated)</MenuItem>
      </Field>

      {values.submissionType === SubmissionType.File && (
        <Typography
          sx={{
            opacity: 0.6,
            fontSize: 12,
          }}
        >
          png, jpg, jpeg, doc, docx, ppt, pptx, xlsx, xlx, csv, txt, pdf
        </Typography>
      )}
    </FormControl>
  )
}
