import { SubmissionType } from "@mobilemind/common/src/types/course"
import {
  GenerateCourseParameters,
  GenieAdditionalResource,
  GenieCategory,
  GenieCourseData,
  GenieCourseFeedbackParameters,
  GenieCourseQuestion,
  GenieRewriteAllowedField,
  UpdateCourseParameters,
  YouTubeVideo,
} from "@mobilemind/genie-api/src/types"
import {
  Draft,
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import { openSnackbar } from "features/snackbar/snackbarSlice"
import { isArray } from "lodash"
import moment from "moment"
import { getCategories } from "store/reducers/categories"
import { AppDispatch, RootState } from "store/types"
import { genieApi } from "../../genie/common/genieApi"
import { CourseFormValues, QuizQuestion, QuizQuestionAnswer } from "../types"
import { sanitizeUrl } from "@braintree/sanitize-url"
import sanitizeHtml from "sanitize-html"

export const getYouTubeTranscript =
  (videoId: string) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState
  ): Promise<string | null> => {
    const {
      session: { user },
    } = getState()
    if (user.attributes.mail.match(/^youtube(.*)@example.com$/i)) {
      return null
    }
    return await genieApi
      .get(`/video/${videoId}/transcript`)
      .then((response) => response.data)
  }

export const GENERATE_COURSE_NUM_TICKS = 6

export const generateCourse = createAsyncThunk<
  void,
  {
    video: YouTubeVideo
    draft: Draft<CourseFormValues>
    commit: (values: Draft<CourseFormValues>) => void
  },
  { dispatch: AppDispatch; state: RootState }
>("genie/generateCourse", async (args, { dispatch, getState }) => {
  const {
    session: { user },
  } = getState()

  const { video, draft, commit } = args

  const numQuestions = await calculateNumQuestions(draft, video.duration)
  dispatch(initProgress(GENERATE_COURSE_NUM_TICKS + numQuestions))

  dispatch(updateProgress("Creating course…"))

  const transcript = await dispatch(getYouTubeTranscript(video.id))
  const course = await genieApi
    .post<
      GenieCourseData,
      AxiosResponse<GenieCourseData>,
      GenerateCourseParameters
    >(`/course`, {
      userId: user.id,
      videoId: video.id,
      level: draft.level,
      transcript,
    })
    .then((response) => response.data)

  if (course["Course Name"]) {
    // @todo split the text at a word boundary, instead. Could use regex?
    draft.name = course["Course Name"].slice(0, 50)
  }
  if (course["Learn"]) {
    draft.objective = course["Learn"]
  }
  if (isArray(course["Tags"])) {
    draft.tags = course["Tags"].map((item) => {
      return {
        id: item,
        text: item,
      }
    })
  }
  commit(draft)

  dispatch(updateProgress("Assigning category…"))
  const category = await dispatch(generateCategory())
  if (category) {
    draft.field_category = category
    commit(draft)
  }

  // get updated values back from updateCourseChallenge
  await dispatch(updateCourseChallenge({ video, draft, commit })).unwrap()

  dispatch(updateProgress("Generating course instructions…"))
  const apply = await dispatch(generateApply(draft.submissionType))
  draft.content = apply
  commit(draft)

  dispatch(updateProgress("Generating course instructions…"))
  const learnerInstructions = await dispatch(
    generateLearnerInstructions(draft.submissionType)
  )
  draft.learnerInstructions = learnerInstructions
  commit(draft)

  dispatch(updateProgress("Getting materials..."))
  const additionalResources = await dispatch(generateAdditionalResources())
  draft.resources = additionalResources
    ?.map((resource) => {
      // Sanitize the title and href
      const title = sanitizeHtml(resource.Title)
      const href = sanitizeUrl(resource.URL)
      return `<p><a href="${href}">${title}</a></p>`
    })
    .join("\n")
  commit(draft)

  dispatch(updateProgress("Calculating estimated time…"))
  draft.estimatedTime = calculateEstimatedTime(draft, video.duration)
  commit(draft)

  dispatch(resetProgress())
})

export const resumeCourse = createAsyncThunk<
  void,
  { values: CourseFormValues; video: YouTubeVideo },
  { dispatch: AppDispatch; state: RootState }
>("genie/resumeCourse", async ({ values, video }, { dispatch, getState }) => {
  const {
    session: { user },
  } = getState()

  const transcript = await dispatch(getYouTubeTranscript(video.id))
  // @todo not even sure we need to provide this.
  const course: GenieCourseData = {
    "Course Name": values.name,
    Category: values.field_category?.attributes.name,
    Learn: values.objective,
  }

  const data: UpdateCourseParameters = {
    userId: user.id,
    course,
    videoId: video.id,
    level: values.level,
    transcript,
  }
  // Seed the course data in the Genie API for future requests.
  // This doesn't immediately result in generative AI nor does it return a value,
  // it only initializes the session for future AI requests.
  await genieApi.put("/course", data)
})

export const generateCategory = () => async (dispatch: AppDispatch) => {
  const allCategories = await dispatch(getCategories())
    .unwrap()
    .then((categories) => categories?.flat())

  const allCategoryNames = allCategories?.map(
    (category) => category.attributes.name
  )

  const categoryName = await genieApi
    .post<GenieCategory>(`/course/category`, {
      categories: allCategoryNames,
    })
    .then((response) => response.data)

  const category = allCategories?.find(
    (category) => category.attributes.name === categoryName
  )

  return category
}

export const generateApply = (submissionType: SubmissionType) => async () => {
  return await genieApi
    .post<string>(`/course/apply`, { submissionType })
    .then((response) => response.data)
}

export const generateLearnerInstructions =
  (submissionType: SubmissionType) => async () => {
    return await genieApi
      .post(`/course/learner-instructions`, { submissionType })
      .then((response) => response.data)
  }

export const generateAdditionalResources = () => async () => {
  return await genieApi
    .post<GenieAdditionalResource[]>(`/course/additional-resources`)
    .then((response) => response.data)
}

export const calculateNumQuestions = async (
  values: CourseFormValues,
  videoDuration: string
) => {
  switch (values.submissionType) {
    case SubmissionType.Quiz: {
      const duration = moment.duration(videoDuration).as("seconds")
      if (duration <= 120) {
        return 2
      } else if (duration <= 180) {
        return 3
      } else if (duration <= 300) {
        return 4
      } else {
        return 5
      }
    }
    default: {
      return 1
    }
  }
}

export const calculateEstimatedTime = (
  values: CourseFormValues,
  videoDuration: string
): number => {
  // Calculate estimated time
  let estimatedTime = moment.duration(videoDuration)
  const explorePattern = /\b(explore|exploring|browse)\b/i
  const createPattern = /\b(create|build)\b/i

  // If one of these words is in the Apply or Learner Instructions section, add 5 minutes: explore, exploring, browse
  if (
    explorePattern.test(values.content) ||
    explorePattern.test(values.learnerInstructions)
  ) {
    estimatedTime.add(5, "minutes")
  }

  // If the word “create” or “build” is in the Apply or Learner Instructions section, add 5 minutes.
  if (
    createPattern.test(values.content) ||
    createPattern.test(values.learnerInstructions)
  ) {
    estimatedTime.add(5, "minutes")
  }

  // Add time based on the submission type
  switch (values.submissionType) {
    // URL/Image - add 5 minutes
    case "URL":
    case "Image": {
      estimatedTime.add(5, "minutes")
      break
    }
    // MC/Poll - 1 minute
    case "Multiple Choice":
    case "Checkbox": {
      estimatedTime.add(1, "minutes")
      break
    }
    // Text - 3 minutes
    case "Text": {
      estimatedTime.add(3, "minutes")
      break
    }
    // Quiz - 30 seconds per question
    case "Quiz": {
      estimatedTime.add(30 * values.quizQuestions.length, "seconds")
    }
  }
  return Math.round(estimatedTime.asMinutes())
}

const getUpdateMessage = (submissionType: SubmissionType): string => {
  switch (submissionType) {
    case SubmissionType.MultipleChoice: {
      return "Creating multiple choice question…"
    }
    case SubmissionType.Text: {
      return "Creating automated text question…"
    }
    case SubmissionType.Url: {
      return "Creating URL question…"
    }
    case SubmissionType.Image: {
      return "Creating image question…"
    }
    case SubmissionType.File: {
      return "Creating file upload question…"
    }
    case SubmissionType.Checkbox: {
      return "Creating poll…"
    }
    case SubmissionType.Quiz: {
      return "Creating quiz…"
    }
  }
}

export const updateCourseChallenge = createAsyncThunk<
  void,
  {
    video: YouTubeVideo
    draft: Draft<CourseFormValues>
    commit: (draft: Draft<CourseFormValues>) => void
  }
>(
  "courseGenie/updateCourseChallenge",
  async ({ video, draft, commit }, { dispatch }) => {
    const numQuestions = await calculateNumQuestions(draft, video.duration)

    // Clear existing question values
    // @todo should this happen in non-genie mode, too?
    // @todo maybe reuse `defaultValues`

    draft.question = ""
    draft.checkboxOptions = []
    draft.answers = []
    draft.quizQuestions = []

    if (
      draft.submissionType === SubmissionType.Text ||
      draft.submissionType === SubmissionType.Image ||
      draft.submissionType === SubmissionType.File
    ) {
      draft.reviewMethod = "manual"
      draft.exactMatch = ""
    }

    commit(draft)

    const { submissionType } = draft
    if (submissionType === SubmissionType.Quiz) {
      draft.quizQuestions = []
      commit(draft)
      for (let i = 0; i < numQuestions; i++) {
        dispatch(updateProgress("Creating quiz…"))
        const quizQuestion = await dispatch(generateQuizQuestion()).unwrap()
        draft.quizQuestions.push(quizQuestion)
        commit(draft)
      }
    } else {
      const message = getUpdateMessage(submissionType)
      dispatch(updateProgress(message))
      const response = await dispatch(generateQuestion(submissionType)).unwrap()
      Object.assign(draft, response)
      commit(draft)
    }
  }
)

export const generateQuestion = createAsyncThunk<
  Partial<CourseFormValues>,
  Exclude<SubmissionType, SubmissionType.Quiz>
>("courseGenie/generateQuestion", async (submissionType) => {
  const response = await genieApi
    .post<GenieCourseQuestion>("/course/question", {
      submissionType,
    })
    .then((response) => response.data)
  switch (submissionType) {
    case SubmissionType.Checkbox: {
      return {
        question: response["Question"] ?? "",
        checkboxOptions: response["Options"] ?? [],
      }
    }
    case SubmissionType.Image: {
      return {
        question: response["Question"],
      }
    }
    case SubmissionType.MultipleChoice: {
      return {
        question: response["Question"] ?? "",
        answers:
          response["Options"]?.map((option) => ({
            type: "paragraph--multiple_choice",
            attributes: {
              field_mc_answer: option,
              field_mc_answer_type:
                response["Correct Answer"] === option ? "correct" : "incorrect",
            },
          })) ?? [],
      }
    }
    case SubmissionType.Text: {
      return {
        question: response["Question"] ?? "",
        reviewMethod: "manual",
        exactMatch: "",
      }
    }
    case SubmissionType.File: {
      return {
        question: response["Question"] ?? "",
        reviewMethod: "manual",
        exactMatch: "",
      }
    }

    case SubmissionType.Url: {
      return {
        question: response["Question"],
      }
    }
  }
})

export const generateQuizQuestion = createAsyncThunk<QuizQuestion, void>(
  "courseGenie/generateQuizQuestion",
  async () => {
    const response = await genieApi
      .post<GenieCourseQuestion>("/course/question", {
        submissionType: SubmissionType.Quiz,
      })
      .then((response) => response.data)
    const answers: QuizQuestionAnswer[] =
      response["Options"]?.map((option) => {
        return {
          value: option ?? "",
          isCorrect: response["Correct Answer"] === option,
        }
      }) ?? []
    const quizQuestion: QuizQuestion = {
      value: response["Question"] ?? "",
      answers,
    }
    return quizQuestion
  }
)

export const rewriteField = createAsyncThunk<
  string,
  {
    field: GenieRewriteAllowedField
    content: string
    action: "rewrite" | "longer" | "shorter"
  }
>("courseGenie/rewriteField", async (args) => {
  return await genieApi
    .post("/course/rewrite", args)
    .then((response) => response.data)
})

export const saveFeedback = createAsyncThunk<
  void,
  GenieCourseFeedbackParameters
>("courseGenie/saveFeedback", async (args) => {
  return await genieApi
    .post("/course/feedback", args)
    .then((response) => response.data)
})

export const finishCourseGenie = createAsyncThunk<void, string>(
  "courseGenie/finishCourseGenie",
  async (courseId) => {
    return await genieApi
      .post("/course/finish", { courseId })
      .then((response) => response.data)
  }
)

export const showCourseGenieError = () => (dispatch: AppDispatch) => {
  dispatch(openSnackbar({ message: "There was an problem with the AI Genie." }))
}

export enum PopperType {
  Field = "Field",
  Helper = "Helper",
  Feedback = "Feedback",
}

type FieldPopperState = {
  type: PopperType.Field
  field: GenieRewriteAllowedField
  name: string
}

type HelperPopperState = {
  type: PopperType.Helper
}

type FeedbackPopperState = {
  type: PopperType.Feedback
}

type PopperState = FieldPopperState | HelperPopperState | FeedbackPopperState

type ProgressState = {
  message: string | null
  tick: number
  numTicks: number
}

type CourseGenieState = {
  active: boolean
  loading: boolean
  used: boolean
  progress: ProgressState | null
  popper: PopperState | null
  hasViewedHelper: boolean
}

const initialState: CourseGenieState = {
  active: false,
  loading: false,
  used: false,
  progress: null,
  popper: null,
  hasViewedHelper: false,
}

export const courseGenieSlice = createSlice({
  name: "courseGenie",
  initialState,
  reducers: {
    initProgress: (state, action: PayloadAction<number>) => {
      if (!state.progress) {
        state.progress = {
          message: null,
          tick: 0,
          numTicks: action.payload,
        }
      }
    },
    updateProgress: (state, action: PayloadAction<string>) => {
      if (state.progress) {
        state.progress.message = action.payload
        state.progress.tick++
      }
    },
    resetProgress: (state) => {
      state.progress = null
    },
    showPopper: (state, action: PayloadAction<PopperState | null>) => {
      state.popper = action.payload
      if (!action.payload) {
        state.hasViewedHelper = true
      }
    },
    activateCourseGenie: (state) => {
      state.active = true
    },
    resetCourseGenie: () => {
      return { ...initialState }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isPending(rewriteField, generateQuestion, generateQuizQuestion),
      (state) => {
        state.loading = true
      }
    )
    builder.addMatcher(
      isFulfilled(rewriteField, generateQuestion, generateQuizQuestion),
      (state) => {
        state.loading = false
      }
    )
    builder.addMatcher(
      isRejected(rewriteField, generateQuestion, generateQuizQuestion),
      (state) => {
        state.loading = false
      }
    )
    // Mark the course genie as "used" when one of these thunks is fulfilled.
    builder.addMatcher(
      isFulfilled(
        generateCourse,
        updateCourseChallenge,
        rewriteField,
        generateQuestion,
        generateQuizQuestion
      ),
      (state) => {
        state.used = true
      }
    )
  },
})

export const selectCourseGenieState = (state: RootState) => state.courseGenie

export const selectCourseGenieActive = (state: RootState) =>
  state.courseGenie.active

export const selectCourseGenieLoading = (state: RootState) =>
  state.courseGenie.loading

export const selectCourseGenieUsed = (state: RootState) =>
  state.courseGenie.used

export const selectCourseGenieProgress = (state: RootState) =>
  state.courseGenie.progress

export const selectCourseGeniePopper = (state: RootState) =>
  state.courseGenie.popper

export const {
  reducer: courseGenieReducer,
  actions: {
    activateCourseGenie,
    resetCourseGenie,
    initProgress,
    updateProgress,
    resetProgress,
    showPopper,
  },
} = courseGenieSlice
