import theme from "@mobilemind/common/src/theme/theme"
import "../../styles/signup.scss"

import SignUpStep1 from "./SignUpStep1"
import SignUpStep2 from "./SignUpStep2"
import { useEffect, useState } from "react"
import { useAppSelector } from "store/hooks"

export function SignUpLayout(props: any) {
  // Redirect any already logged in users away from this page
  const [hasRedirected, setHasRedirected] = useState(false)
  const user = useAppSelector((state) => state.session.user)

  useEffect(() => {
    if (user.id && process.env.REACT_APP_HQ_URL && !hasRedirected) {
      window.location.href = process.env.REACT_APP_HQ_URL
    }
    setHasRedirected(true)
  }, [hasRedirected, user.id])

  return (
    <div className="page login sign-up">
      <div
        className="backgroundWallpaper"
        style={{
          transition: "1s",
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          filter: "grayscale(1)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: "url(" + theme.mixins.images.hqBackground + ")",
        }}
      />

      <div
        style={{
          opacity: 0.85,
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          background:
            "linear-gradient(120deg, " +
            "black" +
            " 0%, " +
            "#eee" +
            " 70%, " +
            "#eee" +
            " 100%)",
        }}
        className="background"
      />
      {props?.match?.path === "/sign-up/organization" ? (
        <SignUpStep2 />
      ) : (
        <SignUpStep1 />
      )}
    </div>
  )
}

export default SignUpLayout
