import { Button, Dialog, Popover, TextField } from "@mui/material"
import classNames from "classnames"
import React, { useState } from "react"
import { updateAssignedCourseSearch } from "../features/review/reviewSlice"
import he from "he"
import profilePic from "../img/profile-pic.svg"

import "../styles/modal.scss"
import { useAppDispatch } from "../store/hooks"

function ModalAssignedCourses(props) {
  const { onClose, assignedCourses, open, remove, session } = props
  const [promptCourse, setPromptCourse] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { isOrgAdmin } = session
  const [activeCourseId, setActiveCourseId] = React.useState(null)

  const dispatch = useAppDispatch()
  const handleClose = () => {
    onClose()
    setPromptCourse(null)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setActiveCourseId(null)
  }

  const promptRemove = (course) => {
    setPromptCourse(course)
  }

  const removeReviewer = (course) => {
    // Remove the user from the course
    remove(course)
    setPromptCourse(null)
  }

  const handleClick = (event, courseId) => {
    setAnchorEl(event.currentTarget)
    setActiveCourseId(courseId)
  }
  const handleSearchChange = (event) => {
    dispatch(updateAssignedCourseSearch(event.target.value))
  }

  return (
    <Dialog
      className="modal assigned-course-modal"
      onClose={handleClose}
      open={open}
    >
      <h2 style={{ display: "flex", margin: "0 0 10px 0" }}>
        <div style={{ flex: 1 }}>
          {isOrgAdmin ? (
            <>Challenges by Course</>
          ) : (
            <>Manage Assigned Courses</>
          )}
        </div>

        <div className="searchWrapper">
          <div className="flexRow inputSearch">
            <TextField
              style={{ width: 300 }}
              variant="standard"
              label="Search Courses"
              value={assignedCourses.filters.search}
              onChange={(event) => {
                handleSearchChange(event)
              }}
            />
          </div>
        </div>
      </h2>
      {assignedCourses.data.length > 0 && (
        <ul className="assigned-course-list">
          <li>
            <div className="flexRow">
              <div className="title">Course</div>
              {isOrgAdmin && (
                <div className="reviewerCount" style={{ width: 128 }}>
                  Challenges Submitted
                </div>
              )}
              <div className="reviewerCount">Reviewers</div>
              <div className="created">Created By</div>
              <div className="action"></div>
            </div>
          </li>
          {assignedCourses.data.map((course, index) => {
            const popoverOpen =
              Boolean(anchorEl) &&
              (activeCourseId === course.course_uuid ||
                activeCourseId === course.uuid)

            let courseReviewers = course.field_reviewers
            let isGroupLevel
            if (course.field_reviewer_groups) {
              courseReviewers = courseReviewers.concat(
                course.field_reviewer_groups.split(",")
              )
            }

            if (!Array.isArray(courseReviewers)) {
              courseReviewers = course.field_reviewers.split(",")
              isGroupLevel = true
            }

            const courseAuthor = course.field_author ?? course.user_id

            return (
              <li
                key={index}
                className={classNames(
                  promptCourse &&
                    promptCourse.course_uuid !== course.course_uuid &&
                    "fade"
                )}
              >
                <div className="flexRow">
                  <div className="title">
                    <img
                      className="categoryImage"
                      alt={course.name}
                      src={
                        process.env.REACT_APP_API_URL +
                        course.field_category_image
                      }
                    />
                    {he.decode(course.name)}
                  </div>
                  {isOrgAdmin && (
                    <div className="reviewerCount">
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        {course.assessment_count}
                      </span>
                    </div>
                  )}

                  <div
                    className="reviewerCount"
                    style={{
                      opacity: courseReviewers.length > 0 ? 1 : 0,
                      pointerEvents:
                        courseReviewers.length > 0 ? "all" : "none",
                    }}
                  >
                    <Button
                      aria-describedby={course.course_id}
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        handleClick(event, course.course_uuid ?? course.uuid)
                      }}
                    >
                      <span className="icon review" />
                      {courseReviewers.length}
                    </Button>

                    <Popover
                      id={course.course_uuid}
                      open={popoverOpen}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      className={"reviewer-popover"}
                      style={{ marginTop: -13, overflow: "visible" }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <ul className="popover-list">
                        {courseReviewers.map((reviewer, index) => {
                          const isUser = reviewer.id
                          let {
                            user_picture,
                            field_first_name,
                            field_last_name,
                          } = reviewer
                          const fullUser = session.orgUsers.data.find(
                            (user) => {
                              return reviewer === user.uid
                            }
                          )

                          if (fullUser) {
                            user_picture = fullUser.user_picture
                            field_first_name = fullUser.field_first_name
                            field_last_name = fullUser.field_last_name
                          }

                          if (isUser || fullUser) {
                            return (
                              <li key={isUser}>
                                {!user_picture ||
                                (user_picture &&
                                  user_picture.includes("default_temp")) ? (
                                  <img
                                    className="userPicture icon"
                                    src={profilePic}
                                    alt={
                                      field_first_name + " " + field_last_name
                                    }
                                  />
                                ) : (
                                  <span
                                    className="userPicture icon"
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        process.env.REACT_APP_API_URL +
                                        user_picture +
                                        ")",
                                    }}
                                  />
                                )}
                                {field_first_name} - {field_last_name}
                              </li>
                            )
                          } else if (!isGroupLevel) {
                            return (
                              <li key={index}>
                                <span className="reviewer-group icon" />
                                {reviewer}
                              </li>
                            )
                          }
                          return null
                        })}
                      </ul>
                    </Popover>
                  </div>

                  <div className="created" style={{ marginRight: 10 }}>
                    {courseAuthor}
                  </div>
                  <div className="action">
                    {Array.isArray(courseReviewers) &&
                      courseReviewers.find((reviewer) => {
                        return (
                          (isOrgAdmin &&
                            Number(reviewer.id) ===
                              Number(
                                session.user.attributes.drupal_internal__uid
                              )) ||
                          (!isOrgAdmin &&
                            Number(reviewer) ===
                              Number(
                                session.user.attributes.drupal_internal__uid
                              ))
                        )
                      }) && (
                        <Button
                          onClick={() => {
                            promptRemove(course)
                          }}
                          className="button small"
                        >
                          Remove Me
                        </Button>
                      )}
                  </div>
                </div>
                {promptCourse &&
                  ((isOrgAdmin &&
                    promptCourse.course_uuid === course.course_uuid) ||
                    (!isOrgAdmin && promptCourse.uuid === course.uuid)) && (
                    <div className="prompt">
                      <em>
                        Are you sure you want to remove yourself as a reviewer
                        from this course?
                      </em>
                      <Button
                        onClick={() => {
                          setPromptCourse(null)
                        }}
                        style={{ marginRight: 20 }}
                        className="button small cancel"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          removeReviewer(course)
                        }}
                        className="button small"
                      >
                        Yes, Remove Me
                      </Button>
                    </div>
                  )}
              </li>
            )
          })}
        </ul>
      )}

      <footer style={{ marginTop: 0 }}>
        <Button onClick={handleClose} className="button small">
          Done
        </Button>
      </footer>
    </Dialog>
  )
}

export default ModalAssignedCourses
