import moment from "moment"
import { Component } from "react"
import { connect } from "react-redux"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import Snackbar from "./components/Snackbar"

import ProfileLayout from "./components/ProfileLayout"
import DashboardLayout from "./features/dashboard/DashboardLayout"
import ServiceLayout from "./features/serviceAccount/ServiceAccountLayout"

import Navbar from "./features/navbar/Navbar"

import ManageCategoriesModal from "./features/categories/ManageCategoriesModal"

import Unauthorized from "@mobilemind/common/src/components/Unauthorized"

import BackpackContainer from "./features/backpack/BackpackContainer"
import BackpackPrintLayout from "./features/backpack/BackpackPrintLayout"

import OrganizationSingle from "./features/organizations/OrganizationSingle"
import OrganizationList from "./features/organizations/OrganizationsList"
import ReviewContainer from "./features/review/ReviewContainer"

import BadgeStudio from "./features/createBadge/BadgeStudio"
import { fetchHQAnnouncements } from "./features/hqAnnouncements/hqAnnouncementsSlice"
import { getCategories } from "./store/reducers/categories"

import "./styles/badgeItem.scss"
import "./styles/badgeStudio.scss"
import "./styles/calendar.scss"
import "./styles/categories.scss"
import "./styles/certificate.scss"
import "./styles/courseCard.scss"
import "./styles/courseSelect.scss"
import "./styles/create.scss"
import "./styles/event-live-view.scss"
import "./styles/event-single.scss"
import "./styles/external-pd.scss"
import "./styles/index.scss"
import "./styles/modal.scss"
import "./styles/reports.scss"
import "./styles/review.scss"
import "./styles/sidebar.scss"
import "./styles/widget.scss"

import RubricLayout from "./features/rubrics/RubricLayout"

import classNames from "classnames"
import pluralize from "pluralize"
import { getOrgBadges } from "./actions/badges"
import { getOrgCourses } from "./actions/courses"
import { getOrgLearningPaths } from "./actions/learningPaths"
import SubmitTicket from "./components/SubmitTicket"
import ReviewerGroupsModal from "./features/review/ReviewerGroupsModal"
import { getOrgSuccessChecklist } from "./store/reducers/session"

import { CollectionSettingsLayout } from "features/collectionSettings/CollectionSettingsLayout"
import CollectionDashboardLayout from "features/dashboard/CollectionDashboardLayout"
import FeedbackFormsModal from "features/feedbackForms/FeedbackFormsModal"
import { PartnerMarketplaceLayout } from "features/marketplace/PartnerMarketplaceLayout"
import { OrgSettingsLayout } from "features/orgSettings/OrgSettingsLayout"
import { PartnerSettingsLayout } from "features/partnerSettings/PartnerSettingsLayout"

import ErrorBoundary from "components/ErrorBoundary"
import PreApprovalFormsModal from "features/preApprovalForms/PreApprovalFormsModal"
import { EventsSwitch } from "routes/EventsSwitch"
import { ReportsSwitch } from "routes/ReportsSwitch"
import { ContentSwitch } from "routes/ContentSwitch"
import SignUpLayout from "features/signup/SignUpLayout"
import RequestUpgradeLayout from "features/dashboard/RequestUpgradeLayout"

const mapStateToProps = ({
  session,
  activeCourse,
  activeGoal,
  activeBadge,
  activeLearningPath,
  activeMandatedTraining,
  activeAnnouncement,
}) => {
  return {
    session,
    groupRoles: session.groupRoles,
    orgRoles: session.orgRoles,
    activeGoal,
    activeCourse,
    activeBadge,
    activeLearningPath,
    activeMandatedTraining,
    activeAnnouncement,
  }
}

const mapDispatchToProps = {
  fetchHQAnnouncements,
  getCategories,
  getOrgLearningPaths,
  getOrgSuccessChecklist,
  getOrgBadges,
  getOrgCourses,
}

class App extends Component {
  state = {
    subscriptionWarningDismissed: false,
  }

  componentDidMount() {
    this.props.fetchHQAnnouncements()
    this.props.getCategories()
    this.props.getOrgSuccessChecklist()

    if (this.props.session.isPartner) {
      this.props.getOrgLearningPaths()
      this.props.getOrgBadges({})
      this.props.getOrgCourses({})
    }
  }

  render() {
    const { session } = this.props

    const {
      isCollectionAdmin,
      canViewApp,
      isOrgAdmin,
      isServiceAdmin,
      canReview,
      canViewBackpack,
      subscriptionEnd,
      customerSupportEmail,
      isPartner,
    } = session

    const isObserver =
      session.orgRoles.includes("organization-observer") ||
      session.orgRoles.includes("organization-observation_admin") ||
      session.groupRoles.includes("group-observer") ||
      session.groupRoles.includes("group-observation_admin")

    const subscriptionEndsIn =
      moment(subscriptionEnd).diff(moment(), "days") + 1

    const DashboardComponent =
      session.isCollectionAdmin && !session.activeMembership?.data
        ? CollectionDashboardLayout
        : DashboardLayout

    return (
      <BrowserRouter>
        {canViewApp ? (
          <>
            {!window.location.href.includes("print") &&
              !window.location.href.includes("sign-up") && (
                <Navbar {...this.props} isAuthorized={true} />
              )}

            <ManageCategoriesModal />
            <ReviewerGroupsModal />
            <FeedbackFormsModal />

            <PreApprovalFormsModal />

            {subscriptionEndsIn >= 1 &&
              subscriptionEndsIn <= 90 &&
              isOrgAdmin &&
              !this.state.subscriptionWarningDismissed &&
              !window.location.href.includes("/print") && (
                <div className="subscription-warning">
                  <div
                    className={classNames(
                      "inner",
                      subscriptionEndsIn <= 30 && "danger"
                    )}
                  >
                    <div
                      className="dismissWrapper"
                      onClick={() =>
                        this.setState({ subscriptionWarningDismissed: true })
                      }
                    >
                      <div className="dismiss">Dismiss</div>
                    </div>
                    <div
                      className="flexRow"
                      style={{ justifyContent: "center", marginBottom: 10 }}
                    >
                      <span className="icon renew" />
                      <strong>
                        Your subscription ends
                        {subscriptionEndsIn === 1 ? (
                          <> tomorrow!</>
                        ) : (
                          <>
                            {" "}
                            in {subscriptionEndsIn}{" "}
                            {pluralize("day", subscriptionEndsIn)}!
                          </>
                        )}
                      </strong>
                    </div>
                    <p>
                      Contact{" "}
                      <a
                        href={`mailto:${
                          customerSupportEmail
                            ? customerSupportEmail
                            : "info@mobilemind.io"
                        }`}
                      >
                        {customerSupportEmail
                          ? customerSupportEmail
                          : "info@mobilemind.io"}
                      </a>{" "}
                      to avoid losing access.
                    </p>
                  </div>
                </div>
              )}

            <ErrorBoundary>
              <EventsSwitch />
              <ReportsSwitch />
              <ContentSwitch {...this.props} />

              <Switch>
                <Route
                  exact
                  path="/"
                  component={canViewApp ? DashboardComponent : Unauthorized}
                />
                <Route exact path="/upgrade" component={RequestUpgradeLayout} />

                <Route exact path="/profile" component={ProfileLayout} />

                <Route
                  exact
                  path="/sign-up/organization"
                  component={SignUpLayout}
                />

                <Route exact path="/sign-up" component={SignUpLayout} />

                <Route
                  exact
                  path="/service-account"
                  component={isServiceAdmin ? ServiceLayout : Unauthorized}
                />
                <Route
                  exact
                  path="/org-settings"
                  component={isOrgAdmin ? OrgSettingsLayout : Unauthorized}
                />

                <Route
                  exact
                  path="/marketplace/:id?"
                  component={PartnerMarketplaceLayout}
                />
                <Route
                  exact
                  path="/partner-settings"
                  component={isPartner ? PartnerSettingsLayout : Unauthorized}
                />
                <Route
                  exact
                  path="/collection-settings"
                  component={
                    isCollectionAdmin ? CollectionSettingsLayout : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/review"
                  component={canReview ? ReviewContainer : Unauthorized}
                />
                <Route
                  exact
                  path="/organizations"
                  component={
                    session.isSiteAdmin ? OrganizationList : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/organizations/:id"
                  component={
                    session.isSiteAdmin ? OrganizationSingle : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/backpack/:id"
                  component={
                    canViewBackpack || isObserver
                      ? BackpackContainer
                      : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/backpack/:id/print"
                  component={
                    canViewBackpack || isObserver
                      ? BackpackPrintLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/rubric/:rubricId/:userId"
                  component={
                    isOrgAdmin || isObserver ? RubricLayout : Unauthorized
                  }
                />

                <Route exact path="/badge-studio" component={BadgeStudio} />

                <Route exact path="/submit-ticket" component={SubmitTicket} />
              </Switch>
            </ErrorBoundary>

            <Snackbar />
          </>
        ) : (
          <>
            <Navbar isAuthorized={false} />
            <Unauthorized />
          </>
        )}
      </BrowserRouter>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
