import { Component, useEffect, useState } from "react"
import { connect } from "react-redux"
import EventNavbarContainer from "./EventNavbarContainer"

import { setContentLock } from "../../../actions"
import { getOrgBadges } from "../../../actions/badges"
import { changeTab } from "../../../store/reducers/navbar"

import classNames from "classnames"
import {
  breakEventLock,
  changeEventTab,
  fetchActiveEvent,
  setIsFetched,
  updateField,
  startNewEvent,
} from "./activeEventSlice"

import {
  breakConferenceLock,
  changeConferenceTab,
  fetchActiveConference,
  setConferenceIsFetched,
  setIsEditingEvent,
  startNewConference,
} from "../conference/activeConferenceSlice"

import EventDetails from "./EventDetails"
import EventLocationContainer from "./EventLocationContainer"
import EventPersonnelContainer from "./EventPersonnelContainer"
import EventRegistration from "./EventRegistration"
import EventResources from "./EventResources"

import { getJobTitles, getSubGroups } from "../../../store/reducers/session"

import "../../../styles/event-single.scss"
import "../../../styles/index.scss"

import Loading from "@mobilemind/common/src/components/Loading"
import ContentLock from "../../../components/ContentLock"
import EventAttendees from "./EventAttendees"

import { withRouter } from "react-router-dom"
import { useAppDispatch } from "store/hooks"
import EventRubric from "./EventRubric"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import { Box } from "@mui/material"
import EventCertificateLayout from "./EventCertificateLayout"

const mapStateToProps = (
  { calendar, entityList, courses, session, activeEvent, activeConference },
  ownProps
) => {
  let eventId = ownProps.match ? ownProps.match.params.id : "new"
  if (eventId === "new" && activeEvent.name) {
    eventId = "continue"
  }
  let event
  if (ownProps.isConference) {
    event = activeConference
    eventId = activeConference.drupal_internal__id
  } else {
    event = activeEvent
  }

  let eventRef = ownProps.isConference ? activeConference : activeEvent
  let targetPersonnel, eventRole

  if (window.location.href.includes("session")) {
    targetPersonnel =
      activeConference.personnel &&
      activeConference.personnel.find((user) => user.id === session.user.id)
    eventRole = targetPersonnel && targetPersonnel.field_event_role_name
  } else {
    targetPersonnel =
      eventRef.personnel &&
      eventRef.personnel.find((user) => user.id === session.user.id)
    eventRole = targetPersonnel && targetPersonnel.field_event_role_name
  }

  const isSession =
    ownProps.isSession ||
    (ownProps.match && ownProps.match.params.type === "session")

  let bundle = "mobilemind_event_entity--event_base"
  if (isSession) {
    bundle = "mobilemind_event_entity--conference_event"
  }
  if (ownProps.isConference) {
    bundle = "mobilemind_event_entity--conference"
  }

  const isObservation = ownProps.match.path.includes("observation")

  return {
    eventRole,
    history: ownProps.history,
    isConference: ownProps.isConference,
    isObservation,
    isSession,
    calendar,
    eventId,
    bundle,
    orgBadges: entityList.orgBadges,
    courses,
    session,
    activeEvent: event,
    activeConference: activeConference,
    conference: ownProps.conference,
  }
}

const mapDispatchToProps = {
  getOrgBadges,
  changeTab,
  fetchActiveEvent,
  startNewEvent,
  getSubGroups,
  setIsFetched,
  getJobTitles,
  setIsEditingEvent,
  fetchActiveConference,
  startNewConference,
  setConferenceIsFetched,
  setContentLock,
  updateField,
  changeConferenceTab,
  breakConferenceLock,
  breakEventLock,
}

function SidebarEvent(props) {
  const {
    activeEvent,
    eventRole,
    eventLabel,
    isConference,
    isObservation,
    session,
  } = props

  const dispatch = useAppDispatch()
  const [initialTabSet, setIntialTabSet] = useState(false)

  function changeTab(tab) {
    const changeTab = isConference ? changeConferenceTab : changeEventTab
    dispatch(changeTab(tab))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (!initialTabSet && eventRole) {
      if (
        eventRole === "Presenter" &&
        !session.isOrgAdmin &&
        !session.isGroupAdmin &&
        !session.orgRoles.includes("organization-scheduler") &&
        !session.groupRoles.includes("group-scheduler")
      ) {
        const changeTab = isConference ? changeConferenceTab : changeEventTab
        dispatch(changeTab("resources"))
        window.scrollTo(0, 0)
      }

      setIntialTabSet(true)
    }
  }, [
    initialTabSet,
    eventRole,
    session.isOrgAdmin,
    session.isGroupAdmin,
    session.orgRoles,
    session.groupRoles,
    dispatch,
    isConference,
  ])

  let highlightedTabs = activeEvent.missingFields.map((field) => {
    if (field.includes("Details")) {
      return "details"
    }
    if (field.includes("Location")) {
      return "location"
    }
    if (field.includes("Participants")) {
      return "participants"
    }
    if (field.includes("Registration")) {
      return "registration"
    }
    return null
  })

  const allRoles = props.session.orgRoles
    .concat(props.session.groupRoles)
    .join(",")

  const eventRef = isConference ? props.activeConference : props.activeEvent
  let targetPersonnel = eventRef.personnel.find(
    (personnel) => personnel.uuid === props.session.user.id
  )

  const canEdit =
    allRoles.includes("admin") ||
    allRoles.includes("scheduler") ||
    allRoles.includes("observer")

  const isManager = targetPersonnel?.field_event_role_name === "Manager"

  return (
    <div
      className={classNames(
        "sidebar sidebar-event",
        !activeEvent.fetched && "disabled",
        isConference && "conference"
      )}
    >
      <ul className="sidebar-tabs">
        <li className="divider">Required</li>
        <li
          onClick={() => {
            if (canEdit || isManager) {
              changeTab("details")
            }
          }}
          className={classNames(
            activeEvent.currentTab === "details" && "active",
            highlightedTabs.includes("details") && "highlight"
          )}
        >
          <span className="icon details" /> Details
          {!canEdit && !isManager && (
            <span className="no-access">
              As {eventLabel === "event" ? <>an</> : <>a</>}{" "}
              <span className="capitalize">
                {eventLabel} {eventRole}
              </span>
              , you don't have permission to edit this {eventLabel}'s details.
            </span>
          )}
        </li>
        <li
          onClick={() => {
            canEdit && changeTab("location")
          }}
          className={classNames(
            activeEvent.currentTab === "location" && "active",
            highlightedTabs.includes("location") && "highlight"
          )}
        >
          <span className="icon location" /> Location
          {!canEdit && (
            <span className="no-access">
              As {eventLabel === "event" ? <>an</> : <>a</>}{" "}
              <span className="capitalize">
                {eventLabel} {eventRole}
              </span>
              , you don't have permission to edit the location of this{" "}
              {eventLabel}.
            </span>
          )}
        </li>
        <li
          onClick={() => {
            if (canEdit || isManager) {
              changeTab("participants")
            }
          }}
          className={classNames(
            activeEvent.currentTab === "participants" && "active",
            highlightedTabs.includes("participants") && "highlight"
          )}
        >
          <span className="icon attendees" /> Participants
          {!canEdit && !isManager && (
            <span className="no-access">
              As {eventLabel === "event" ? <>an</> : <>a</>}{" "}
              <span className="capitalize">
                {eventLabel} {eventRole}
              </span>
              , you don't have permission to edit this {eventLabel}'s
              participants.
            </span>
          )}
        </li>
        <li className="divider">Optional</li>
        {isObservation && (
          <li
            onClick={() => {
              changeTab("rubric")
            }}
            className={classNames(
              activeEvent.currentTab === "rubric" && "active"
            )}
          >
            <span className="icon rubric black" /> Rubric
          </li>
        )}
        {!isObservation && (
          <li
            onClick={() => {
              changeTab("resources")
            }}
            className={classNames(
              activeEvent.currentTab === "resources" && "active"
            )}
          >
            <span className="icon laptop black" /> Resources
          </li>
        )}

        {!isObservation && (
          <li
            onClick={() => {
              canEdit && changeTab("personnel")
            }}
            className={classNames(
              activeEvent.currentTab === "personnel" && "active"
            )}
          >
            <span className="icon personnel" />
            Personnel
            {!canEdit && (
              <span className="no-access">
                As {eventLabel === "event" ? <>an</> : <>a</>}{" "}
                <span className="capitalize">
                  {eventLabel} {eventRole}
                </span>
                , you don't have permission to edit personnel.
              </span>
            )}
          </li>
        )}
        <li
          onClick={() => {
            if (canEdit || isManager) {
              changeTab("registration")
            }
          }}
          className={classNames(
            activeEvent.currentTab === "registration" && "active",
            highlightedTabs.includes("registration") && "highlight"
          )}
        >
          <span className="icon registration" /> Registration
          {!canEdit && !isManager && (
            <span className="no-access">
              As {eventLabel === "event" ? <>an</> : <>a</>}{" "}
              <span className="capitalize">
                {eventLabel} {eventRole}
              </span>
              , you don't have permission to edit this {eventLabel}'s
              registration.
            </span>
          )}
        </li>

        {!props.isSession &&
          !isObservation &&
          !window.location.href.includes("session") && (
            <li
              onClick={() => {
                if (canEdit || isManager) {
                  changeTab("certificate")
                }
              }}
              className={classNames(
                activeEvent.currentTab === "certificate" && "active",
                highlightedTabs.includes("certificate") && "highlight"
              )}
            >
              <span className="icon diploma" /> Certificate
            </li>
          )}
      </ul>

      {isConference && !activeEvent.id && (
        <div className="infoContainer">
          <span className="icon info" />
          <p>
            To add a session, first save this conference as a draft or publish.
          </p>
        </div>
      )}

      {activeEvent.eventAuthor?.attributes && session.isOrgAdmin && (
        <div
          style={{
            position: "fixed",
            bottom: 20,
            left: 20,
            zIndex: 3,
            width: 205,
            marginTop: 20,
          }}
        >
          <strong
            style={{
              display: "block",
              fontSize: 10,
              opacity: 0.7,
              textTransform: "uppercase",
              marginBottom: 5,
            }}
          >
            {eventLabel} Created By
          </strong>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: 200,
            }}
          >
            <UserInitial
              fontSize={11}
              size={28}
              initials={
                activeEvent.eventAuthor?.attributes.field_first_name
                  .charAt(0)
                  .toUpperCase() +
                activeEvent.eventAuthor?.attributes.field_last_name
                  .charAt(0)
                  .toUpperCase()
              }
            />

            <strong style={{ paddingLeft: 5, fontSize: 12, lineHeight: 1 }}>
              {activeEvent.eventAuthor?.attributes.field_first_name}{" "}
              {activeEvent.eventAuthor?.attributes.field_last_name}
            </strong>
          </Box>
        </div>
      )}
    </div>
  )
}

class EventSingleLayout extends Component {
  componentDidMount() {
    this.props.setIsEditingEvent(false)

    window.scrollTo(0, 0)
    const {
      eventId,
      session,
      isObservation,
      activeConference,
      match,
      activeEvent,
      orgBadges,
    } = this.props

    this.props.changeTab("sync")
    if (isObservation) {
      this.props.updateField({
        field: "attendeeMethod",
        value: "custom",
      })
    }

    if (eventId !== "new" && eventId !== "continue") {
      if (!this.props.isConference) {
        let isSession = match && match.path.includes("session")

        this.props.fetchActiveEvent({
          isObservation,
          isSession: activeConference.id || isSession,
          isConference: this.props.isConference,
          id: eventId,
        })
      }
    } else if (eventId === "continue") {
      this.props.setIsFetched(true)
    } else if (eventId === "new") {
      this.props.startNewEvent(true)

      if (this.props.isConference) {
        this.props.startNewConference(true)
      }
      if (!activeEvent.isSession) {
        this.props.startNewEvent(true)
      } else {
        this.props.setIsFetched(true)
      }
    } else {
      if (this.props.isConference) {
        this.props.setConferenceIsFetched(true)
      }
    }

    !session.jobTitles.fetched && this.props.getJobTitles()

    if (!session.subGroups.fetched) {
      this.props.getSubGroups(session.group.uuid[0].value)
    }

    !orgBadges.fetched &&
      this.props.getOrgBadges({
        searchQuery: "",
        status: "any",
        category: null,
        subGroup: "any",
        jobTitle: "any",
        sortBy: "created",
        sortOrder: "DESC",
      })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eventId !== this.props.eventId &&
      this.props.eventId === "new"
    ) {
      this.props.startNewEvent(true)
    }

    const { session, activeEvent } = this.props

    if (
      prevProps.activeEvent.currentEditor !==
      this.props.activeEvent.currentEditor
    ) {
      this.unlisten = this.props.history.listen(() => {
        const id = activeEvent.currentEditor && activeEvent.currentEditor.id
        // Clear out the content lock fields if we are the one editing it and we're leaving
        if (!activeEvent.currentEditor || (id && id === session.user.id)) {
          this.props.setContentLock({
            type: this.props.bundle,
            id: activeEvent.id,
            clear: true,
          })
        }
      })
    }
  }

  render() {
    const props = { ...this.props }
    const { activeEvent } = props
    const conference = props.activeConference
    const { currentTab } = activeEvent

    let eventLabel = "event"
    if (props.isConference) {
      eventLabel = "conference"
    }
    if (props.isObservation) {
      eventLabel = "observation"
    }
    if (props.isSession || window.location.href.includes("session")) {
      eventLabel = "session"
    }

    props.eventLabel = eventLabel

    return (
      <>
        {((props.isConference && conference.isSaving) ||
          (!props.isConference && activeEvent.isSaving)) && (
          <Loading fullPage={true} message={"Saving " + eventLabel + "..."} />
        )}

        {activeEvent.isLoadingEvent && (
          <Loading fullPage={true} message={"Getting " + eventLabel + "..."} />
        )}

        {!activeEvent.isLoadingEvent && (
          <div
            className={classNames(
              "page event-single edit",
              ((props.isConference && conference.isSaving) ||
                (!props.isConference && activeEvent.isSaving)) &&
                "saving",
              props.isConference && "conference-edit"
            )}
          >
            <>
              <EventNavbarContainer {...props} />
              {activeEvent.editorLock &&
                activeEvent.currentEditor &&
                activeEvent.currentEditor.id !== props.session.user.id && (
                  <ContentLock
                    contentType={eventLabel}
                    editor={activeEvent.currentEditor}
                    id={activeEvent.id}
                    type={this.props.bundle}
                    editorLock={activeEvent.editorLock}
                    updateLock={() => {
                      if (props.isConference) {
                        this.props.breakConferenceLock()
                      } else {
                        this.props.breakEventLock()
                      }
                    }}
                  />
                )}

              {!activeEvent.isEditingEvent && (
                <>
                  <SidebarEvent {...props} />

                  {currentTab === "details" && <EventDetails {...props} />}
                  {currentTab === "location" && (
                    <EventLocationContainer {...props} />
                  )}
                  {currentTab === "participants" && (
                    <EventAttendees {...props} />
                  )}
                  {currentTab === "resources" && <EventResources {...props} />}
                  {currentTab === "personnel" && (
                    <EventPersonnelContainer {...props} />
                  )}
                  {currentTab === "rubric" && <EventRubric {...props} />}
                  {currentTab === "registration" && (
                    <EventRegistration {...props} />
                  )}
                  {currentTab === "certificate" && (
                    <EventCertificateLayout {...props} />
                  )}
                </>
              )}
            </>
          </div>
        )}
      </>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventSingleLayout)
)
