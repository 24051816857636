import { Formik } from "formik"
import yup from "@mobilemind/common/src/utility/yup"

import { Box, Card } from "@mui/material"
import "../../styles/signup.scss"
import { OrganizationSetup } from "./components/OrganizationSetup"

export type SignUpStep2Values = {
  organizationName: string
  firstName: string
  lastName: string
}
export function SignUpStep2() {
  const initialValues: SignUpStep2Values = {
    organizationName: "",
    firstName: "",
    lastName: "",
  }

  const validationSchema = yup.object({
    organizationName: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  })

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Card
        sx={{
          background: "white",
          padding: 3,
          paddingInline: 5,
          maxWidth: 550,
        }}
      >
        <img alt="MobileMind" src={require("../../img/logo.png")} />
        <Formik
          initialValues={initialValues}
          onSubmit={async (values: SignUpStep2Values) => {}}
          validationSchema={validationSchema}
        >
          {(values) => {
            return <OrganizationSetup />
          }}
        </Formik>
      </Card>
    </Box>
  )
}

export default SignUpStep2
