import { Box, MenuItem } from "@mui/material"
import pluralize from "pluralize"
import { useMemo } from "react"
import LampIcon from "../../img/magic-lamp.svg"
import { useAppSelector } from "../../store/hooks"
import {
  selectGenieWishesRemaining,
  selectHasGenieAccess,
} from "store/selectors"
import Loading from "@mobilemind/common/src/components/Loading"

type AIGenieMenuItemProps = {
  onClick?: () => void
  hideWishesMessage?: boolean
}

export function AIGenieMenuItem(props: AIGenieMenuItemProps) {
  const hasGenieAccess = useAppSelector(selectHasGenieAccess)
  const genieWishesRemaining = useAppSelector(selectGenieWishesRemaining)
  const genieWishesFetched = useAppSelector(
    (state) => state.session.genieWishesFetched
  )

  const content = useMemo(() => {
    if (hasGenieAccess) {
      return null
    }
    return (
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 8,
            marginBottom: props.hideWishesMessage ? -5 : 2,
          }}
        >
          {genieWishesRemaining > 0 &&
            [0, 1, 2].map((item, index) => {
              return (
                <div
                  style={{
                    flex: 1,
                    height: 5,
                    backgroundColor:
                      index < genieWishesRemaining ? "black" : "#ebebeb",
                    marginRight: 3,
                  }}
                />
              )
            })}
        </div>
        {!props.hideWishesMessage && (
          <span
            style={{
              display: "inline-block",
              maxWidth: 180,
              whiteSpace: "wrap",
              fontSize: 12,
              opacity: 0.7,
              paddingTop: 5,
              lineHeight: 1.2,
            }}
          >
            {genieWishesRemaining > 0 ? (
              <>
                {genieWishesRemaining} {pluralize("Wish", genieWishesRemaining)}{" "}
                Remaining
              </>
            ) : (
              <>
                You're out of wishes. Contact MobileMind for unlimited Genie
                access!
              </>
            )}
          </span>
        )}
      </>
    )
  }, [hasGenieAccess, props.hideWishesMessage, genieWishesRemaining])

  const opacity = hasGenieAccess || genieWishesRemaining > 0 ? 1 : 0.65

  const pointerEvents =
    hasGenieAccess || genieWishesRemaining > 0 ? "all" : "none"

  return (
    <>
      <MenuItem
        onClick={
          hasGenieAccess || genieWishesRemaining > 0 ? props.onClick : undefined
        }
        disabled={!hasGenieAccess && !genieWishesFetched}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          pointerEvents,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            opacity,
          }}
        >
          <img
            src={LampIcon}
            alt="AI Genie"
            width={20}
            height={20}
            style={{
              opacity,
            }}
          />
          <span
            style={{
              opacity,
            }}
          >
            AI Genie
          </span>
          {!hasGenieAccess && !genieWishesFetched && (
            <Box
              sx={{
                transform: "scale(.5)",
                position: "relative",
                top: 8,
                marginTop: -3,
                right: -25,
              }}
            >
              <Loading />
            </Box>
          )}
        </Box>
        {(genieWishesFetched || hasGenieAccess) && content}
      </MenuItem>
    </>
  )
}
